import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Stack, Grid, Card, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import toast from 'src/utils/snackBarUtils';
import { changePassword } from 'src/redux/slices/authentication';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

const ChangePassWordSchema = Yup.object().shape({
  old_password: Yup.string().when('$isOldPasswordDirty', {
    is: true,
    then: Yup.string().required('Это поле обязательное'),
  }),
  new_password1: Yup.string()
    .when('$isNewPasswordDirty', {
      is: true,
      then: Yup.string().required('Это поле обязательно'),
    })
    .min(6, 'Пароль должен содержать минимум 6 символов')
    .max(100, 'Пароль должен содержать максимум 100 символов'),
  new_password2: Yup.string()
    .oneOf([Yup.ref('new_password1'), null], 'Пароли не совпадают')
    .min(6, 'Пароль должен содержать минимум 6 символов')
    .max(100, 'Пароль должен содержать максимум 100 символов'),
});

const formikInitialValues = {
  old_password: '',
  new_password1: '',
  new_password2: '',
};

export default function AccountChangePassword() {
  const isMountedRef = useIsMountedRef();

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: ChangePassWordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await changePassword({ ...values });
        toast.success('Ваш пароль успешно изменен');
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        if (isMountedRef.current) {
          if (error.old_password) {
            setErrors({ afterSubmit: error.old_password });
          } else if (error.new_password2) {
            setErrors({ afterSubmit: error.new_password2 });
          }
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { change_password } = DATA_IDS.settings;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} xs={8} md={8}>
                <TextField
                  {...getFieldProps('old_password')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label="Текущий пароль"
                  error={Boolean(touched.old_password && errors.old_password)}
                  helperText={touched.old_password && errors.old_password}
                  {...setDataTestId({
                    name: change_password.current_password,
                    inputProps: {},
                  })}
                />

                <TextField
                  {...getFieldProps('new_password1')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label="Новый пароль"
                  error={Boolean(touched.new_password1 && errors.new_password1)}
                  helperText={touched.new_password1 && errors.new_password1}
                  {...setDataTestId({
                    name: change_password.new_password,
                    inputProps: {},
                  })}
                />

                <TextField
                  {...getFieldProps('new_password2')}
                  fullWidth
                  autoComplete="on"
                  type="password"
                  label="Повторите новый пароль"
                  error={Boolean(touched.new_password2 && errors.new_password2)}
                  helperText={touched.new_password2 && errors.new_password2}
                  {...setDataTestId({
                    name: change_password.confirm_new_password,
                    inputProps: {},
                  })}
                />
                {errors.afterSubmit && (
                  <Alert severity="error">
                    {errors.afterSubmit.map((item) => (
                      <>
                        {item}
                        <br />
                      </>
                    ))}
                  </Alert>
                )}
                <Box
                  sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    {...setDataTestId({
                      name: change_password.change_password_btn,
                    })}
                  >
                    Изменить
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
