import React from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomBreadcrumbs from '../../../customComponents/CutsomBreadCrumbs';
import CustomTypography from '../../../components/CustomTypography';
import { ReactComponent as ArrowLeft } from '../../../assets/ArrowLeft.svg';
import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';
import CirculeImage from '../../../assets/CircleLine.png';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RemoveIcon from '@mui/icons-material/Remove';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CustomTooltip from '../../../components/_dashboard/my-analytics/UI/TextTruncateTooltip';

const mockData = [
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    id: 'ID 146243628',
    type: 'Аукцион',
    status: 'Приостановлена',
    impressions: 3073,
    clicks: 21,
    ctr: 1.5,
    crc_p: 65.5,
    cr_percent: 65.5,
    inCart: 0,
  },
];
const mockData2 = [
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    inCart: 0,
    orders: 10,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    inCart: 0,
    orders: 10,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    inCart: 0,
    orders: 10,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    inCart: 0,
    orders: 10,
  },
  {
    photo: '',
    campaign: 'Кампания от 18.09.24',
    inCart: 0,
    orders: 10,
  },
];
const StyledCard = styled(Card)({
  padding: '20px',
  borderRadius: '16px',
  textAlign: 'center',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: '#FCFCFD',
    padding: '12px 24px',
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const CampanyStatisticDetails = () => {
  const breadcrumbData = [
    { name: 'Главная', href: PATH_DASHBOARD.root },
    { name: 'Реклама', href: PATH_DASHBOARD.general.ad },
    { name: 'Статистика', href: '' },
  ];

  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page title="Реклама">
      <Stack>
        <CustomBreadcrumbs items={breadcrumbData} />
      </Stack>

      <Paper
        sx={{
          borderRadius: '12px',
          padding: isMobile ? '10px' : '20px',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            borderBottom: '1px solid #EAECF0',
            p: isMobile ? '10px' : '20px 30px',
          }}
        >
          <Box display="flex" alignItems="center">
            <ArrowLeft />
            <Box
              display="flex"
              alignItems="center"
              ml={isMobile ? '10px' : '18px'}
            >
              <Box>
                <CustomTypography
                  mr="8px"
                  color="#09244B"
                  variant={isMobile ? 'body2' : 'title1'}
                >
                  Кампания от 18.09.24
                </CustomTypography>
                <CustomTypography
                  mr="8px"
                  color="#595959"
                  variant={isMobile ? 'body2' : 'body'}
                >
                  Аукцион ID:46532458
                </CustomTypography>
              </Box>
              <Chip
                sx={{ backgroundColor: '#E6020208' }}
                label={
                  <CustomTypography variant="body1" p="4px 8px" color="#E60202">
                    Приостановлена
                  </CustomTypography>
                }
                color="primary"
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <CustomTypography color="#667080" variant="body3" mr={2}>
              Последнее обновление 07-Окт 2024 в 03:43
            </CustomTypography>
            <Button
              variant="contained"
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0651 2.5V1.75V2.5ZM3.06586 9.44444H2.31586H3.06586ZM3.06586 10.8333L2.53762 11.3657C2.83003 11.6559 3.30169 11.6559 3.5941 11.3657L3.06586 10.8333ZM4.99394 9.97685C5.28799 9.68511 5.28985 9.21024 4.99811 8.9162C4.70637 8.62216 4.2315 8.6203 3.93746 8.91204L4.99394 9.97685ZM2.19426 8.91204C1.90022 8.6203 1.42535 8.62216 1.13361 8.9162C0.841866 9.21024 0.843731 9.68511 1.13777 9.97685L2.19426 8.91204ZM15.4058 6.22612C15.6228 6.57899 16.0847 6.68918 16.4375 6.47225C16.7904 6.25532 16.9006 5.79341 16.6837 5.44054L15.4058 6.22612ZM10.0651 1.75C5.79086 1.75 2.31586 5.18938 2.31586 9.44444H3.81586C3.81586 6.02889 6.60816 3.25 10.0651 3.25V1.75ZM2.31586 9.44444L2.31586 10.8333H3.81586L3.81586 9.44444L2.31586 9.44444ZM3.5941 11.3657L4.99394 9.97685L3.93746 8.91204L2.53762 10.3009L3.5941 11.3657ZM3.5941 10.3009L2.19426 8.91204L1.13777 9.97685L2.53762 11.3657L3.5941 10.3009ZM16.6837 5.44054C15.3231 3.22738 12.867 1.75 10.0651 1.75V3.25C12.3281 3.25 14.3087 4.44155 15.4058 6.22612L16.6837 5.44054Z"
                    fill="#09244B"
                  />
                  <path
                    opacity="0.5"
                    d="M9.90293 17.4993V18.2493V17.4993ZM16.9289 10.5549H17.6789H16.9289ZM16.9289 9.16602L17.4561 8.6326C17.164 8.34382 16.6938 8.34382 16.4017 8.6326L16.9289 9.16602ZM14.9965 10.0215C14.7019 10.3127 14.6991 10.7875 14.9903 11.0821C15.2815 11.3767 15.7563 11.3795 16.0509 11.0883L14.9965 10.0215ZM17.8069 11.0883C18.1015 11.3795 18.5763 11.3767 18.8675 11.0821C19.1587 10.7875 19.1559 10.3127 18.8613 10.0215L17.8069 11.0883ZM4.53864 13.7721C4.32111 13.4196 3.85901 13.3102 3.50651 13.5278C3.15402 13.7453 3.04461 14.2074 3.26214 14.5599L4.53864 13.7721ZM9.90293 18.2493C14.1892 18.2493 17.6789 14.8127 17.6789 10.5549H16.1789C16.1789 13.9678 13.3773 16.7493 9.90293 16.7493V18.2493ZM17.6789 10.5549V9.16602H16.1789V10.5549H17.6789ZM16.4017 8.6326L14.9965 10.0215L16.0509 11.0883L17.4561 9.69943L16.4017 8.6326ZM16.4017 9.69943L17.8069 11.0883L18.8613 10.0215L17.4561 8.6326L16.4017 9.69943ZM3.26214 14.5599C4.62804 16.7732 7.09266 18.2493 9.90293 18.2493V16.7493C7.62886 16.7493 5.63984 15.5565 4.53864 13.7721L3.26214 14.5599Z"
                    fill="#09244B"
                  />
                </svg>
              }
              sx={{
                textTransform: 'none',
                backgroundColor: '#fff',
                borderRadius: '28px',
                boxShadow: 'none',
                border: '1px solid #D0D5DD',
                mr: 1,
              }}
              disableRipple
            >
              <CustomTypography
                variant="body2"
                color="#09244B"
                sx={{
                  '&:hover': {
                    color: 'white',
                  },
                }}
              >
                Обновить
              </CustomTypography>
            </Button>
            <Button
              variant="contained"
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              sx={{
                textTransform: 'none',
                backgroundColor: '#616cf5',
                borderRadius: '28px',
                boxShadow: 'none',
              }}
              disableRipple
            >
              <CustomTypography variant="body2">Фильтр</CustomTypography>
            </Button>
          </Box>
        </Stack>
        <Stack mt={2}>
          <CustomTypography
            variant="h7"
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            Воронка продаж{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                opacity="0.5"
                cx="12"
                cy="12"
                r="10"
                stroke="#1C274C"
                stroke-width="1.5"
              />
              <path
                d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
                stroke="#1C274C"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <circle cx="12" cy="16" r="1" fill="#1C274C" />
            </svg>
          </CustomTypography>
          <CustomTypography variant="body3" color="#595959">
            Расширенная статистика за последние 30 дней
          </CustomTypography>
          <Grid
            container
            p={{ xs: '20px 20px', sm: '30px 50px', md: '80px 200px' }}
          >
            <Grid item xs={12} pt={10} md={4}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6} md={6}>
                  <StyledCard>
                    <CustomTypography color="#F23D5E" variant="h6">
                      62145
                    </CustomTypography>
                    <CustomTypography variant="body2" color="#6e6e6e">
                      Добавлено в корзину
                    </CustomTypography>
                  </StyledCard>
                </Grid>
                <Grid item xs={6} md={6}>
                  <StyledCard>
                    <CustomTypography color="#F2421B" variant="h6">
                      53
                    </CustomTypography>
                    <CustomTypography variant="body2" color="#6e6e6e">
                      Просмотров
                    </CustomTypography>
                  </StyledCard>
                </Grid>
                <Grid item xs={6} md={6}>
                  <StyledCard>
                    <CustomTypography color="#F2A71B" variant="h6">
                      542
                    </CustomTypography>
                    <CustomTypography variant="body2" color="#6e6e6e">
                      Кликов
                    </CustomTypography>
                  </StyledCard>
                </Grid>
                <Grid item xs={6} md={6}>
                  <StyledCard>
                    <CustomTypography color="#0D74CD" variant="h6">
                      12
                    </CustomTypography>
                    <CustomTypography variant="body2" color="#6e6e6e">
                      Заказанные товары
                    </CustomTypography>
                  </StyledCard>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box
                component="img"
                src={CirculeImage}
                sx={{
                  width: { xs: '250px', sm: '300px', md: '380px' },
                  height: { xs: '230px', sm: '300px', md: '350px' },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack mt={2}>
          <CustomTypography variant="h7">
            Базовая статистика кампании
          </CustomTypography>
          <TableContainer
            component={Paper}
            style={{ maxHeight: '60vh', overflowY: 'auto' }}
          >
            <Table aria-label="customized table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <CustomTypography variant="body3">ФОТО</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <CustomTypography variant="body3">
                      КАМПАНИЯ
                    </CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <CustomTypography variant="body3">СТАРТ</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <CustomTypography variant="body3">ФИНИШ</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <CustomTypography variant="body3">ПОКАЗЫ</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTypography variant="body3">КЛИКИ</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTypography variant="body3">СРМ,₽</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTypography variant="body3">CTR,%</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTypography variant="body3">CPC,₽</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTypography variant="body3">
                      В КОРЗИНЕ
                    </CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTypography variant="body3">
                      ЗАТРАТЫ,₽
                    </CustomTypography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockData?.map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      '&.MuiTableRow-root.Mui-selected': {
                        backgroundColor: 'rgba(97, 108, 245, 0.07)',
                      },
                    }}
                  >
                    <StyledTableCell width="66px" align="left">
                      <Box component="img" src={row.photo} />
                    </StyledTableCell>
                    <StyledTableCell width="300px" align="left">
                      <CustomTooltip
                        title={row.campaign}
                        placement="bottom-end"
                      >
                        <Box>
                          <CustomTypography
                            variant="body3"
                            align="left"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row.campaign}
                          </CustomTypography>
                        </Box>
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <CustomTooltip title={row.id} placement="bottom-end">
                        <Box>
                          <CustomTypography variant="body3" color="#667085">
                            ID {row.id}
                          </CustomTypography>
                        </Box>
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <CustomTooltip title={row.type} placement="bottom-end">
                        <Box>
                          <CustomTypography variant="body3" color="#667085">
                            {row.type}
                          </CustomTypography>
                        </Box>
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <CustomTooltip title={row.status} placement="bottom-end">
                        <Box>
                          <CustomTypography variant="body3" color="#667085">
                            {row.status}
                          </CustomTypography>
                        </Box>
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTooltip
                        title={row.impressions}
                        placement="bottom-end"
                      >
                        <CustomTypography variant="body3" color="#667085">
                          {row.impressions}
                        </CustomTypography>
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTooltip title={row.clicks} placement="bottom-end">
                        <Box>
                          <CustomTypography variant="body3" color="#667085">
                            {/*{fCurrency(row.nms_count)}*/}
                            {row.clicks}
                          </CustomTypography>
                        </Box>
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTypography variant="body2" color="#667085">
                        {row.ctr}
                      </CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTooltip title={row.crc_p} placement="bottom-end">
                        {row.crc_p}
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTooltip
                        title={row.cr_percent}
                        placement="bottom-end"
                      >
                        {row.cr_percent}
                      </CustomTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTooltip title={row.inCart} placement="bottom-end">
                        {row.inCart}
                      </CustomTooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack mt={2}>
          <CustomTypography variant="h7">
            Ассоциированные конверсии
          </CustomTypography>
          <Box width="50%">
            <TableContainer
              component={Paper}
              style={{ maxHeight: '60vh', overflowY: 'auto' }}
            >
              <Table aria-label="customized table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <CustomTypography variant="body3">ФОТО</CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <CustomTypography variant="body3">
                        КАМПАНИЯ
                      </CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTypography variant="body3">
                        В КОРЗИНЕ
                      </CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomTypography variant="body3">
                        ЗАКАЗЫ, шт
                      </CustomTypography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mockData2?.map((row, index) => (
                    <TableRow
                      key={index}
                      hover
                      sx={{
                        '&.MuiTableRow-root.Mui-selected': {
                          backgroundColor: 'rgba(97, 108, 245, 0.07)',
                        },
                      }}
                    >
                      <StyledTableCell width="66px" align="left">
                        <Box component="img" src={row.photo} />
                      </StyledTableCell>
                      <StyledTableCell width="300px" align="left">
                        <CustomTooltip
                          title={row.campaign}
                          placement="bottom-end"
                        >
                          <Box>
                            <CustomTypography
                              variant="body3"
                              align="left"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {row.campaign}
                            </CustomTypography>
                          </Box>
                        </CustomTooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CustomTooltip
                          title={row.inCart}
                          placement="bottom-end"
                        >
                          {row.inCart}
                        </CustomTooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CustomTooltip
                          title={row.orders}
                          placement="bottom-end"
                        >
                          {row.orders}
                        </CustomTooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Paper>
    </Page>
  );
};

export default CampanyStatisticDetails;
