import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback } from 'react';
import { useTheme, Box, useMediaQuery } from '@mui/material';
import track from '../utils/analytics';
import { isDevicePWA } from '../utils/utils';
import MainFooter from '../layouts/main/MainFooter';

const Page = forwardRef(
  ({ children, title = '', margin = 12, childrenProps, ...other }, ref) => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname,
      });
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      <>
        <Box
          {...other}
          ref={ref}
          margin={`${margin}px`}
          paddingBottom={10}
          mt={isMobile ? 1 : 6}
          bgcolor="#fdf9fc"
          borderRadius="16px"
          p={isMobile ? '8px 15px' : '16px 29px'}
        >
          <Helmet>
            <title>{title}</title>
            <meta
              name="theme-color"
              content={theme.palette.mode === 'dark' ? '#212B36' : 'white'}
            />

            {!isDevicePWA() && window.location.hostname !== 'localhost' && (
              <script
                src="//code.jivosite.com/widget/SXdH9liKRD"
                async
              ></script>
            )}
          </Helmet>
          <Box
            {...childrenProps}
            sx={{
              // padding: '16px 30px',
              ...(childrenProps && { ...childrenProps.sx }),
            }}
          >
            {children}
          </Box>
        </Box>
        <MainFooter />
      </>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
