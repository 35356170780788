import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Drawer, Typography } from '@mui/material';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { ReactComponent as HamburgerButton } from '../../assets/HamburgerButton.svg';
import { ReactComponent as ArrowLeft } from '../../assets/ArrowLeftWith.svg';

import MainLogo from '../../assets/MainLogo.png';
import { useSelector } from 'react-redux';
import { authReduxSelector } from '../../redux/slices/authentication';
import { ReactComponent as InIcon } from '../../assets/InIcon.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const StyledRouterLink = styled(Box)`
  width: 141px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNav = styled(Box)`
  height: 45px;
  position: fixed;
  border-radius: 0 0 16px 16px;
  top: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const StyledIconWrap = styled(IconButton)`
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled(Box)`
  width: 29%;
  display: flex;
  align-items: center;
`;

const StyledDrawerContainer = styled(Box)`
  padding: 53px 30px 30px 40px;
`;

export default function MenuMobile() {
  const { isAuthenticated } = useSelector((state) => authReduxSelector(state));
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const portalSpecialistLink = isAuthenticated
    ? PATH_DASHBOARD.user.cards
    : PATH_PAGE.portal_specialist;

  const formLink = isAuthenticated
    ? PATH_DASHBOARD.general.forum
    : PATH_PAGE.forum;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <>
      <StyledNav>
        <Wrap justifyContent="start">
          <StyledIconWrap onClick={toggleDrawer(true)}>
            <HamburgerButton />
          </StyledIconWrap>
        </Wrap>

        <Wrap justifyContent="center">
          <StyledRouterLink to={PATH_DASHBOARD.root}>
            <Box
              display="flex"
              justifyContent="center"
              component="img"
              alt="isistant-logo"
              src={`${MainLogo}`}
            />
          </StyledRouterLink>
        </Wrap>

        {!isAuthenticated && (
          <Wrap
            display="flex"
            alignItems="center"
            gap="20px"
            justifyContent="end"
          >
            <Box
              onClick={() => navigate(PATH_AUTH.login)}
              sx={{
                borderRadius: '28px',
                width: '85px',
                height: '24px',
                border: '1px solid #616CF5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                '&:hover': {
                  background: '#616CF5',
                },
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight={500}
                sx={{
                  color: '#09244B',
                }}
              >
                Войти
              </Typography>
            </Box>
          </Wrap>
        )}
      </StyledNav>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '0 12px 12px 0',
            background: '#F5F6F7',
            boxShadow: '0 2px 6px 0 rgba(103, 109, 117, 0.24)',
            width: '332px',
          },
        }}
      >
        <StyledDrawerContainer role="presentation">
          <Box
            sx={{
              width: '44px',
              height: '44px',
              background: '#12121D0D',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            }}
            onClick={toggleDrawer(false)}
          >
            <ArrowLeft />
          </Box>
          <Box mt="30px">
            <Box mt="15px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate(formLink)}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Форм
                </Typography>
              </Box>
            </Box>
            <Box mt="15px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate(PATH_PAGE.price)}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Тарифы
                </Typography>
              </Box>
            </Box>
            <Box mt="15px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate(portalSpecialistLink)}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Портал специалистов
                </Typography>
              </Box>
            </Box>

            {isAuthenticated && (
              <Box mt="15px">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="8px"
                  p="0 10px 7px 0"
                  onClick={() => navigate(PATH_DASHBOARD.root)}
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    noWrap
                  >
                    Дашборд
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <InIcon />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </StyledDrawerContainer>
      </Drawer>
    </>
  );
}
