import {
  randomFeedbacks,
  randomProductImage,
} from 'src/components/_dashboard/external-analytics/constants';
import { generateRandomDate, randomDateFormater } from '../formatTime';
import {
  generate2WeekArray,
  generateMonthArray,
  getRandomKeywords,
  getRandomWarehouses,
} from './warehouses';
import _ from 'lodash';

export const randomAnalysisOfFeedback = () => {
  const array = [...Array(Math.round(Math.random() * 25))];

  const keywords = array.map((el, i) => ({
    id: i,
    answer: randomFeedbacks(),
    feedback: randomFeedbacks(),
    date: generateRandomDate(),
    rating: Math.round(Math.random() * 5),
  }));

  const uniqueKeywords = _.uniqBy(keywords, 'feedback');

  return Array.isArray(uniqueKeywords) ? uniqueKeywords : [];
};

export const randomBalanceSoldData = () => {
  const randomArray = [...Array(Math.round(Math.random() * 20))];

  const result = randomArray.map((el, i) => {
    const amount = Math.round(Math.random() * 100000);
    const balanceCount = Math.round(Math.random() * 1000);
    const balance = {
      price: amount * balanceCount,
      count: balanceCount,
    };

    const soldCount = Math.round(Math.random() * 1000000);

    const sold = {
      price: amount * soldCount,
      count: soldCount,
    };

    return {
      id: i + 1,
      name: getRandomWarehouses(),
      amount,
      balance,
      sold,
    };
  });

  const uniqueProducts = _.uniqBy(result, 'name');

  return uniqueProducts;
};

export const randomPositionQuery = () => {
  const array = [...Array(Math.round(Math.random() * 25))];

  const keywords = array.map((el, i) => ({
    id: i,
    name: getRandomKeywords(),
    wb_frequency: Math.round(Math.random() * 1000000),
    result: Math.round(Math.random() * 100000),
    monthData: generateMonthArray().map((el) => ({
      date: el,
      value: Math.round(Math.random() * 100),
    })),
  }));

  const uniqueKeywords = _.uniqBy(keywords, 'name');

  return Array.isArray(uniqueKeywords) ? uniqueKeywords : [];
};

export const randomPositionCategoryQuery = () => {
  const array = [...Array(Math.round(Math.random() * 25))];

  const keywords = array.map((el, i) => ({
    id: i,
    name: getRandomKeywords(),
    products: Math.round(Math.random() * 1000000),
    monthData: generateMonthArray().map((el) => ({
      date: el,
      value: Math.round(Math.random() * 100000),
    })),
  }));

  const uniqueKeywords = _.uniqBy(keywords, 'name');

  return Array.isArray(uniqueKeywords) ? uniqueKeywords : [];
};

export const randomWarehouseTable = () => {
  const array = [...Array(Math.round(Math.random() * 25))];

  const keywords = array.map((el, i) => ({
    id: i,
    name: getRandomWarehouses(),
    sales_percent: Math.random() * 100,
    balance_percent: Math.random() * 100,
    sales_count: Math.round(Math.random() * 100000),
    balance_count: Math.round(Math.random() * 100000),
  }));

  const uniqueKeywords = _.uniqBy(keywords, 'name');

  return Array.isArray(uniqueKeywords) ? uniqueKeywords : [];
};

export const randomSizesTable = () => {
  const array = [...Array(Math.round(Math.random() * 25))];

  const keywords = array.map((el, i) => ({
    id: i,
    name: getRandomWarehouses(),
    vendor_size: Math.round(Math.random() * 1000000),
    sales_percent: Math.random() * 100,
    balance_percent: Math.random() * 100,
    sales_count: Math.round(Math.random() * 10000),
    balance_count: Math.round(Math.random() * 10000),
  }));

  const uniqueKeywords = _.uniqBy(keywords, 'name');

  return Array.isArray(uniqueKeywords) ? uniqueKeywords : [];
};

const randomArray = (number) => {
  const value = number || 10;

  return [...Array(Math.round(Math.random() * value))];
};

export const randomImages = () => {
  const array = randomArray(10);

  const images = array.map((el) =>
    randomArray(10).map((el) => randomProductImage()),
  );

  return images;
};

export const randomSalesBalancesTable = () => {
  const array = randomArray(50);

  const keywords = array.map((el, i) => ({
    id: i,
    date: randomDateFormater(),
    quantity_sales: Math.round(Math.random() * 100000),
    having_units: Math.round(Math.random() * 100000),
    base_price: Math.round(Math.random() * 10000),
    price: Math.round(Math.random() * 10000),
    discount: Math.round(Math.random() * 100),
    discount_price: Math.round(Math.random() * 10000),
    cpp_percent: Math.round(Math.random() * 100),
    price_spp_ruble: Math.round(Math.random() * 1000),
    sales_amount: Math.round(Math.random() * 10000000),
    comment_count: Math.round(Math.random() * 10000),
    rating: Math.round(Math.random() * 5),
  }));

  const uniqueKeywords = _.uniqBy(keywords, 'id');

  return Array.isArray(uniqueKeywords) ? uniqueKeywords : [];
};

const percentRandom = () => {
  return Math.round(Math.random() * 100);
};

export const randomSalesBalancesChart = () => {
  const names = [
    'Продажи',
    'Остаток',
    'Выручка',
    'Цена',
    'Видимость ключей',
    'Отзывы',
  ];

  const dummyData = names.map((el) => ({
    title: el,
    history: generate2WeekArray().map((el) => ({
      date: el,
      value: Math.round(Math.random() * 100000),
    })),
  }));

  return dummyData;
};

const salesBalancesTable = [
  {
    id: 1,
    name: 'Суммарно (30 дней)',
    sales_quantity: Math.round(Math.random() * 100000),
    sales_amount: Math.round(Math.random() * 100000000),
  },
  {
    id: 2,
    name: 'Среднее (в день)',
    sales_quantity: Math.round(Math.random() * 1000),
    sales_amount: Math.round(Math.random() * 1000000),
  },
  {
    id: 3,
    name: 'Дней с наличием',
    sales_quantity: Math.round(Math.random() * 100),
    sales_amount: Math.round(Math.random() * 10000000),
  },
  {
    id: 4,
    name: 'Среднее при наличии',
    sales_quantity: Math.round(Math.random() * 1000),
    sales_amount: Math.round(Math.random() * 10000000),
  },
  {
    id: 5,
    name: 'Потелциал',
    sales_quantity: Math.round(Math.random() * 100),
    sales_amount: Math.round(Math.random() * 100000000),
  },
  {
    id: 6,
    name: 'Упущенная выручка',
    sales_quantity: Math.round(Math.random() * 100),
    sales_amount: Math.round(Math.random() * 100000000),
  },
];

export const dummy_datas = () => {
  return {
    warehouses: randomBalanceSoldData(),
    sizes: randomBalanceSoldData(),
    position_query: randomPositionQuery(),
    position_category_query: randomPositionCategoryQuery(),
    warehouses_table: randomWarehouseTable(),
    sizes_table: randomSizesTable(),
    buybacks_percent: percentRandom(),
    lost_revenue_count: Math.round(Math.random() * 1000),
    lost_revenue_price: Math.round(Math.random() * 1000),
    order_count: Math.round(Math.random() * 1000),
    order_price: Math.round(Math.random() * 100000000),
    redemption_percentage: percentRandom(),
    selling_percent: percentRandom(),
    selling_price: Math.round(Math.random() * 1000),
    sales_balances_chart: randomSalesBalancesChart(),
    sales_balances_table_footer: salesBalancesTable,
    analysis_feedback: randomAnalysisOfFeedback(),
    sales_balances_table: randomSalesBalancesTable(),
    made_country: 'Китай',
    сommission: percentRandom(),
    first_of_obbers: new Date(),
    categories: [
      { id: 1, name: 'Аксессуары' },
      { id: 9965, name: 'Аксессуары для волос' },
    ],
    sandbox: 'Для дома',
    images: randomImages(),
  };
};
