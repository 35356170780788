import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'all',
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLanguage(state, { payload }) {
      state.language = payload;
    },
  },
});

export default slice.reducer;

export const setLanguage = (lang) => {
  return (dispatch) => {
    dispatch(slice.actions.setLanguage(lang));
  };
};
