import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'src/apis';
import axiosInstance from 'src/utils/axios';
import { applyFilter } from 'src/utils/searchCategoriesTree';

export const getDumpingThunk = createAsyncThunk(
  'getDumpingThunk/autoDumpingSlice',
  async ({ url }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(API.dumping);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postDumpingThunk = createAsyncThunk(
  'postDumpingThunk/autoDumpingSlice',
  async ({ body }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch('/api/kaspi/dumping/', body);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  data: [],
  duble: [],

  loading: false,
  error: {
    message: null,
    enabled: false,
  },
};

const autoDumpingSlice = createSlice({
  name: 'autoDumping',
  initialState,
  reducers: {
    setDumpingData(state, { payload }) {
      state.data = payload;
    },
    setDumpingDataById: (state, actions) => {
      const { id, value, key } = actions.payload;

      state.duble = state.duble.map((el) => {
        if (el.id === id) {
          const newValue =
            key === 'enabled'
              ? value
              : !Number.isNaN(+value)
              ? Number(value)
              : value;
          return { ...el, [key]: newValue };
        }

        return el;
      });
    },
    searchDumping: (state, actions) => {
      const { query } = actions.payload;

      state.data = applyFilter(state.duble, query);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDumpingThunk.fulfilled, (state, actions) => {
        const products = actions.payload;
        if (Array.isArray(products) && products.length) {
          state.data = products;
          state.duble = products;
        }

        state.loading = false;
        state.error = initialState.error;
      })
      .addCase(getDumpingThunk.rejected, (state) => {
        state.error.enabled = true;
        state.loading = false;
      })
      .addCase(getDumpingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDumpingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDumpingThunk.fulfilled, (state) => {
        state.error = initialState.error;
        state.loading = false;
      })
      .addCase(postDumpingThunk.rejected, (state) => {
        state.error.enabled = true;
        state.loading = false;
      });
  },
});

export const actionsAutoDumping = autoDumpingSlice.actions;
export default autoDumpingSlice.reducer;
