import { Button, Card, Grid, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import PromocodeSuccess from './PromocodeSuccess';
import PromoTable from './PromoTable';
import axiosInstance from 'src/utils/axios';
import { API } from 'src/apis';
import toast from 'src/utils/snackBarUtils';

const initPromo = {
  name: '',
  gained_accesses: [],
  discounts: [],
};

export default function PromocodeForm() {
  const [open, setOpen] = useState(false);
  const [benefits, setBenefits] = useState(initPromo);
  const [promocode, setPromo] = useState('');
  const [rows, setRows] = useState([]);
  const promo = localStorage.getItem('promo');

  const getHistory = async () => {
    try {
      const { data } = await axiosInstance.get(API.promocodes + 'active/');
      setRows(data);
    } catch (e) {
      toast.error(`${e?.data || 'Не удалось получить данные'}`);
    }
  };

  useEffect(() => {
    getHistory();
    setPromo(promo ? promo : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  const handleModal = () => setOpen((prev) => !prev);

  const postPromocode = async () => {
    try {
      const { data } = await axiosInstance.post(API.promocodes + 'activate/', {
        promocode,
      });
      setBenefits(data);
      handleModal();
      localStorage.removeItem('promo');
      setPromo('');
      getHistory()
        .then((res) => setRows(res))
        .catch(() => setRows([]));
    } catch (e) {
      toast.error(`${e.detail || e[0]}`);
      localStorage.removeItem('promo');
      setPromo('');
      console.error(e);
    }
  };

  const onChange = ({ target }) => {
    setPromo(target.value);
  };

  return (
    <>
      <Card sx={{ p: { xs: 2, md: 3 }, width: '100%' }}>
        <Stack spacing={1} direction="row">
          <TextField
            id="textfield-id"
            fullWidth
            name="promo"
            value={promocode}
            placeholder="Введите промокод"
            onChange={onChange}
          />
          <Button
            variant="contained"
            onClick={postPromocode}
            disabled={!promocode.length}
            sx={{
              boxSizing: 'border-box',
              fontSize: { xs: '10px', sm: '13px' },
            }}
          >
            Активировать
          </Button>
        </Stack>
        <PromoTable rows={rows} />
      </Card>
      <PromocodeSuccess benefits={benefits} open={open} onClose={handleModal} />
    </>
  );
}
