import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { API } from 'src/apis';
import axiosInstance from '../../utils/axios';
import { useSnackbar } from 'notistack';

const initialState = {
  isLoading: false,
  error: false,
  myProfile: {
    bio: '',
    city: {},
    created_at: '',
    email: '',
    fb_link: null,
    full_name: '',
    id: 1,
    instagram_link: '',
    linkedin_link: null,
    phone: '',
    portfolio_images: [],
    profile_image: '',
    speciality: {},
  },
  posts: [],
  brands: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: [],
  addressBook: [],
  invoices: [],
  notifications: null,
  paymentStatus: false,
  paymentDaysRemain: 1,
  permissions: [{ section_id: 10, section_name: 'Весь функционал' }],
  balance: 0,
  extra_funds_amount: 0,
  extra_funds_available: 0,
  extra_funds_payout: 0,
  extra_funds_spent: 0,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },
    getBrandsSuccess(state, action) {
      state.isLoading = false;
      state.brands = action.payload;
    },
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
    deleteUser(state, action) {
      const deleteUser = filter(
        state.userList,
        (user) => user.id !== action.payload,
      );
      state.userList = deleteUser;
    },
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },
    onToggleFollow(state, action) {
      const followerId = action.payload;
      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed,
          };
        }
        return follower;
      });
      state.followers = handleToggle;
    },
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    setPaymentStatus(state, { payload }) {
      state.paymentStatus = payload;
    },
    setPaymentDaysRemain(state, { payload }) {
      state.paymentDaysRemain = payload;
    },
    setPermissions(state, { payload }) {
      state.permissions = payload;
    },
    setBalance(state, { payload }) {
      state.balance = payload;
    },
    setExtraFundsAmount(state, { payload }) {
      state.extra_funds_amount = payload;
    },
    setExtraFundsAvailable(state, { payload }) {
      state.extra_funds_available = payload;
    },
    setExtraFundsPayout(state, { payload }) {
      state.extra_funds_payout = payload;
    },
    setExtraFundsSpent(state, { payload }) {
      state.extra_funds_spent = payload;
    },
  },
});

export default slice.reducer;

export const { onToggleFollow, deleteUser } = slice.actions;

export function getProfile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API.specialists + `${id}/`);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('api/user/posts/');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrands() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/store/brands/');
      dispatch(slice.actions.getBrandsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users/');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/billing/user_cards/');
      dispatch(slice.actions.getCardsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book/');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API.billing_invoices);
      dispatch(slice.actions.getInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/user/account/notifications-settings',
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get('api/specialists/', { params });
      dispatch(slice.actions.getUsersSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPaymentStatus(configs) {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) return;

      await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/billing/check_billing/`,
        {
          method: 'get',
          headers: new Headers({
            Accept: 'application/json, text/plain, */*',
            Authorization: `Bearer ${accessToken}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.permissions) {
            dispatch(slice.actions.setPaymentStatus(data?.allowed_to_use));
            dispatch(slice.actions.setPaymentDaysRemain(data?.days_remaining));
            dispatch(slice.actions.setPermissions(data?.permissions));
            dispatch(slice.actions.setBalance(data?.balance));
            dispatch(
              slice.actions.setExtraFundsAmount(data?.extra_funds_amount),
            );
            dispatch(
              slice.actions.setExtraFundsAvailable(data?.extra_funds_available),
            );
            dispatch(
              slice.actions.setExtraFundsPayout(data?.extra_funds_payout),
            );
            dispatch(slice.actions.setExtraFundsSpent(data?.extra_funds_spent));
          }
        })
        .catch((e) => console.error(e));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function popUpBalance({ amount, payment_method }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post(API.popup_balance, {
        amount,
        payment_method,
      });

      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error(error);
    }
  };
}
