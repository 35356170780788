import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Badge, Box, IconButton, Drawer, Typography } from '@mui/material';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { ReactComponent as SearchIcon } from '../../../assets/Search.svg';
import { ReactComponent as Cart } from '../../../assets/Bag.svg';
import { ReactComponent as HamburgerButton } from '../../../assets/HamburgerButton.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/ArrowLeftWith.svg';
import { ReactComponent as LinkIcon } from '../../../assets/LinkIcon.svg';
import { ReactComponent as MyProductIcon } from '../../../assets/MyProductsIcon.svg';
import { ReactComponent as BalanceIcon } from '../../../assets/BalanceIcon.svg';
import AccountPlaceHolder from '../../../assets/AccountPlaceHolder.png';
import MainLogo from '../../../assets/MainLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  authReduxActions,
  authReduxSelector,
} from '../../../redux/slices/authentication';
import { ReactComponent as InIcon } from '../../../assets/InIcon.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment/moment';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomModal from '../../../components/customModal/CustomModal';
import { isDevicePWA } from '../../../utils/utils';
import toast from '../../../utils/snackBarUtils';

const StyledRouterLink = styled(Box)`
  width: 141px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNav = styled(Box)`
  height: 45px;
  position: fixed;
  border-radius: 0 0 16px 16px;
  top: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const StyledIconWrap = styled(IconButton)`
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled(Box)`
  width: 29%;
  display: flex;
  align-items: center;
`;

const StyledBadge = styled(Badge)`
    padding: 10px;

    span {
        top: 15px;
        right: 10px;
        width: 18px;
        height: 18px;
        background: #EC407A;
        color: #fff;
        border-radius: 50%;
        font-size: 9px;
    }

,
`;

const StyledDrawerContainer = styled(Box)`
  padding: 53px 30px 30px 40px;
`;

export default function MobileNavigation() {
  const { totalQuantity } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => authReduxSelector(state));
  const { paymentDaysRemain } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // States
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [modal, setModal] = useState(false);

  // Collapse States
  const [isOpenTools, setIsOpenTools] = useState(false);
  const [isOpenPromotion, setIsOpenPromotion] = useState(false);
  const [isOpenEducation, setIsOpenEducation] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleLogout = async () => {
    try {
      dispatch(authReduxActions.logout());
      if (isDevicePWA()) {
        navigate(PATH_AUTH.login);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout');
    }
  };

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <>
      <StyledNav>
        <Wrap justifyContent="start">
          <StyledIconWrap onClick={toggleDrawer(true)}>
            <HamburgerButton />
          </StyledIconWrap>
        </Wrap>

        <Wrap justifyContent="center">
          <StyledRouterLink to={PATH_DASHBOARD.root}>
            <Box
              display="flex"
              justifyContent="center"
              component="img"
              alt="isistant-logo"
              src={`${MainLogo}`}
            />
          </StyledRouterLink>
        </Wrap>

        <Wrap
          display="flex"
          alignItems="center"
          gap="20px"
          justifyContent="end"
        >
          <StyledIconWrap>
            <SearchIcon />
          </StyledIconWrap>
          <StyledIconWrap>
            <StyledBadge showZero badgeContent={totalQuantity} max={99}>
              <Cart />
            </StyledBadge>
          </StyledIconWrap>
        </Wrap>
      </StyledNav>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '0 12px 12px 0',
            background: '#F5F6F7',
            boxShadow: '0 2px 6px 0 rgba(103, 109, 117, 0.24)',
            width: '332px',
          },
        }}
      >
        <StyledDrawerContainer role="presentation">
          <Box
            sx={{
              width: '44px',
              height: '44px',
              background: '#12121D0D',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            }}
            onClick={toggleDrawer(false)}
          >
            <ArrowLeft />
          </Box>
          <Box
            sx={{
              mt: '8px',
              borderBottom: '1px solid #D5D5D5',
            }}
          />
          <Box display="flex" mt="16px" alignItems="center">
            <Box
              component="img"
              sx={{ width: '68px', height: '68px' }}
              src={user?.image || AccountPlaceHolder}
              mr="19px"
            />
            <Box>
              <Typography
                fontSize="16px"
                fontWeight={700}
                maxWidth="180px"
                lineHeight="28px"
                noWrap
              >
                {user?.last_name} {user?.first_name}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={400}
                color="#616CF5"
                maxWidth="180px"
                lineHeight="16px"
                noWrap
              >
                {user?.email}
              </Typography>
              <Box
                bgcolor="#FFF"
                mt="9px"
                borderRadius="2px"
                p="5px 10px"
                width="90px"
                onClick={() => navigate(PATH_DASHBOARD.user.account)}
              >
                <Typography
                  fontSize="10px"
                  fontWeight={400}
                  color="#939393"
                  maxWidth="180px"
                  lineHeight="16px"
                  noWrap
                >
                  Редактировать
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt="30px">
            <Typography fontSize="12px" fontWeight={400} color="#909090">
              Мой аккаунт
            </Typography>
            <Box mt="15px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #D9D9D9"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate('/')}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Главная
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #D9D9D9"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate(PATH_DASHBOARD.general.catalog_orders)}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  История заказов
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <InIcon />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #D9D9D9"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate(PATH_DASHBOARD.general.my_products)}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Мои товары
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <MyProductIcon />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #D9D9D9"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => navigate(PATH_DASHBOARD.general.balance)}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Баланс
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <BalanceIcon />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="30px">
            <Typography fontSize="12px" fontWeight={400} color="#909090">
              Меню
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #D9D9D9"
              mt="8px"
              p="0 10px 7px 0"
              onClick={() => navigate(PATH_DASHBOARD.general.catalog)}
            >
              <Typography
                fontSize="16px"
                fontWeight={500}
                color="#12121D"
                noWrap
              >
                Каталог
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <LinkIcon />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #D9D9D9"
              mt="8px"
              p="0 10px 7px 0"
              onClick={() =>
                window.open(
                  'https://chrome.google.com/webstore/detail/isistant/ccnjccoemjdajbmaompkjgmoidicnkpb?hl=ru',
                  '_blank',
                )
              }
            >
              <Typography
                fontSize="16px"
                fontWeight={500}
                color="#12121D"
                noWrap
              >
                Расширение
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <LinkIcon />
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                borderBottom="1px solid #D9D9D9"
                mt="8px"
                p="0 10px 7px 0"
                onClick={() => setIsOpenTools((prev) => !prev)}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#12121D"
                  noWrap
                >
                  Инструменты
                </Typography>
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  ml="5px"
                >
                  <ArrowDropDownIcon style={{ color: '#09244B' }} />
                </Box>
              </Box>
              {isOpenTools && (
                <Box mt="8px" borderBottom="1px solid #D9D9D9">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    p="0 0 10px 20px"
                    noWrap
                  >
                    Внутренняя аналитика
                  </Typography>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() => navigate(PATH_DASHBOARD.general.shop)}
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Мой магазин
                    </Typography>
                  </Box>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() =>
                      navigate(PATH_DASHBOARD.general.financial_report)
                    }
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Финансовый Отчет
                    </Typography>
                  </Box>

                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    p="0 0 10px 20px"
                    noWrap
                  >
                    Внешняя аналитика
                  </Typography>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() => navigate(PATH_DASHBOARD.general.analytics)}
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Черный ящик
                    </Typography>
                  </Box>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() =>
                      navigate(PATH_DASHBOARD.general.category_analytics)
                    }
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Аналитика категорий
                    </Typography>
                  </Box>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() =>
                      navigate(PATH_DASHBOARD.general.unit_economy)
                    }
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Кубик Рубика
                    </Typography>
                  </Box>

                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    p="0 0 10px 20px"
                    noWrap
                  >
                    Бизнес инструменты
                  </Typography>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() => navigate(PATH_DASHBOARD.user.cards)}
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Портал специалистов
                    </Typography>
                  </Box>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() =>
                      navigate(PATH_DASHBOARD.general.profit_calculator)
                    }
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Юнит экономика
                    </Typography>
                  </Box>
                  <Box
                    p="0 0 10px 20px"
                    onClick={() =>
                      navigate(PATH_DASHBOARD.general.generate_barcode)
                    }
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={500}
                      color="#12121D"
                      borderLeft="2px solid #D9D9D9"
                      pl={1}
                      noWrap
                    >
                      Баркодер
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              onClick={() => setIsOpenPromotion((prev) => !prev)}
              borderBottom="1px solid #D9D9D9"
              mt="8px"
              p="0 10px 7px 0"
            >
              <Typography
                fontSize="16px"
                fontWeight={500}
                color="#12121D"
                noWrap
              >
                Продвижение
              </Typography>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                ml="5px"
              >
                <ArrowDropDownIcon style={{ color: '#09244B' }} />
              </Box>
            </Box>
            {isOpenPromotion && (
              <Box mt="8px" borderBottom="1px solid #D9D9D9">
                <Box
                  p="0 0 10px 20px"
                  onClick={() => navigate(PATH_DASHBOARD.general.semantic_core)}
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    borderLeft="2px solid #D9D9D9"
                    pl={1}
                    noWrap
                  >
                    Ключевые слова
                  </Typography>
                </Box>
                <Box
                  p="0 0 10px 20px"
                  onClick={() =>
                    navigate(
                      `${PATH_DASHBOARD.general.description_generator}/false`,
                    )
                  }
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    borderLeft="2px solid #D9D9D9"
                    pl={1}
                    noWrap
                  >
                    Ant GPT
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              onClick={() => setIsOpenEducation((prev) => !prev)}
              borderBottom="1px solid #D9D9D9"
              mt="8px"
              p="0 10px 7px 0"
            >
              <Typography
                fontSize="16px"
                fontWeight={500}
                color="#12121D"
                noWrap
              >
                Обучение
              </Typography>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                ml="5px"
              >
                <ArrowDropDownIcon style={{ color: '#09244B' }} />
              </Box>
            </Box>
            {isOpenEducation && (
              <Box mt="8px" borderBottom="1px solid #D9D9D9">
                <Box
                  p="0 0 10px 20px"
                  onClick={() =>
                    navigate(PATH_DASHBOARD.general.video_instructor)
                  }
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    borderLeft="2px solid #D9D9D9"
                    pl={1}
                    noWrap
                  >
                    Видео-инструкция
                  </Typography>
                </Box>
                <Box
                  p="0 0 10px 20px"
                  onClick={() => navigate(PATH_DASHBOARD.general.media_portal)}
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    borderLeft="2px solid #D9D9D9"
                    pl={1}
                    noWrap
                  >
                    Медиапортал
                  </Typography>
                </Box>
                <Box
                  p="0 0 10px 20px"
                  onClick={() =>
                    navigate(PATH_DASHBOARD.general.video_teaching)
                  }
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    borderLeft="2px solid #D9D9D9"
                    pl={1}
                    noWrap
                  >
                    Видео-обучение
                  </Typography>
                </Box>
                <Box
                  p="0 0 10px 20px"
                  onClick={() => navigate(PATH_DASHBOARD.general.forum)}
                >
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#12121D"
                    borderLeft="2px solid #D9D9D9"
                    pl={1}
                    noWrap
                  >
                    Форум
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box mt="50px" onClick={handleOpenModal}>
            <Typography fontSize="14px" fontWeight={400} color="#E60202">
              Выйти из аккаунта
            </Typography>
          </Box>
          <Box mt="27px">
            <Box
              bg="#9C1192"
              sx={{
                background: 'linear-gradient(90deg, #9C1192 0%, #B911A1 100%)',
                p: '8px 12px',
                borderRadius: '4px',
              }}
            >
              <Typography fontSize="14px" fontWeight={400} color="#fff">
                WILDBERRIES
              </Typography>
            </Box>
            <Box
              bg="#9C1192"
              sx={{
                mt: '3px',
                background: '#F14635',
                p: '8px 12px',
                borderRadius: '4px',
              }}
            >
              <Typography
                fontSize="14px"
                fontWeight={400}
                color="rgba(255, 255, 255, 0.32)"
              >
                Kaspi (скоро)
              </Typography>
            </Box>
          </Box>
          <Box mt="24px" display="flex" justifyContent="center">
            <Typography fontSize="12px" fontWeight={400} color="#9291A5">
              Подписка до:{' '}
              {moment().add(+paymentDaysRemain, 'days').format('DD.MM.YY')}
            </Typography>
          </Box>
        </StyledDrawerContainer>
        <CustomModal
          isOpen={modal}
          title={'Вы действительно хотите выйти из системы'}
          submitBtnTitle={'Выйти'}
          onSubmit={() => handleLogout()}
          onClose={() => handleCloseModal()}
        />
      </Drawer>
    </>
  );
}
