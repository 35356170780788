import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';

export const getCategoriesThunk = createAsyncThunk(
  'getCategoriesThunk/categoryTree',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        '/api/store/dashboard/category_tree/',
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  categories: [],
  filteredList: [],

  error: {
    message: null,
    enabled: false,
  },
  loading: false,
};

const categoryTreeSlice = createSlice({
  name: 'categoryTree',
  initialState,
  reducers: {
    setCategories: (state, actions) => {
      const newCategories = actions.payload;

      if (Array.isArray(newCategories)) {
        state.filteredList = newCategories;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesThunk.fulfilled, (state, actions) => {
        const categories = actions.payload;

        if (Array.isArray(categories)) {
          state.categories = categories;
          state.filteredList = categories;
        }
        state.error.enabled = false;
        state.loading = false;
      })
      .addCase(getCategoriesThunk.rejected, (state, actions) => {
        const message = actions.error.message;
        state.error.message = message;
        state.error.enabled = true;
        state.loading = false;
      })
      .addCase(getCategoriesThunk.pending, (state) => {
        state.loading = true;
      });
  },
});

export const categoryTreeActions = categoryTreeSlice.actions;
export default categoryTreeSlice.reducer;
