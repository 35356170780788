export const DATA_IDS = {
  login: {
    selector_option: (option_id) => `option_${option_id}`,
    first_name: 'first_name',
    phone_number: 'phone_number',
    role: 'role',
    email: 'email',
    password: 'password',
    submit_button: 'submit_button',
    reset_password: 'reset_password',
    new_password: 'new_password',
    confirm_new_password: 'confirm_new_password',
    change_password_submit: 'change_password_submit',
    send_email_reset_password_text: 'send_email_reset_password_text',
  },

  dashboard: {
    navbar: {
      user_avatar_btn: 'user_avatar_button',
      search_by_sku: 'search_by_sku',
      hire_specialist_btn: 'hire_specialist',
      tarifs_btn: 'tarifs_btn',
      bind_token_btn: 'bind_token_btn',
      notification_text: 'notification_text',
      update_data_btn: 'update_data_btn',
    },
    sidebar_open_bureger_menu: 'sidebar_open_bureger_menu',
    left_sidebar_close_button: 'left-sidebar-close-button',
    sidebar_close_menu: 'sidebar_close_menu',
    left_sidebar: 'left_sidebar',
    home_link_btn: 'home_link_btn',
    setting_link_btn: 'setting_link_btn',
    subscribe_btn: 'subscribe_btn',
    wildberries_link_btn: 'wildberries_link_btn',
    logout_btn: 'logout_btn',
    modal_submit_btn: 'modal_submit_btn',
    payment_guard_page: 'payment_guard_page',

    myShop: {
      title: 'my_shop_title',
      more_details_btn: {
        giro: 'more_detail_giro_btn',
        sales_count: 'more_detail_sales_count_btn',
        revenue: 'more_detail_revenue_btn',
        cancel_percent: 'more_detail_cancel_percent_btn',
      },
      chart_select: 'chart_select_option',
      chart_select_option: {
        zakaz_vykup: 'zakaz_option',
        oborot_dohod: 'oborot_dohod',
      },
      brands_option: 'brends_otpion',
    },
    user: {
      billing: {
        payment_history: 'payment_history',
        payment_history_pdf: 'payment_history_pdf',
        info_prices: 'info_prices',
        action_button: 'action_button',
        payment_methods_cancel_button: 'payment_methods_cancel_button',
        payment_methods: {
          visa: 'visa',
          mastercard: 'mastercard',
          elcart: 'elcart',
          mbank: 'mbank',
        },
        fixture: {
          basic: 'basic',
          wildberries_3_month: 'wildberries_3_month',
          test_1_month: 'test_1_month',
          premium_plus_teaching: 'premium_plus_teaching',
          wildberries_plus_kaspi: 'wildberries_plus_kaspi',
          wildberries: 'wildberries',
          premium_plus_teaching_3_month: 'premium_plus_teaching_3_month',
          kaspi_3_month: 'kaspi_3_month',
          kaspi_1_month: 'kaspi_1_month',
        },
      },
    },
    forum: {
      tab_values: {
        forum_tab_value_popular: 'forum_tab_value_popular',
        forum_tab_value_new: 'forum_tab_value_new',
        forum_tab_value_save: 'forum_tab_value_save',
      },
      post_item_link: 'post_item_link',
      save_post_btn: 'save_post_btn',
      saved_icon: 'saved_icon',
      unsave_icon: 'unsave_icon',
      rating_inc_btn: 'rating_inc_btn',
      rating_dec_btn: 'rating_dec_btn',
      rating_value: 'rating_value',
      post_item: 'post_item',
      comment_item: 'comment_item',
      share_button: 'share_button',
      comment_text: 'comment_text',
      comment_upvote: 'comment_upvote',
      comment_downvote: 'comment_downvote',
      comment_save: 'comment_save',
    },
    financial_report: {
      token_user_table: 'token_user_table',
      no_token_user_table: 'no_token_user_table',
      financial_report_add_token: 'financial_report_add_token',
      not_available_trial_version: 'not_available_trial_version',
      financial_report_table: 'financial_report_table',
      add_token: 'add_token',
    },
    eCommerce: {
      list: {
        tableHead: {
          product_name: 'product_name',
          giro: 'giro',
          revenue: 'revenue',
          sales_count: 'sales_count',
          cancel_percent: 'cancel_percent',
          turnover: 'turnover',
        },
        tableBody: {
          tr: 'tr',
        },
        toolbar: {
          search: 'toolbar_search',
          product_not_found: 'product_not_found',
        },
      },
    },
  },
  landing: {
    try_free_get: 'try_free_get',
    huge_pack_elements_get: 'huge_pack_elements_get',
    advertisement_start: 'advertisement_start',
    dashboard: 'root',
    login: 'login',
    forum: 'forum',
    price: 'price',
    portal_specialist: 'portal_specialist',
  },
  footer: {
    terms: 'terms',
    privacy: 'privacy',
    our_extension: 'our_extension',
    support: 'support',
    phone_number: 'phone_number',
    sales_team: 'sales_team',
    youtube: 'youtube',
    telegram: 'telegram',
    instagram: 'instagram',
    facebook: 'facebook',
  },
  components: {
    table: {
      pagination: {
        next_button: 'next_button',
        previous_button: 'previous_button',
      },
    },
    info: {
      icon: 'information_icon',
    },
    files: {
      download_csv: 'download_csv',
      download_xlsx: 'download_xlsx',
    },
  },

  settings: {
    tabs: {
      api_key: 'api_key',
      profile: 'profile',
      change_password: 'change_password',
      notification: 'notification',
    },
    profile: {
      name: 'profile_name',
      last_name: 'profile_last_name',
      phone_number: 'profile_phone_number',
      save_button: 'profile_save_button',
    },
    change_password: {
      current_password: 'current_password',
      new_password: 'new_password',
      confirm_new_password: 'confirm_new_password',
      change_password_btn: 'change_password_button',
    },
    api_key: {
      seller_name: 'seller_name',
      token_x64: 'token_x64',
      save_button: 'token_save_btn',
    },
  },
};
