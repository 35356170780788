import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { API } from 'src/apis';
import {
  TIME_LIST_LABELS,
  TIME_LIST_VALUES,
  getValueTimeList,
} from 'src/components/_dashboard/external-analytics/helpers/time-list';
import { SELECT_VALUES } from 'src/components/_dashboard/external-analytics/tables/components/header-dropdown';
import axiosInstance from 'src/utils/axios';
import { checkArray } from 'src/utils/flattenedArray';
import { dummy_datas } from 'src/utils/mock-data/productDetail';
import {
  generateMonthArray,
  getRandomCategories,
  getRandomKeywords,
  getRandomWarehouses,
} from 'src/utils/mock-data/warehouses';

const initialState = {
  loading: false,
  timeRange: { label: TIME_LIST_LABELS.month, value: TIME_LIST_VALUES.month },
  detail: {
    image_url: '',
    brand: {},
    seller: {},
    sales_count: 0,
    sales_amount: 0,
    warehouses: [],
    warehouses_table: [],
    sizes_table: [],
    separate_group: [],
    price_history: {},
    sales_history: {},
    quantity_history: {},
    sizes: [],
    colors: [],
    name: '',
    reviews_count: 0,
    stars: 0,
    final_price: 0,
    position_query: [],
    position_category_query: [],
    buybacks_percent: 0,
    lost_revenue_count: 0,
    lost_revenue_price: 0,
    order_count: 0,
    order_price: 0,
    redemption_percentage: 0,
    selling_percent: 0,
    selling_price: 0,
    sales_balances_chart: [],
    sales_balances_table: [],
    sales_balances_table_footer: [],
    analysis_feedback: [],
    made_country: '',
    сommission: 0,
    first_of_obbers: null,
    categories: [],
    sandbox: '',
    images: [],
    product_positions_in_search: {},
    product_positions_in_categories: {},
    product_positions_in_search_dates: [],
  },
  times: generateMonthArray(),
  selectedObj: {},
};

const filterByKey = (key, value, greaterThan, date) => (array) => {
  const filteredArray = checkArray(array).filter((item) => {
    if (key === 'date') {
      const value = new Date(item[key]);
      const startDate = new Date(date?.start);
      const endDate = new Date(date?.end);

      if (date?.start && date?.end) {
        // Extract day, month, and year components
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth();
        const startYear = startDate.getFullYear();

        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth();
        const endYear = endDate.getFullYear();

        // Extract day, month, and year components from the 'value' date
        const valueDay = value.getDate();
        const valueMonth = value.getMonth();
        const valueYear = value.getFullYear();

        // Check if 'value' is within the specified date range
        return (
          valueYear >= startYear &&
          valueYear <= endYear &&
          valueMonth >= startMonth &&
          valueMonth <= endMonth &&
          valueDay >= startDay &&
          valueDay <= endDay
        );
      }

      if (date?.start) {
        // Extract day, month, and year components
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth();
        const startYear = startDate.getFullYear();

        // Extract day, month, and year components from the 'value' date
        const valueDay = value.getDate();
        const valueMonth = value.getMonth();
        const valueYear = value.getFullYear();

        // Check if 'value' is greater than or equal to 'startDate'
        return (
          valueYear >= startYear &&
          valueMonth >= startMonth &&
          valueDay >= startDay
        );
      }

      if (date?.end) {
        // Extract day, month, and year components
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth();
        const endYear = endDate.getFullYear();

        // Extract day, month, and year components from the 'value' date
        const valueDay = value.getDate();
        const valueMonth = value.getMonth();
        const valueYear = value.getFullYear();

        // Check if 'value' is less than or equal to 'endDate'
        return (
          valueYear <= endYear && valueMonth <= endMonth && valueDay <= endDay
        );
      }
    }
    if (value.length === 0 || !item.hasOwnProperty(key)) {
      return true; // Keep the item if value is empty or key is missing
    }
    const keys = [
      'name',
      'answer',
      'feedback',
      'category',
      'brand',
      'seller',
      'color',
    ];

    if (keys.includes(key)) {
      const itemWithoutSpaces = String(item[key] || '').replace(/\s+/g, '');
      const queryWithoutSpaces = String(value).replace(/\s+/g, '');

      // Convert both the item and query to lowercase for case-insensitive search
      const itemLowerCase = itemWithoutSpaces.toLowerCase();
      const queryLowerCase = queryWithoutSpaces.toLowerCase();

      // Check if the item includes the query (case-insensitive)
      return itemLowerCase.includes(queryLowerCase);
    }

    const itemValue = parseFloat(String(item[key]).replace(/\s/g, ''));
    const parsedValue = parseFloat(String(value).replace(/\s/g, ''));

    if (Number.isNaN(itemValue) || Number.isNaN(parsedValue)) {
      return false;
    }

    if (greaterThan === SELECT_VALUES.fewer) {
      return itemValue < parsedValue;
    } else if (greaterThan === SELECT_VALUES.larger) {
      return itemValue > parsedValue;
    }

    return true; // Keep the item by default if no conditions match
  });

  return filteredArray;
};

const filteredData = (array, filter, filterType, date) => {
  const builder = _.flow(
    _.map(filter, (value, key) => {
      return filterByKey(key, value, filterType, date);
    }),
  );

  return builder(array);
};

export const getProductsDetailThunk = createAsyncThunk(
  'getProductsDetail/product-detail',
  async ({ product_id }, { getState, rejectWithValue }) => {
    const dateFilter = getState().product_detail.timeRange.value || '1';
    try {
      const { data } = await axiosInstance.get(
        API.analytics + `products/${product_id}/`,
        {
          params: { date: dateFilter },
        },
      );
      return data;
    } catch (e) {
      console.error(e);
      rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'product-detail',
  initialState,
  reducers: {
    timeChangeHandler: (state, action) => {
      const value = action.payload;

      if (value) {
        const { name: label } = getValueTimeList(value);

        state.timeRange = { value, label };
      }
    },

    searchProductSales: (state, action) => {
      const { objKey, query, filterType, array, date } = action.payload;

      const filter = {
        ...state.selectedObj?.sales_balances_table,
        [objKey]: query,
      };

      state.selectedObj.sales_balances_table = filter;

      const sales_balances_table = filteredData(
        array,
        filter,
        filterType,
        date,
      );

      state.detail.sales_balances_table = sales_balances_table;
    },

    searchPositionQuery: (state, action) => {
      const { objKey, query, filterType, array } = action.payload;

      const filter = {
        ...state.selectedObj?.position_query,
        [objKey]: query,
      };

      state.selectedObj.position_query = filter;

      state.detail.position_query = filteredData(array, filter, filterType);
    },

    searchPositionCategoryQuery: (state, action) => {
      const { objKey, query, filterType, array } = action.payload;

      const filter = {
        ...state.selectedObj?.position_category_query,
        [objKey]: query,
      };

      state.selectedObj.position_category_query = filter;

      state.detail.position_category_query = filteredData(
        array,
        filter,
        filterType,
      );
    },
    searchSizes: (state, action) => {
      const { objKey, query, filterType, array } = action.payload;

      const filter = {
        ...state.selectedObj?.sizes_table,
        [objKey]: query,
      };

      state.selectedObj.sizes_table = filter;

      state.detail.sizes_table = filteredData(array, filter, filterType);
    },
    searchWarehouses: (state, action) => {
      const { objKey, query, filterType, array } = action.payload;

      const filter = {
        ...state.selectedObj?.warehouses_table,
        [objKey]: query,
      };

      state.selectedObj.warehouses_table = filter;

      state.detail.warehouses_table = filteredData(array, filter, filterType);
    },
    searchFeedback: (state, action) => {
      const { objKey, query, filterType, array, date } = action.payload;

      const filter = {
        ...state.selectedObj?.analysis_feedback,
        [objKey]: query,
      };

      state.selectedObj.analysis_feedback = filter;

      state.detail.analysis_feedback = filteredData(
        array,
        filter,
        filterType,
        date,
      );
    },
    searchSeparateGroup: (state, action) => {
      const { objKey, query, filterType, array, date } = action.payload;

      const filter = {
        ...state.selectedObj?.separate_group,
        [objKey]: query,
      };

      state.selectedObj.separate_group = filter;

      state.detail.separate_group = filteredData(
        array,
        filter,
        filterType,
        date,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsDetailThunk.fulfilled, (state, action) => {
        const details = action.payload;

        const detail = {
          ...details,
        };

        const tableKeys = [
          'position_query',
          'position_category_query',
          'sizes_table',
          'warehouses_table',
        ];
        const selectedObj = {
          ...tableKeys.reduce((previous, current) => {
            const currentArray = checkArray(detail?.[current]);
            const currentValue = currentArray ? currentArray.pop() : undefined;

            return {
              ...previous,
              [current]: Object.keys(currentValue || {}).reduce(
                (acc, current) => {
                  return { ...acc, [current]: '' };
                },
                {},
              ),
            };
          }, {}),
        };

        state.detail = detail;
        state.selectedObj = selectedObj;

        state.loading = false;
      })
      .addCase(getProductsDetailThunk.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProductsDetailThunk.pending, (state) => {
        state.loading = true;
      });
  },
});
export const productDetailActions = slice.actions;
// Reducer
export default slice.reducer;

export const productDetailSelector = (state) => {
  return state?.product_detail || {};
};
