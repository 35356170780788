import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return `${numeral(number).format(
    Number.isInteger(number) ? '0,0' : '0,0.00',
  )} ₽`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fPrice(number) {
  if (isNaN(number) || typeof number === 'string') {
    return number;
  }

  const formattedNumber = parseFloat(number.toFixed(2))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return formattedNumber;
}
export const fInputPriceMask = (number) => {
  numeral.localeData().delimiters.thousands = ' ';
  const value = number.replace(/\D/g, '');
  return numeral(value).format('0,0');
};
