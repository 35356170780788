import PropTypes from 'prop-types';
// material
import {
  Stack,
  Button,
  Typography,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from '@mui/material';
import ReactToPdf from 'react-to-pdf';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

const { billing } = DATA_IDS.dashboard.user;

const options = {
  orientation: 'landscape',
};

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '3px',
  '&:first-of-type': { paddingLeft: 0 },
  '&:last-of-type': { paddingRight: 0 },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '3px',
  '&:first-of-type': { paddingLeft: 0 },
  '&:last-of-type': { paddingRight: 0 },
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
  },
}));

// ----------------------------------------------------------------------

const TABLE_HEAD = ['№', 'Дата', 'Сумма', 'Описание', 'ID', 'Статус'];

AccountBillingInvoiceHistory.propTypes = {
  invoices: PropTypes.array,
};

const monthShortener = (date) => {
  const arrStr = date.split(' ');
  if (arrStr[1].length > 3) {
    arrStr[1] = arrStr[1].slice(0, 3);
    return arrStr.join(' ');
  } else {
    return arrStr.join(' ');
  }
};

const NoData = () => (
  <Typography color="#808080" fontSize={'11px'}>
    нет данных
  </Typography>
);

export default function AccountBillingInvoiceHistory({ invoices }) {
  const navigate = useNavigate();

  const navigateToPaymentCheck = (id, invoice) => {
    navigate(PATH_DASHBOARD.user.billing + `/${id}`, {
      state: {
        invoice,
      },
    });
  };

  return (
    <Card sx={{ p: 2, height: '100%', width: '100%' }}>
      <ReactToPdf options={options} x={20} y={10} filename="история-оплат.pdf">
        {({ toPdf, targetRef }) => (
          <Box component="div" ref={targetRef}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ width: '100%', py: 0.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: 1 }}
                {...setDataTestId({ name: billing.payment_history })}
              >
                История оплат
              </Typography>
              <Button
                onClick={toPdf}
                {...setDataTestId({ name: billing.payment_history_pdf })}
              >
                PDF
              </Button>
            </Stack>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((title, i) => (
                      <StyledHeaderCell key={i}>{title}</StyledHeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(invoices || []).map((row, i) => (
                    <TableRow
                      key={row?.id}
                      hover
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => navigateToPaymentCheck(row.id, row)}
                    >
                      <StyledTableCell>#{row.id}</StyledTableCell>
                      <StyledTableCell>
                        {monthShortener(row?.created_date)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.amount} {row.currency}
                      </StyledTableCell>
                      <StyledTableCell>{row?.description} </StyledTableCell>
                      <StyledTableCell>
                        {row?.transaction_id || <NoData />}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="body2"
                          sx={{
                            color: `${row.status_color}.main`,
                            fontSize: 'inherit',
                          }}
                        >
                          {row.status}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </ReactToPdf>
    </Card>
  );
}
