import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import LoadingScreen from '../components/LoadingScreen';
import PaymentGuard from 'src/components/PaymentGuard';
import PAGES_ACCESS from 'src/constants';
import PromoCode from 'src/pages/dashboard/PromoCode';
import DashboardLayout from 'src/layouts/dashboard/wb';
import { PATH_DASHBOARD } from './paths';
import EcommerceCheckout from '../pages/dashboard/EcommerceCheckout';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const lazyRetry = (componentImport) => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export default function Router() {
  const promocode = localStorage.getItem('promo');

  const autoNavigationLink = promocode
    ? PATH_DASHBOARD.general.promocode
    : PATH_DASHBOARD.general.shop;

  const routes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'reset-password-confirm/:uid/:token',
          element: <ResetPasswordConfirm />,
        },
        { path: 'confirm-email-sent', element: <VerifyCodeSent /> },
        { path: 'email-verify-confirm/:key/', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={autoNavigationLink} replace />,
        },
        {
          path: 'shop',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.shop}>
              <MyShop />
            </PaymentGuard>
          ),
        },
        {
          path: 'analytics/*',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.analytics}>
              <MyAnalytics />
            </PaymentGuard>
          ),
        },
        {
          path: 'analytics/:id',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.analytics}>
              <ProductDetails />
            </PaymentGuard>
          ),
        },

        {
          path: 'brand/:id',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.analytics}>
              <BrandsPage />
            </PaymentGuard>
          ),
        },
        { path: 'promocode', element: <PromoCode /> },
        {
          path: PATH_DASHBOARD.general.video_instructor,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.video_instructor}>
              <VideoPortal />
            </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.video_instructor}/:id`,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.video_instructor}>
              <VideoPortalDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'module-portal/:id',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.video_instructor}>
              <VideoWithModulesPortalDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'media-portal',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.media_portal}>
              <MediaPortal />
            </PaymentGuard>
          ),
        },
        {
          path: 'media-portal/:id',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.media_portal}>
              <MediaPortalDetail />
            </PaymentGuard>
          ),
        },

        {
          path: PATH_DASHBOARD.general.video_teaching,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.video_teaching}>
              <VideoTeaching />
            </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.video_teaching}/:id`,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.video_teaching}>
              <VideoTeachingDetails />
            </PaymentGuard>
          ),
        },

        {
          path: 'forum',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.forum}>
              <Forum />
            </PaymentGuard>
          ),
        },
        {
          path: 'forum/:slug',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.forum}>
              <ForumDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'financial-report',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.financial_report}>
              <FinancialReport />
            </PaymentGuard>
          ),
        },
        {
          path: 'unit-economy',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.unit_economy}>
              <UnitEconomy />
            </PaymentGuard>
          ),
        },
        {
          path: 'profit_calculator',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.profit_calculator}>
              <NewProfitCalculator />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalytics />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id/:innerId',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id/:innerId/:grandId',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id/:innerId/:grandId/:category',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id/:innerId/:grandId/:category/:subCategory',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id/:innerId/:grandId/:category/:subCategory/:item',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'category-analytics/:id/:innerId/:grandId/:category/:subCategory/:item/:details',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.category_analytics}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.generate_barcode,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.generate_barcode}>
              <GenerateBarcode />
            </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.catalog,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <Catalog />
            // </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.catalog}/:id`,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.internal_analytics}>
              <EcommerceProductDetails />
            </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.checkout,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <EcommerceCheckout />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.catalog_favorites,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CatalogFavorites />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.catalog_orders,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CatalogOrders />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.catalog_order_details,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CatalogOrderDetails />
            // </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.catalog_order_details}/:id`,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CatalogOrderDetails />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.my_products,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CatalogMyProducts />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.balance,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <Balance />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.create_order,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CreateOrder />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.ad,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <Ad />
            // </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.campany_statistic_details}/:id`,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CampanyStatisticDetails />
            // </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.campany_details}/:id/:isAutoCampany`,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CampanyDetails />
            // </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.campany_details,
          element: (
            // <PaymentGuard pageKey={PAGES_ACCESS.catalog}>
            <CampanyDetails />
            // </PaymentGuard>
          ),
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            {
              path: 'shop',
              element: (
                <PaymentGuard pageKey={PAGES_ACCESS.internal_analytics}>
                  <EcommerceShop />
                </PaymentGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <PaymentGuard pageKey={PAGES_ACCESS.internal_analytics}>
                  <EcommerceProductList />
                </PaymentGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            {
              path: 'cards/:id',
              element: <UserProfile />,
            },
            {
              path: 'cards',
              element: (
                <PaymentGuard pageKey={PAGES_ACCESS.user_cards}>
                  <UserCards />
                </PaymentGuard>
              ),
            },
            {
              path: 'new/:id',
              element: (
                <PaymentGuard pageKey={PAGES_ACCESS.user_cards}>
                  <CreatePortalUser />
                </PaymentGuard>
              ),
            },
            { path: 'account', element: <UserAccount /> },
            { path: 'billing', element: <AccountBilling /> },
          ],
        },
        {
          path: 'featured-categories',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id/:childId',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id/:childId/:grandId',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id/:childId/:grandId/:category',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id/:childId/:grandId/:category/:subcategory',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id/:childId/:grandId/:category/:subcategory/:item',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: 'featured-categories/:id/:childId/:grandId/:category/:subcategory/:item/:details',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.featured_categories}>
              <CategoryAnalyticsDetail />
            </PaymentGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.semantic_core,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.semantic_core}>
              <SemantickCore />
            </PaymentGuard>
          ),
        },
        {
          path: `${PATH_DASHBOARD.general.description_generator}/:fromSemanticCore`,
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.description_generator}>
              <DescriptionGenerator />
            </PaymentGuard>
          ),
        },
        {
          path: 'auto-dumping',
          element: (
            <PaymentGuard pageKey={PAGES_ACCESS.auto_dumping}>
              <AutoDumping />
            </PaymentGuard>
          ),
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'terms', element: <Terms /> },
        { path: 'agreement', element: <Agreement /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'promocode/:promo', element: <UnregisteredPromo /> },
        { path: 'forum', element: <ForumExternal /> },
        { path: 'forum/:slug', element: <ForumDetailExternal /> },
        { path: 'price', element: <TariffPlans /> },
        { path: `user/cards/:id`, element: <UserProfileExternal /> },
        { path: 'user/cards', element: <UserCardsExternal /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(routes);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(
  lazy(() => import('../pages/authentication/Register')),
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword')),
);
const ResetPasswordConfirm = Loadable(
  lazy(() => import('../pages/authentication/ResetPasswordConfirm')),
);
const VerifyCode = Loadable(
  lazy(() => import('../pages/authentication/VerifyCode')),
);
const VerifyCodeSent = Loadable(
  lazy(() => import('../pages/authentication/VerifyCodeSent')),
);
// Dashboard
const MyAnalytics = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/MyAnalytics'))),
);
const MyShop = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/MyShop'))),
);
const EcommerceShop = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/EcommerceShop'))),
);
const EcommerceProductDetails = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/EcommerceProductDetails')),
  ),
);
const EcommerceProductList = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/EcommerceProductList')),
  ),
);
const UserProfile = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/UserProfile'))),
);
const UserProfileExternal = Loadable(
  lazy(() => lazyRetry(() => import('../pages/UserProfileExternal'))),
);
const UserCards = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/UserCards'))),
);
const UserCardsExternal = Loadable(
  lazy(() => lazyRetry(() => import('../pages/UserCardsExternal'))),
);
const UserAccount = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/UserAccount'))),
);
const ProductDetails = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ProductDetails'))),
);
const BrandsPage = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/BrandsDetail'))),
);
const AccountBilling = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import('../components/_dashboard/user/account/AccountBilling'),
    ),
  ),
);
const VideoPortal = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/VideoPortal'))),
);
const VideoPortalDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/VideoPortalDetail'))),
);

const VideoWithModulesPortalDetail = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/VideoWithModulesPortalDetail')),
  ),
);

const MediaPortal = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/MediaPortal'))),
);
const MediaPortalDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/MediaPortalDetail'))),
);

const Forum = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Forum'))),
);
const ForumDetail = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/ForumDetail'))),
);
const FinancialReport = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/FinancialReport'))),
);
const UnitEconomy = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/UnitEconomy.js'))),
);

const NewProfitCalculator = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/NewProfitCalculator.js')),
  ),
);
const CategoryAnalytics = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/CategoryAnalytics'))),
);
const CategoryAnalyticsDetail = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/CategoryAnalyticsDetail')),
  ),
);
const LandingPage = Loadable(
  lazy(() => lazyRetry(() => import('../pages/LandingPage'))),
);
const Terms = Loadable(lazy(() => lazyRetry(() => import('../pages/Terms'))));
const Privacy = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Privacy'))),
);
const Agreement = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Agreement'))),
);
const Maintenance = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Maintenance'))),
);
const Page500 = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Page500'))),
);
const NotFound = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Page404'))),
);
const UnregisteredPromo = Loadable(
  lazy(() => lazyRetry(() => import('../pages/UnregisteredPromo'))),
);
const ForumExternal = Loadable(
  lazy(() => lazyRetry(() => import('../pages/Forum'))),
);
const ForumDetailExternal = Loadable(
  lazy(() => lazyRetry(() => import('../pages/ForumDetail'))),
);
const TariffPlans = Loadable(
  lazy(() => lazyRetry(() => import('../pages/TariffPlans'))),
);

const SemantickCore = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/SemantikCore/Semantic_core')),
  ),
);

const DescriptionGenerator = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/DescriptionGenerator')),
  ),
);

const GenerateBarcode = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/GenerateBarcode.js'))),
);

const CreatePortalUser = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/UserCreate'))),
);

const AutoDumping = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/AutoDumping'))),
);
const VideoTeaching = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/VideoTeaching'))),
);

const VideoTeachingDetails = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/VideoTeachingDetails')),
  ),
);

const Catalog = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Catalog/Catalog'))),
);

const CatalogFavorites = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Catalog/CatalogFavorites')),
  ),
);

const CatalogOrders = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Catalog/Orders'))),
);

const CatalogOrderDetails = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/Catalog/CatalogOrderDetails')),
  ),
);

const CatalogMyProducts = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Catalog/MyProducts'))),
);

const Balance = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Catalog/Balance'))),
);

const CreateOrder = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Catalog/CreateOrder'))),
);

const Ad = Loadable(
  lazy(() => lazyRetry(() => import('../pages/dashboard/Ad/Ad'))),
);

const CampanyDetails = Loadable(
  lazy(() =>
    lazyRetry(() => import('../pages/dashboard/CampanyDetails/CampanyDetails')),
  ),
);

const CampanyStatisticDetails = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import(
        '../pages/dashboard/CampanyStatisticDetails/CampanyStatisticDetails'
      ),
    ),
  ),
);
