import { Box, Grid, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'src/apis';
import axiosInstance from 'src/utils/axios';
import PlanCard from '../_dashboard/user/account/PlanCard/PlanCard';
import toast from 'src/utils/snackBarUtils';

const makeRequestChangePlan = async (planId, method) => {
  await axiosInstance
    .post(
      '/api/billing/change_plan/',
      { plan_id: planId },
      {
        params: {
          method: method,
        },
      },
    )
    .then((res) => {
      toast.success(res.data['detail']);
    })
    .catch((e) => {
      toast.error('Ошибка при смене плаана, повторите чуть позже');
    });
};

const TariffsSkeletonLoad = (
  <Stack
    direction="row"
    justifyContent={{ xs: 'center', sm: 'space-between' }}
    sx={{ flexWrap: 'wrap', mb: '20px' }}
  >
    {[...Array(3)].map((_, i) => (
      <Skeleton
        key={i}
        variant="rectangular"
        width={'32%'}
        height={500}
        sx={{ p: '20px', borderRadius: '2%' }}
      />
    ))}
  </Stack>
);

const fetchUserPlans = async () => {
  try {
    const { data } = await axiosInstance.get(API.billing + 'plans/');
    return data;
  } catch (e) {
    console.error(e);
    // eslint-disable-next-line no-throw-literal
    throw 'Не удалось загрузить информацию о тарифах';
  }
};

const doUniqueValues = (array = []) => {
  const allFeatures = array.flatMap((item) => item.features);

  const uniqueFeaturesMap = new Map();
  allFeatures.forEach((feature) => {
    const sortName = String(feature.name).toLowerCase().trim();
    if (!uniqueFeaturesMap.has(sortName)) {
      uniqueFeaturesMap.set(sortName, {
        ...feature,
        sortName,
      });
    }
  });

  return Array.from(uniqueFeaturesMap.values());
};

export default function TariffsPlanCards() {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isThreeMonth, setIsThreeMonth] = useState(false);
  const { cards, paymentDaysRemain } = useSelector((state) => state.user);

  const getAllPlans = () => {
    if (!loading) setLoading(true);
    fetchUserPlans()
      .then((res) => {
        setPlans(res);
      })
      .catch((e) => {
        toast.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allFeatures = doUniqueValues(plans);

  const changePlan = async (id, method, isFreePlan = false) => {
    try {
      if (isFreePlan) {
        await makeRequestChangePlan(id, method);

        getAllPlans();

        return;
      }

      if (method === 'mbank') {
        const { data } = await axiosInstance.get(
          API.billing + id + '?payment_method=internetbank',
        );
        window.open(`${data.url}`, '_blank');
      } else if (cards.length) {
        await makeRequestChangePlan(id, method);

        getAllPlans();
      } else {
        const { data } = await axiosInstance.post(
          API.billing + 'cards/save/?plan_id=' + id,
        );
        window.open(`${data.url}`, '_blank');
      }
    } catch (e) {
      toast.error('Не удалось обновить тарифный план');
    }
  };

  return loading ? (
    TariffsSkeletonLoad
  ) : (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        mb={2}
      >
        <Typography variant="h4" mb={2}>
          <span style={{ color: isThreeMonth && '#6b6b6b' }}>Ежемесячно</span>
          <Switch
            checked={isThreeMonth}
            onClick={() => setIsThreeMonth(!isThreeMonth)}
          />
          <span style={{ color: !isThreeMonth && '#6b6b6b' }}>
            Раз в три месяца
          </span>
          <span style={{ color: isThreeMonth ? '#19A760' : '#6b6b6b' }}>
            {' '}
            (Скидка до 30%)
          </span>
        </Typography>
      </Box>

      <Grid
        container
        justifyContent={'space-between'}
        alignItems={{ xs: 'stretch' }}
        spacing={{ md: 1, lg: 4 }}
      >
        {plans
          .filter((plan) => plan.is_three_month === isThreeMonth)
          .map((plan, i) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              changePlan={changePlan}
              index={i}
              paymentDaysRemain={paymentDaysRemain}
              allFeatures={allFeatures}
            />
          ))}
      </Grid>
    </>
  );
}
