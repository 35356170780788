export { default as AccountBilling } from './AccountBilling';
export { default as AccountBillingAddressBook } from './AccountBillingAddressBook';
export { default as AccountBillingInvoiceHistory } from './AccountBillingInvoiceHistory';
export { default as AccountBillingPaymentMethod } from './AccountBillingPaymentMethod';
export { default as AccountChangePassword } from './AccountChangePassword';
export { default as AccountGeneral } from './AccountGeneral';
export { default as AccountStore } from './AccountStore';
export { default as AccountNotifications } from './AccountNotifications';
export { default as AccountSocialLinks } from './AccountSocialLinks';

export const subscriptionDurationParser = (duration) => {
  switch (duration) {
    case 0:
      return 'Всегда';
    case 1:
      return '1 месяц';
    case 3:
      return '3 месяца';
    case 6:
      return ' 6 месяцев';
    case 12:
      return ' 1 год';
    // eslint-disable-next-line no-duplicate-case
    case 0:
      return 'Навсегда';
    default:
      return `${duration} мес.`;
  }
};

export const currencyParser = (currency) => {
  switch (currency) {
    case 'price':
      return { name: 'сом', discount: 'discount_price_som' };
    case 'price_kzt':
      return { name: '₸', discount: 'discount_price_kzt' };
    case 'price_rub':
      return { name: '₽', discount: 'discount_price_rub' };
    default:
      return currency;
  }
};

export const sortingCurrencies = (plan) => {
  const keys = Object.keys(plan);
  const som = keys.findIndex((k) => k === 'price');
  const kzt = keys.findIndex((k) => k === 'price_kzt');
  const rub = keys.findIndex((k) => k === 'price_rub');
  const sortedIndex = [som, kzt, rub].sort((a, b) => a - b);
  return sortedIndex.map((val) => keys[val]);
};
