import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Box,
  Paper,
  Stack,
  Card,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import axiosInstance from 'src/utils/axios';
import { useDispatch } from 'src/redux/store';
import { getCards } from 'src/redux/slices/user';
import { API } from 'src/apis';
import toast from 'src/utils/snackBarUtils';
import CustomModal from '../../../customModal/CustomModal';

// ----------------------------------------------------------------------

AccountBillingPaymentMethod.propTypes = {
  cards: PropTypes.array,
};

export default function AccountBillingPaymentMethod({ cards }) {
  const [showCardRmvModal, setShowCardRmvModal] = useState(false);
  const [defaultCard, setDefaultCard] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const cardDefault = cards.find((o) => o.is_default);
    if (cardDefault) {
      setDefaultCard(cardDefault.id);
    }
  }, [cards]);

  const changeDefaultCard = ({ target }, id) => {
    setDefaultCard(target.value);
    sendDefaultCard(id);
  };

  const sendDefaultCard = async (id) => {
    try {
      await axiosInstance.put(API.billing + 'change_default_card/', {
        card_id: id,
      });
      dispatch(getCards());
      toast.success('Дефолтная карта обновлена');
    } catch (e) {
      console.error(e);
      toast.error('Не удалось обновить дефолтную карту');
    }
  };

  const deleteCard = async (id) => {
    try {
      await axiosInstance.delete(API.billing + 'delete_card/', {
        data: {
          card_id: id,
        },
      });
      dispatch(getCards());
      toast.success('Карта удалена');
    } catch (e) {
      console.error(e);
      toast.error('Не удалось удалить карту');
    }
  };

  const addCard = async () => {
    try {
      const { data } = await axiosInstance.post(API.billing + 'cards/save/');
      window.open(`${data?.url}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error('Не удалось добавить карту');
    }
  };

  return (
    <Card sx={{ p: 2, height: '100%', width: '100%' }}>
      <Typography
        variant="overline"
        sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
      >
        Методы оплат
      </Typography>

      {cards.length ? (
        <Stack spacing={2} sx={{ maxHeight: 400, overflow: 'scroll' }}>
          {cards.map((card) => (
            <div key={card.id}>
              <Paper
                sx={{
                  p: 3,
                  width: 1,
                  position: 'relative',
                  border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                }}
              >
                {card.card_type === 'MASTERCARD' && (
                  <Box
                    component="img"
                    alt="icon"
                    src={'/static/icons/ic_mastercard.svg'}
                    sx={{ mb: 1 }}
                  />
                )}

                {card.card_type === 'VISA' && (
                  <Box
                    component="img"
                    alt="icon"
                    src={'/static/icons/ic_visa.svg'}
                    sx={{ mb: 1 }}
                  />
                )}

                <Typography variant="subtitle2">{card.card_pan}</Typography>
                <Stack>
                  <Tooltip
                    title={
                      card.id === defaultCard ? '' : 'Установить как дефолтное'
                    }
                  >
                    <FormControlLabel
                      sx={{
                        top: 8,
                        right: -5,
                        position: 'absolute',
                      }}
                      value={card.id}
                      control={
                        <Radio size="small" checked={card.id === defaultCard} />
                      }
                      onChange={(e) => changeDefaultCard(e, card.id)}
                      label=""
                    />
                  </Tooltip>

                  <Tooltip title="Удалить">
                    <IconButton
                      sx={{
                        top: 40,
                        right: 8,
                        position: 'absolute',
                      }}
                      onClick={() => setShowCardRmvModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 1024 1024"
                      >
                        <path
                          fill="pink"
                          d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z"
                        />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Paper>
              <CustomModal
                isOpen={showCardRmvModal}
                onClose={() => setShowCardRmvModal(false)}
                title="Удалить карту?"
                subTitle="Пожалуйста, подтвердите удаление карты"
                closeBtnTitle="Отменить"
                submitBtnTitle="Подтвердить"
                onSubmit={() => deleteCard(card.id)}
              />
            </div>
          ))}
        </Stack>
      ) : (
        <Typography sx={{ color: '#808080' }}>
          Нет привязанных карт...
        </Typography>
      )}

      <Box sx={{ mt: 3 }}>
        <Button
          size="small"
          startIcon={<Icon icon={plusFill} />}
          onClick={addCard}
          sx={{ textTransform: 'none' }}
        >
          Добавить карту
        </Button>
      </Box>
    </Card>
  );
}
