import { createSlice } from '@reduxjs/toolkit';
import { API } from 'src/apis';
import axiosInstance from 'src/utils/axios';

const initialState = {
  error: false,
  isLoading: false,
  products: [],
  next: null,
  previous: null,
  totalCount: 0,
};

const catalogMyProductsSlice = createSlice({
  name: 'catalog_my_products',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.error = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload.data;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.totalCount = action.payload.total_count;
    },
    addProduct(state, action) {
      state.products.push(action.payload);
    },
    updateProduct(state, action) {
      const { id, updatedProduct } = action.payload;
      const index = state.products.findIndex((product) => product.id === id);
      if (index !== -1) {
        state.products[index] = updatedProduct;
      }
    },
    removeProduct(state, action) {
      const id = action.payload;
      state.products = state.products.filter((product) => product.id !== id);
    },
  },
});

export const { setProducts, addProduct, updateProduct, removeProduct } =
  catalogMyProductsSlice.actions;
export default catalogMyProductsSlice.reducer;

export function getMyProducts(filters) {
  return async (dispatch) => {
    dispatch(catalogMyProductsSlice.actions.startLoading());
    try {
      const response = await axiosInstance.get(API.products, {
        params: filters,
      });
      dispatch(catalogMyProductsSlice.actions.setProducts(response.data));
    } catch (error) {
      console.error(error);
      dispatch(catalogMyProductsSlice.actions.hasError(error));
    }
    dispatch(catalogMyProductsSlice.actions.endLoading());
  };
}
