export const TIME_LIST_VALUES = {
  week: 'week',
  lastWeek: 'last_week',
  valentineWeek: 'valentine_week',
  currentMonth: 'current_month',
  month: '1',
  lastMonth: 'last_month',
  quarter: '3',
  monthsOfYear: '6',
  year: '12',
};

export const TIME_LIST_LABELS = {
  week: '7 дней',
  lastWeek: 'Прошлая неделя',
  valentineWeek: '14 дней',
  currentMonth: 'Текущий месяц',
  month: '30 дней',
  lastMonth: 'Прошлый месяц',
  quarter: '3 месяца',
  monthsOfYear: '6 месяцев',
  year: '12 месяцев',
};

export const timeList = [
  { id: 1, name: TIME_LIST_LABELS.month, value: TIME_LIST_VALUES.month },
  { id: 2, name: TIME_LIST_LABELS.quarter, value: TIME_LIST_VALUES.quarter },
  {
    id: 3,
    name: TIME_LIST_LABELS.monthsOfYear,
    value: TIME_LIST_VALUES.monthsOfYear,
  },
  { id: 4, name: TIME_LIST_LABELS.year, value: TIME_LIST_VALUES.year },
];

export const getValueTimeList = (value) => {
  const findValue = timeList.find((time) => time?.value === value);

  return findValue || {};
};
