import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.data;
    },
    updateCategoriesSuccess(state, action) {
      const updatedCategory = action.payload;
      state.isLoading = false;
      const index = state.categories.findIndex(
        (category) => category.id === updatedCategory.id,
      );
      if (index !== -1) {
        state.categories[index] = updatedCategory;
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  getCategoriesSuccess,
  updateCategoriesSuccess,
} = slice.actions;

export const getCategories = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosInstance.get(API.catalog_categories);
    dispatch(getCategoriesSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export default slice.reducer;
