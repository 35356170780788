import axios from '../../utils/axios';
import { API } from '../../apis';
import toast from '../../utils/snackBarUtils';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  descriptionGeneratorKeyWords: [],
  values: null,
};

const descriptionGeneratorKeyWordsSlice = createSlice({
  name: 'descriptionGeneratorKeyWords',
  initialState,
  reducers: {
    switchDescriptionGeneratorKeyWords: (state, action) => {
      const value = action.payload;
      state.descriptionGeneratorKeyWords = value;
      state.values = null;
    },
    changeValues: (state, action) => {
      const value = action.payload;

      state.values = value;
    },
  },
  extraReducers: (builder) => {},
});

export const actionDescriptionGeneratorKeyWords =
  descriptionGeneratorKeyWordsSlice.actions;
export default descriptionGeneratorKeyWordsSlice.reducer;

export const postSemanticCore = async (data) => {
  try {
    const response = await axios.post(API.semantic_core, data);
    return response.data;
  } catch (error) {
    if (Array.isArray(error) && error.length > 0) {
      toast.error(error.join('\n'));
    } else toast.error(error.detail);
    console.error(
      'An error occurred while posting to the Semantic Core:',
      error,
    );
  }
};
