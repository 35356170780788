import _ from 'lodash';

export const searchCategoriesTree = (items, searchTerm) => {
  return items.reduce((acc, item) => {
    // Check if the parent element matches the search term
    if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      const childMatches = searchCategoriesTree(item.children, searchTerm);
      let element = {
        ...item,
        children: childMatches,
      };
      acc.push(element);
    }

    // Check if any child element matches the search term
    if (item.children && item.children.length > 0) {
      const childMatches = searchCategoriesTree(item.children, searchTerm);
      if (childMatches.length > 0) {
        acc.push({ ...item, children: childMatches });
      }
    }

    const uniqueResults = _.uniqBy(acc, 'id');
    return uniqueResults;
  }, []);
};

export function applyFilter(array, query) {
  if (query) {
    return _.filter(array, (item) => {
      // Remove internal spaces from the item's name and query
      const itemWithoutSpaces = String(item?.title || '').replace(/\s+/g, '');
      const queryWithoutSpaces = String(query).replace(/\s+/g, '');

      // Convert both the item and query to lowercase for case-insensitive search
      const itemLowerCase = itemWithoutSpaces.toLowerCase();
      const queryLowerCase = queryWithoutSpaces.toLowerCase();

      // Check if the item includes the query (case-insensitive)
      return itemLowerCase.includes(queryLowerCase);
    });
  }

  return array;
}
