export const WAREHOUSES = [
  ' Радумля 1 WB ',
  ' Радумля КБТ WB ',
  'Алексин WB ',
  'Алматы Атакент WB ',
  'Астана WB ',
  'Барнаул WB ',
  'Белая Дача WB ',
  'Белые Столбы WB ',
  'Внуково WB ',
  'Вёшки WB ',
  'Домодедово WB ',
  'Домодедово WB ',
  'Екатеринбург 2 WB ',
  'Екатеринбург WB ',
  'Иваново WB ',
  'Казань WB ',
  'Калуга WB ',
  'Киров WB ',
  'Коледино WB ',
  'Краснодар WB ',
  'Минск WB ',
  'Невинномысск WB ',
  'Новокузнецк WB ',
  'Новосибирск WB ',
  'Обухово WB ',
  'Подольск 3 WB ',
  'Подольск 4 WB ',
  'Подольск WB ',
  'Пушкино WB ',
  'Санкт-Петербург WB ',
  'Склад продавца Москва WBGo ',
  'Склад продавца Москва WBGo 10 ',
  'Склад продавца Москва WBGo 11 ',
  'Склад продавца Москва WBGo 12 ',
  'Склад продавца Москва WBGo 13 ',
  'Склад продавца Москва WBGo 14 ',
  'Склад продавца Москва WBGo 15 ',
  'Склад продавца Москва WBGo 16 ',
  'Склад продавца Москва WBGo 17 ',
  'Склад продавца Москва WBGo 18 ',
  'Склад продавца Москва WBGo 19 ',
  'Склад продавца Москва WBGo 2 ',
  'Склад продавца Москва WBGo 20 ',
  'Склад продавца Москва WBGo 21 ',
  'Склад продавца Москва WBGo 22 ',
  'Склад продавца Москва WBGo 23 ',
  'Склад продавца Москва WBGo 24 ',
  'Склад продавца Москва WBGo 25 ',
  'Склад продавца Москва WBGo 26 ',
  'Склад продавца Москва WBGo 27 ',
];

export const CATEGORIES = [
  'Автотовары',
  'Автотовары -> Запчасти на легковые автомобили',
  'Запчасти на легковые автомобили -> Двигатель',
  'Запчасти на легковые автомобили -> Топливная система',
  'Запчасти на легковые автомобили -> Система охлаждения',
  'Запчасти на легковые автомобили -> Трансмиссия',
  'Запчасти на легковые автомобили -> Система выпуска',
  'Запчасти на легковые автомобили -> Ходовая часть',
  'Запчасти на легковые автомобили -> Тормозная система',
  'Запчасти на легковые автомобили -> Рулевое управление',
  'Запчасти на легковые автомобили -> Электрооборудование',
  'Запчасти на легковые автомобили -> Отопление и кондиционирование',
  'Запчасти на легковые автомобили -> Детали салона',
  'Запчасти на легковые автомобили -> Кузов',
  'Запчасти на легковые автомобили -> Автозапчасти',
  'Автозапчасти -> Lada/ВАЗ',
  'Автозапчасти -> Hyundai/Kia',
  'Автозапчасти -> Renault',
  'Автозапчасти -> VAG',
  'Автозапчасти -> Toyota',
  'Автозапчасти -> Nissan',
  'Автозапчасти -> ГАЗ',
  'Автозапчасти -> BMW',
  'Автозапчасти -> Mercedes-Benz',
  'Автозапчасти -> УАЗ',
  'Автозапчасти -> Mitsubishi',
  'Автозапчасти -> Mazda',
  'Автозапчасти -> Ford',
  'Автозапчасти -> Lexus',
  'Автозапчасти -> Volvo',
  'Автозапчасти -> Haval',
  'Автозапчасти -> Geely',
  'Автозапчасти -> Land Rover',
  'Автозапчасти -> Chery',
  'Автозапчасти -> Suzuki',
  'Автозапчасти -> Datsun',
  'Автозапчасти -> Прочие марки',
  'Автотовары -> Масла и жидкости',
  'Масла и жидкости -> Моторное масло',
  'Масла и жидкости -> Трансмиссионное масло',
  'Масла и жидкости -> Жидкости эксплуатационные',
  'Масла и жидкости -> Гидравлические жидкости и масла',
  'Масла и жидкости -> Промывочные масла',
  'Масла и жидкости -> Тормозные жидкости',
  'Автотовары -> Автокосметика и автохимия',
  'Автокосметика и автохимия -> Герметики автомобильные',
  'Автокосметика и автохимия -> Присадки',
  'Автокосметика и автохимия -> Смазки автомобильные',
  'Автокосметика и автохимия -> Уход за стеклами и фарами',
  'Автокосметика и автохимия -> Уход за салоном',
  'Автокосметика и автохимия -> Уход за кузовом',
  'Автокосметика и автохимия -> Уход за шинами и дисками',
  'Автокосметика и автохимия -> Очистители автомобильные',
  'Автокосметика и автохимия -> Прочее: автохимия',
  'Автотовары -> Краски и грунтовки',
  'Краски и грунтовки -> Грунтовки',
  'Краски и грунтовки -> Краски',
  'Краски и грунтовки -> Краски аэрозольные',
  'Краски и грунтовки -> Эмали',
  'Автотовары -> Автоэлектроника и навигация',
  'Автотовары -> Аксессуары в салон и багажник',
  'Аксессуары в салон и багажник -> Аварийные принадлежности',
  'Аксессуары в салон и багажник -> Автокресла и аксессуары',
  'Аксессуары в салон и багажник -> Автомобильные компрессоры',
  'Аксессуары в салон и багажник -> Аксессуары для водителя',
  'Аксессуары в салон и багажник -> Ароматизаторы',
  'Аксессуары в салон и багажник -> Держатели',
  'Аксессуары в салон и багажник -> Домкраты',
  'Аксессуары в салон и багажник -> Изоляционные материалы',
  'Аксессуары в салон и багажник -> Канистры',
  'Аксессуары в салон и багажник -> Наборы автомобилиста',
  'Аксессуары в салон и багажник -> Органайзеры и сумки',
  'Аксессуары в салон и багажник -> Термокружки и подстаканники',
  'Аксессуары в салон и багажник -> Тюнинг и внутренний декор',
  'Аксессуары в салон и багажник -> Уход за автомобилем',
  'Аксессуары в салон и багажник -> Чехлы и накидки',
  'Автотовары -> Коврики',
  'Автотовары -> Внешний тюнинг',
  'Автотовары -> Другие аксессуары и доп. оборудование',
  'Другие аксессуары и доп. оборудование -> Защита автомобиля',
  'Другие аксессуары и доп. оборудование -> Перевозка груза',
  'Другие аксессуары и доп. оборудование -> Электроника',
  'Другие аксессуары и доп. оборудование -> Охранные системы',
  'Другие аксессуары и доп. оборудование -> Другие автоаксессуары',
  'Автотовары -> Шины и диски колесные',
  'Шины и диски колесные -> Шины',
  'Шины и диски колесные -> Диски',
  'Автотовары -> Инструменты',
  'Инструменты -> Автосервисное оборудование',
  'Инструменты -> Диагностический инструмент',
  'Инструменты -> Другие автоинструменты',
  'Инструменты -> Ключи',
  'Инструменты -> Крепежный инструмент',
  'Инструменты -> Наборы инструментов',
  'Инструменты -> Слесарный инструмент',
  'Инструменты -> Съемники',
  'Инструменты -> Тестеры',
  'Автотовары -> Мойки высокого давления и аксессуары',
  'Автотовары -> Мототовары',
  'Мототовары -> Ходовая часть',
];

export const getRandomCategories = () => {
  const randomValueIndex =
    Math.round(Math.random() * CATEGORIES.length - 1) || 0;
  const houseIndex = randomValueIndex > 0 ? randomValueIndex : 0;
  const warehouse = CATEGORIES[houseIndex];
  return warehouse;
};

export const getRandomWarehouses = () => {
  const randomValueIndex =
    Math.round(Math.random() * WAREHOUSES.length - 1) || 0;
  const houseIndex = randomValueIndex > 0 ? randomValueIndex : 0;
  const warehouse = WAREHOUSES[houseIndex];
  return warehouse;
};

export function generateMonthArray() {
  const dateArray = [];

  const currentDate = new Date();

  for (let i = 0; i < 30; i++) {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() - i);
    dateArray.push(nextDate);
  }

  return dateArray;
}

export function generateWeekArray(startDate) {
  const weekArray = [];
  const currentDate = new Date(startDate || null);

  // Loop to generate dates for a week (7 days)
  for (let i = 0; i < 7; i++) {
    weekArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1); // Increment the date by 1 day
  }

  return weekArray;
}

export const generateLastWeekArray = () => {
  const today = new Date();
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const datesArray = [];
  for (let date = lastWeek; date <= today; date.setDate(date.getDate() + 1)) {
    datesArray.push(new Date(date));
  }

  return datesArray;
};

export function generate2WeekArray() {
  const today = new Date(); // Get the current date
  const twoWeeksLater = new Date();
  twoWeeksLater.setDate(today.getDate() + 14); // Add 14 days to the current date

  const datesArray = [];
  let currentDate = new Date(today);

  while (currentDate <= twoWeeksLater) {
    datesArray.push(new Date(currentDate)); // Add a copy of the current date to the array
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return datesArray;
}

export const generateLastMonthArray = () => {
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const daysInLastMonth = new Date(
    lastMonth.getFullYear(),
    lastMonth.getMonth() + 1,
    0,
  ).getDate();

  const lastMonthArray = [];

  for (let i = 1; i <= daysInLastMonth; i++) {
    lastMonthArray.push(
      new Date(lastMonth.getFullYear(), lastMonth.getMonth(), i),
    );
  }

  return lastMonthArray;
};

export const generate3MonthArray = () => {
  const currentDate = new Date();
  const monthsArray = [];

  for (let i = 0; i < 3; i++) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1.
    monthsArray.push(`${year}-${month.toString().padStart(2, '0')}`);

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return monthsArray;
};

export function generate6MonthArray() {
  const currentDate = new Date();
  const months = [];

  for (let i = 0; i < 6; i++) {
    const month = currentDate.getMonth(); // Get the current month (0-11)
    const year = currentDate.getFullYear(); // Get the current year
    months.push(`${year}-${(month + 1).toString().padStart(2, '0')}`);

    // Move to the next month
    currentDate.setMonth(month + 1);
  }

  return months;
}

export const generate12MonthArray = () => {
  const today = new Date();
  const months = Array.from({ length: 12 }, (_, index) => {
    const date = new Date(today);
    date.setMonth(today.getMonth() + index);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  });

  return months;
};

const KEYWORDS_WB = [
  '﻿платье женское',
  'кроссовки женские',
  'сумка женская',
  'джинсы женские',
  'пижама женская',
  'футболка женская',
  'футболка мужская',
  'чехол на iphone 11',
  'подарок на 8 марта',
  'кроссовки мужские',
  'трусы женские',
  'куртка женская весна',
  'платье',
  'befree',
  'zarina',
  'твое',
  'топ женский',
  'джинсы',
  'рубашка женская',
  'gloria jeans',
  'костюм женский',
  'кроссовки',
  'брюки женские',
  'топ',
  'спортивный костюм женский',
  'лонгслив женский',
  'картина по номерам',
  'косметика',
  'zara',
  'куртка женская',
  'футболка',
  'блузка женская',
  'наушники беспроводные',
  'кофта женская',
  'юбка женская',
  'постельное белье',
  'adidas',
  'lit energy',
  'туфли женские',
  'love republic',
  'сумка',
  'трусы мужские',
  'пальто женское',
  'сумка женская через плечо',
  'серьги',
  'подарок на 23 февраля',
  'худи',
  'носки мужские',
  'носки женские',
  'лонгслив',
  'nike',
  'подарки на 8 марта',
  'пиджак женский',
  'боди женское',
  'футболка оверсайз',
  'купальник женский',
  'пальто женское весна',
  'bershka',
  'купальник',
  'чехол на iphone 13',
  'подарок мужчине',
  'платье вечернее',
  '23 февраля',
  'платье для девочки',
  'шорты женские',
  'свитер женский',
  'халат женский',
  'рюкзак женский',
  'юбка',
  'тушь для ресниц',
  'ботинки женские весна',
  'лоферы женские',
  'блеск для губ',
  'косметичка',
  'xiaomi',
  'наушники',
  'джинсы мужские',
  'постельное белье 2 спальное',
  'консилер',
  'пижама',
  'телефон',
  'ботинки женские',
  'гель для душа',
  'носки',
  'бальзам для губ',
  'mango',
  'алмазная мозаика',
  'крем для рук',
  'шампунь для волос',
  'свитшот',
  'кошелек женский',
  'подарки на 23 февраля',
  'кардиган женский',
  'спортивный костюм мужской',
  'рюкзак',
  'свитшот женский',
  'босоножки женские',
  'боди',
  '8 марта',
  'подарочный набор',
];

export const getRandomKeywords = () => {
  const randomValueIndex =
    Math.round(Math.random() * KEYWORDS_WB.length - 1) || 0;
  const houseIndex = randomValueIndex > 0 ? randomValueIndex : 0;
  const warehouse = KEYWORDS_WB[houseIndex];
  return warehouse;
};
