import {
  Autocomplete,
  Card,
  CardContent,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CustomInput from '../../../UI/CustomInput';
import { Icon } from '@iconify/react';
import DatePicker from '@mui/lab/DatePicker';

export const SELECT_VALUES = {
  larger: 'larger',
  fewer: 'under',
};

const HeaderDropDown = ({
  greaterThan,
  title,
  setGreaterThan,
  handleChange,
  value,
  filter,
  date,
  onSelectChange,
  selectedValue,
  dates,
  handleChangeDate,
  dateOption,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = Boolean(anchorEl) ? 'simple-popover' : undefined;

  return (
    <Stack direction="row">
      {date ? (
        <Autocomplete
          options={dateOption || []}
          getOptionLabel={(option) => `${option}`}
          ListboxProps={{ style: { fontSize: '12px' } }}
          clearOnEscape={false}
          fullWidth
          size="small"
          sx={{
            '& .MuiInputBase-sizeSmall': {
              p: '0.2rem !important',
            },
          }}
          renderInput={(params) => <CustomInput type="text" {...params} />}
          onChange={(_, value) => onSelectChange(`${value}`)}
          inputValue={selectedValue}
          onInputChange={(_, newInputValue, reason) => {
            if (reason !== 'reset') {
              onSelectChange(newInputValue);
            }
          }}
          clearOnBlur={false}
          autoHighlight
          noOptionsText=""
          onKeyPress={(keyBoard) =>
            keyBoard.key === 'Enter' && setAnchorEl(false)
          }
        />
      ) : (
        <CustomInput onChange={handleChange} value={value} />
      )}

      {filter && (
        <IconButton
          size="small"
          aria-describedby={id}
          onClick={handleClick}
          sx={{ position: 'relative' }}
        >
          <Icon height={15} width={15} icon="heroicons-outline:filter" />
        </IconButton>
      )}

      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card>
          <CardContent
            sx={{
              p: 1,
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <Icon
              height={15}
              width={15}
              icon="heroicons-outline:filter"
              color="#7635DC"
            />
            <Typography>{title}</Typography>
          </CardContent>

          <Divider />

          <CardContent sx={{ p: 1, px: 0.5, pb: 1 }}>
            {date ? (
              <Stack spacing={{ xs: 2, sm: 3 }} direction="column">
                <DatePicker
                  value={dates?.start}
                  onChange={(e) => handleChangeDate(e, 'start')}
                  label="Start date"
                  name="start"
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      name="start"
                      fullWidth
                      {...params}
                    />
                  )}
                  inputFormat="dd/MM/yyyy"
                />
                <DatePicker
                  value={dates?.end}
                  onChange={(e) => handleChangeDate(e, 'end')}
                  label="End date"
                  name="end"
                  renderInput={(params) => (
                    <TextField size="small" name="end" fullWidth {...params} />
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </Stack>
            ) : (
              <Select
                fullWidth
                sx={{ width: '15rem' }}
                value={greaterThan}
                onChange={(e) => setGreaterThan(e.target.value)}
                size="small"
              >
                <MenuItem sx={{ width: '100%' }} value={SELECT_VALUES.larger}>
                  Больше
                </MenuItem>
                <MenuItem sx={{ width: '100%' }} value={SELECT_VALUES.fewer}>
                  Меньше
                </MenuItem>
              </Select>
            )}
          </CardContent>
        </Card>
      </Popover>
    </Stack>
  );
};

export default HeaderDropDown;
