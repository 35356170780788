import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Alert, Box, Card, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import toast from 'src/utils/snackBarUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  authInitializeThunk,
  authReduxSelector,
  authUpdateStoreThunk,
} from 'src/redux/slices/authentication';
import { getBrands, getPaymentStatus } from 'src/redux/slices/user';
import MyInputStyle from '../../MyInputStyle';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import SetDataTestId from 'src/components/SetDataTestId';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

// ----------------------------------------------------------------------

const asteriskStringRegex = /^\*+$/;

export default function AccountStore() {
  const isMountedRef = useIsMountedRef();
  const { store } = useSelector((state) => authReduxSelector(state));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UpdateStoreSchema = Yup.object().shape({
    name: Yup.string().required('Это поле обязательное'),
    token_x64: Yup.string().required('Это поле обязательное'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: store.name,
      token_x64: '****************',
      wb_adv_api_token: '',
    },

    validationSchema: UpdateStoreSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await dispatch(authUpdateStoreThunk(values))?.unwrap();
        toast.success('Данные успешно сохранены');
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        formik.resetForm();
        dispatch(authInitializeThunk());
        dispatch(getPaymentStatus());
        dispatch(getBrands());
        navigate(PATH_DASHBOARD.general.shop);
      } catch (error) {
        console.log(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.name || error.token_x64 });
          setSubmitting(false);
        } else if ('token_x64' in error) {
          setErrors({
            token_x64: error.token_x64.join(''),
          });
        }
      }
    },
  });

  const {
    errors,
    isSubmitting,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    dirty,
    isValid,
  } = formik;

  const disabled =
    asteriskStringRegex.test(values.token_x64) || !dirty || !isValid;

  const handleChangeToken = ({ target, nativeEvent }) => {
    const newValue = target.value;

    const isBackspace = nativeEvent.inputType === 'deleteContentBackward';

    if (isBackspace && newValue.length === 0) {
      setFieldValue('token_x64', '', false);
      return;
    }

    if (newValue.length === 0) {
      setFieldValue('token_x64', '', false);
      return;
    }

    const tokenWithoutAsterisks = values.token_x64.replace(/\*/g, '');
    const slicedToken = tokenWithoutAsterisks.slice(0, newValue.length - 1);
    const slicedNewValue = newValue.slice(
      slicedToken.length,
      slicedToken.length + 1,
    );

    let customValue;

    if (tokenWithoutAsterisks.length - newValue.length > 1) {
      customValue = generateCustomValue(slicedToken, slicedNewValue);
    } else if (newValue.length !== tokenWithoutAsterisks.length - 1) {
      customValue = generateCustomValue(tokenWithoutAsterisks, newValue, true);
    } else {
      customValue = tokenWithoutAsterisks.slice(0, newValue.length);
    }

    setFieldValue(
      'token_x64',
      customValue,
      !asteriskStringRegex.test(customValue),
    );
  };

  const generateCustomValue = (
    baseValue,
    additionalValue,
    replaceDots = false,
  ) => {
    const modifiedAdditionalValue = replaceDots
      ? additionalValue.replace(/•/g, '')
      : additionalValue;
    return baseValue + modifiedAdditionalValue;
  };

  const token_x64_error = Boolean(touched.token_x64 && errors.token_x64);

  const { api_key } = DATA_IDS.settings;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <MyInputStyle
                    fullWidth
                    label="Имя продавца"
                    onClick={() =>
                      !touched.name ? setFieldValue('name', '') : ''
                    }
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...setDataTestId({
                      name: api_key.seller_name,
                      inputProps: {},
                    })}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <MyInputStyle
                    fullWidth
                    label="Токен x64"
                    value={'•'.repeat(values?.token_x64?.length)}
                    onChange={handleChangeToken}
                    name="token_x64"
                    onClick={() => disabled && setFieldValue('token_x64', '')}
                    error={token_x64_error}
                    inputProps={{
                      style: { ...(token_x64_error && { color: 'red' }) },
                      'data-testid': 'token_x64',
                    }}
                    helperText={touched.token_x64 && errors.token_x64}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <MyInputStyle
                    fullWidth
                    label="Токен рекламы"
                    value={'•'.repeat(values?.wb_adv_api_token?.length)}
                    onChange={(e) =>
                      setFieldValue('wb_adv_api_token', e.target.value)
                    }
                    name="wb_adv_api_token"
                    onClick={() =>
                      disabled && setFieldValue('wb_adv_api_token', '')
                    }
                    inputProps={{
                      'data-testid': 'wb_adv_api_token',
                    }}
                    helperText={
                      touched.wb_adv_api_token && errors.wb_adv_api_token
                    }
                  />
                </Stack>

                {errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={disabled || !values.token_x64}
                  loading={isSubmitting || store.is_pulling_data}
                  {...setDataTestId({ name: api_key.save_button })}
                >
                  Сохранить
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
