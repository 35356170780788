const PAGES_ACCESS = {
  internal_analytics: 'internal_analytics',
  sandbox: 'sandbox',
  specialists: 'specialists',
  sales_report: 'sales_report',
  video_teaching: 'video_teaching',
  video_instructor: 'video_instructor',
  seo_optimization: 'seo_optimization',
  category_analytics: 'category_analytics',
  featured_categories: 'featured_categories',
  unit_economy: 'unit_economy',
  generate_barcode: 'generate_barcode',
  semantic_core: 'semantic_core',
  description_generator: 'description_generate',
  auto_dumping: 'auto_dumping',
  profit_calculator: 'profit_calculator',
  financial_report: 'financial_report',
  media_portal: 'media_portal',
  analytics: 'analytics',
  user_cards: 'user_cards',
  forum: 'forum',
  shop: 'shop',
  catalog: 'catalog',
};

export default PAGES_ACCESS;
