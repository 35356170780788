import { TextField, styled } from '@mui/material';
import React from 'react';

const StyledTextfield = styled(TextField)(() => ({
  '& .MuiOutlinedInput-input': {
    padding: '0.25rem',
    fontSize: '12px',
  },
}));

const CustomInput = ({ ...props }) => {
  return <StyledTextfield size="small" fullWidth type="search" {...props} />;
};

export default CustomInput;
