import { Tooltip, styled, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '4px 8px',
    background: '#fff',
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    boxShadow: '0px 5px 20px 1px rgba(0, 0, 0, 0.08)',
    margin: '0',
  },
  [`& .MuiTooltip-arrow`]: {},
}));

export default CustomTooltip;
