import { saveAs } from 'file-saver';
import { PATH_DASHBOARD } from '../routes/paths';

export const isDevicePWA = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone ||
  document.referrer.includes('android-app://');

export const detectDeviceType = (device) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    device || navigator.userAgent,
  )
    ? 'Mobile'
    : 'Desktop';

export const downloadTable = (
  tableHeaders,
  tableRows,
  docName,
  docFormat,
  blobType = 'msword',
) => {
  const table = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            ${tableHeaders
              .map(
                (header) =>
                  `<th style="border: 1px solid #000; padding: 8px;">${header.label}</th>`,
              )
              .join('')}
          </tr>
        </thead>
        <tbody>
          ${tableRows
            .map(
              (row) => `
            <tr>
              ${Object.values(row)
                .map(
                  (cell) =>
                    `<td style="border: 1px solid #000; padding: 8px;">${cell}</td>`,
                )
                .join('')}
            </tr>
          `,
            )
            .join('')}
        </tbody>
      </table>
    `;

  const blob = new Blob([`<html><body>${table}</body></html>`], {
    type: `application/${blobType}`,
  });
  saveAs(blob, `${docName}.${docFormat}`);
};

export const extractNumbersFromUrl = (url) => {
  const matches = url.match(/\d+/g);
  if (matches) {
    return matches.map((match) => match);
  } else {
    return [];
  }
};

export const extractNumbersFromUrls = (urls) => {
  const nonEmptyUrls = urls.filter((url) => url.trim() !== '');
  const numbers = [];
  const numberPattern = /\d+/;

  nonEmptyUrls.forEach((url) => {
    const matches = url.match(numberPattern);
    if (matches) {
      numbers.push(...matches.map(Number));
    }
  });

  return numbers;
};

export const supportedCountries = [
  {
    country: {
      code: 'KG',
      name: 'Kyrgyzstan',
      calling_code: 996,
      phonenumber_mask: ['3-3-3'],
    },
  },
  {
    country: {
      code: 'KZ',
      name: 'Kazakhstan',
      calling_code: 7,
      phonenumber_mask: ['3-3-4'],
    },
  },
  {
    country: {
      code: 'RU',
      name: 'Russia',
      calling_code: 7,
      phonenumber_mask: ['3-3-2-2'],
    },
  },
];

export const openWBProductDetails = (id) => {
  window.open(`https://www.wildberries.ru/catalog/${id}/detail.aspx`, '_blank');
};

export const openProductDetails = (id) => {
  window.open(PATH_DASHBOARD.general.analytics + `/${id}`, '_blank');
};
