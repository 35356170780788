import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography, Link, styled } from '@mui/material';
import { MBreadcrumbs } from './@material-extend';
import { Icon } from '@iconify/react';
import InfoCard from 'src/infoCard';

HeaderBreadcrumbs.propTypes = {
  onClickTitle: PropTypes?.any,
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  titleColor: PropTypes.string,
  sx: PropTypes.object,
};

const StyledHeadline = styled(Typography)(
  ({ isCursorPointer, titleColor }) => ({
    cursor: isCursorPointer ? 'pointer' : 'auto',
    color: titleColor,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  }),
);

export default function HeaderBreadcrumbs({
  onClickTitle,
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  titleColor = '#000000',
  isCursorPointer = false,
  crumbsColor,
  cardKey,
  title,
  ...other
}) {
  return (
    <Box sx={{ mb: 2.5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <InfoCard cardKey={cardKey} title={title}>
            <StyledHeadline
              variant="h4"
              gutterBottom
              onClick={onClickTitle}
              isCursorPointer={isCursorPointer}
              titleColor={titleColor}
            >
              {heading}
              {isCursorPointer && <Icon icon="ci:external-link" />}
            </StyledHeadline>
          </InfoCard>
          <MBreadcrumbs crumbsColor={crumbsColor} links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
