import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Alert, Box, Card, Grid, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import toast from 'src/utils/snackBarUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  authReduxSelector,
  authUpdateProfileThunk,
} from 'src/redux/slices/authentication';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

export default function AccountGeneral() {
  const isMountedRef = useIsMountedRef();
  const { user } = useSelector((state) => authReduxSelector(state));

  const dispatch = useDispatch();

  const UpdateUserSchema = Yup.object().shape({
    first_name: Yup.string().required('Это поле обязательное'),
    last_name: Yup.string().required('Это поле обязательное'),
    phone: Yup.string().required('Это поле обязательное'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user.first_name || '',
      email: user.email,
      phone: user.phone,
      last_name: user.last_name,
      organization_name: user.organization_name,
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await dispatch(authUpdateProfileThunk(values)).unwrap();
        toast.success('Данные успешно сохранены');
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current || 'phone' in error) {
          setErrors({ afterSubmit: error.phone[0] });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    errors,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    dirty,
    isValid,
  } = formik;

  const { profile } = DATA_IDS.settings;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                ></Stack>

                <Stack direction={{ xs: 'column' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Имя"
                    {...getFieldProps('first_name')}
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name}
                    {...setDataTestId({ name: profile.name, inputProps: {} })}
                  />
                  <TextField
                    fullWidth
                    label="Фамилия"
                    {...getFieldProps('last_name')}
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name}
                    {...setDataTestId({
                      name: profile.last_name,
                      inputProps: {},
                    })}
                  />

                  <MuiPhoneNumber
                    fullWidth
                    defaultCountry="kg"
                    onlyCountries={['ru', 'kz', 'kg']}
                    localization={{
                      Russia: 'Россия',
                      Kazakhstan: 'Казахстан',
                      Kyrgyzstan: 'Кыргызстан',
                    }}
                    {...getFieldProps('phone')}
                    required
                    onChange={(value) =>
                      setFieldValue('phone', value?.replace(/[^\d+]/g, ''))
                    }
                    variant="outlined"
                    label="Телефон"
                    disableAreaCodes
                    masks={{ kg: '+... (...) ... ...' }}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone}
                    {...setDataTestId({
                      name: profile.phone_number,
                      inputProps: {},
                    })}
                  />
                  <TextField
                    fullWidth
                    disabled
                    label="Почта"
                    {...getFieldProps('email')}
                  />
                  <TextField
                    fullWidth
                    label="Название организации"
                    {...getFieldProps('organization_name')}
                  />
                </Stack>
                {errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={!dirty || !isValid}
                  loading={isSubmitting}
                  {...setDataTestId({ name: profile.save_button })}
                >
                  Сохранить
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
