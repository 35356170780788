import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { API } from 'src/apis';
import axiosInstance from 'src/utils/axios';
import toast from 'src/utils/snackBarUtils';

const VALUES = {
  all: 'Все',
};

const name = 'video-teaching';

export const getVideosThunk = createAsyncThunk(
  `${name}/getVideosThunk`,
  async (type = 'video_instruction', { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `${API.video_teaching}modules?type=${type}`,
      );

      return data;
    } catch (e) {
      console.error(e);
      toast.error(`${e?.data || 'Не удалось получить данные'}`);
      return rejectWithValue(e);
    }
  },
);

const filterByKey = (key, value) => (array) => {
  return array.filter((item) => {
    if (value !== VALUES.all) {
      return item[key] === value;
    }
    return item;
  });
};

const filter = (filters, array) => {
  const builder = _.flow(
    _.map(filters, (value, key) => {
      return filterByKey(key, value);
    }),
  );

  return builder(array);
};

const initialState = {
  videos: [],
  duble: [],

  loading: false,

  authorOptions: [],
  topicOptions: [],

  filter: {
    author: VALUES.all,
    topic: VALUES.all,
  },
};

const handleFilterChange = (state, actions, filterKey) => {
  const value = actions.payload || VALUES.all;
  const filterValue = { ...state.filter, [filterKey]: value };
  state.filter = filterValue;
  state.videos = filter(filterValue, state.duble);
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    changeAuthorValue: (state, actions) => {
      handleFilterChange(state, actions, 'author');
    },
    changeTopicValue: (state, actions) => {
      handleFilterChange(state, actions, 'topic');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideosThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideosThunk.rejected, (state, actions) => {
        state.loading = false;
      })
      .addCase(getVideosThunk.fulfilled, (state, actions) => {
        const videos = actions.payload;

        const authorOption = _.uniqBy(videos, 'author')
          .map((el) => el?.author)
          .filter((el) => el);

        const topicOption = _.uniqBy(videos, 'topic')
          .map((el) => el?.topic)
          .filter((el) => el);

        const newVideos = filter(state.filter, videos);
        state.videos = newVideos;
        state.duble = videos;
        state.authorOptions = [VALUES.all, ...authorOption];
        state.topicOptions = [VALUES.all, ...topicOption];
        state.loading = false;
      });
  },
});

export const actionsVideoTeaching = slice.actions;

export const videoTeachingSelector = (state) => {
  return state.video_teaching;
};

export default slice.reducer;
