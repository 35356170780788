// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import { useDispatch, useSelector } from 'react-redux';
import {
  authLoginThunk,
  authReduxSelector,
} from 'src/redux/slices/authentication';
import LoginWithGoogle from './LoginWithGoogle';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: '0 !important',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  width: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useSelector((state) => authReduxSelector(state));

  const dispatch = useDispatch();

  const handleLoginAuth0 = async () => {
    try {
      dispatch(authLoginThunk());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle
      title="Войти | isistant.io"
      childrenProps={{ sx: { display: 'flex', width: '100%' } }}
    >
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Добро пожаловать!
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Войти в iSistant
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Введите данные сюда.
            </Typography>
          </Box>
        </Stack>

        <LoginForm />

        {/*<Divider sx={{ py: 1.5 }}>или</Divider>*/}

        {/*<LoginWithGoogle />*/}
      </ContentStyle>
    </RootStyle>
  );
}
