import PaymentBlock from 'src/pages/PaymentBlock';
import { getPermission } from 'src/redux/selectors/allowedPages';
import { useSelector } from 'src/redux/store';

export default function PaymentGuard({ children, pageKey }) {
  const { paymentDaysRemain } = useSelector((state) => state.user);
  const allow = useSelector((state) => getPermission(state, pageKey));

  if (allow) {
    return <>{children}</>;
  } else {
    return <PaymentBlock expired={paymentDaysRemain <= 0} />;
  }
}
