import { fetchSheetsCommission } from './redux/slices/sheetsData';

export const API = {
  product_variant_list: `api/store/dashboard/product_variant_list/`,
  product_variant_count: '/api/store/dashboard/product_variant_count/',
  brand_statistics: 'api/store/dashboard/brand_detail/?brand_id=',
  calculator_templates: 'api/store/dashboard/calculator_templates/',
  sales_report: 'api/store/seller/sales_report/',
  brands: 'api/store/brands/',
  brand_list: 'api/store/dashboard/brand_list/',
  seller_list: 'api/store/dashboard/seller_list/',
  warehouse_list: 'api/store/dashboard/warehouse_list/',
  product_variants: 'api/store/dashboard/product_variants/',
  sales_tax: 'api/store/seller/sales_tax/',
  products: '/api/store/products/',

  analytics: 'api/store/analytics/',
  seller_detail: 'api/store/dashboard/seller_detail/',
  video_teaching: 'api/video_teaching/',
  //store
  store: 'api/store/main/',
  pull_data: 'api/store/main/pull_data/',
  task_status: 'api/store/main/task_status/',
  //category
  category_tree: 'api/store/dashboard/category_tree/',
  category_tree_path: 'api/store/dashboard/category_tree_path/',
  category_analytics: 'api/store/dashboard/category_analytics/',
  category_summary_favorite: 'api/store/dashboard/category_summary/favorite/',
  category_summary_item: (itemId) =>
    `api/store/dashboard/category_summary/${itemId}`,
  category_list: 'api/store/dashboard/category_list/',
  //forum
  forum: 'api/forum/',
  //promocode
  promocodes: 'api/promocodes/',
  //trending
  trending_search_compilation: 'api/trending_search_compilation/',
  trending_searches: 'api/trending_searches/',
  //billing
  billing: 'api/billing/',
  billing_plans: 'api/billing/plans/',
  billing_invoices: 'api/billing/invoices/',
  billing_history: 'api/billing/history/',
  billing_limit_history: 'api/billing/extra-funds-invoices/',
  billing_catalog_summary: 'api/billing/catalog-summary/',
  //specialists
  specialists: 'api/specialists/',
  //media portal
  media_portal: 'api/media_portal/',
  //account
  account: 'api/account/',
  user: 'api/account/user/',
  signin: 'api/account/signin/',
  singup: 'api/account/singup/',
  verify_email: 'api/account/verify-email/',
  device: '/api/account/devices/',
  //password
  reset: 'api/account/password/reset/',
  confirm: 'api/account/password/reset/confirm/',
  change: 'api/account/password/change/',
  // kaspi
  // dumping
  dumping: 'api/kaspi/dumping/',
  description_generator: 'api/tools/description_generator',
  semantic_core: 'api/semantic_core/',
  analytic_competitor: 'api/product_keywords_comparition/',
  portal_specialist: 'api/specialists/',
  portal_specialist_countries: 'api/specialists/countries/',
  portal_specialist_cities: 'api/specialists/cities/',
  portal_speciality_list: 'api/specialists/specialities/',
  specialist_posts: 'api/specialists/posts/',
  specialist_post_post: 'api/specialists/posts/',
  info_component: `api/help/info`,
  generate_barcode: 'api/tools/barcode',
  catalog_products: 'api/catalog/products/search/',
  catalog_product: (name) => `/api/catalog/products/${name}/`,
  catalog_products_reviews: (sku) => `/api/catalog/products/reviews/${sku}/`,
  catalog_product_reviews: `/api/catalog/products-reviews/`,
  catalog_categories: 'api/catalog/categories/',
  catalog_brands: 'api/catalog/brands/',
  catalog_favorites: 'api/catalog/product-favorites/',
  catalog_orders: 'api/catalog/orders/',
  sticker_upload: 'api/catalog/order-product-item-stickers/upload/',
  boxes_upload: 'api/catalog/orders/product-items/boxes/',
  catalog_products_sku_reviews: (sku) =>
    `/api/catalog/products/${sku}/reviews/stats/`,
  catalog_valid_products: 'api/catalog/products-details/',
  popup_balance: 'api/billing/popup-balance/',
  warehouses: 'api/catalog/warehouse-regions/',
  ads: 'api/adv/wb/',
  ads_pull_data: 'api/adv/wb/pull-data/',
  ad_details: (id) => `api/adv/wb/${id}/`,
  ad_supplier: 'api/adv/wb/supplier/subjects/',
  ad_cpm_table: (keyword, page) =>
    `api/adv/wb/cpm_table/?keyword=${keyword}&page=${page}`,
  commission: 'api/store/wb/comissions/',
  warehouse: 'api/store/wb/warehouse_coefficient/',
};
