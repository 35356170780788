import { Icon } from '@iconify/react';
import { InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const MyInputStyle = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'stretchStart',
})(({ stretchStart, theme, error = {}, name }) => {
  return {
    '& .MuiOutlinedInput-root': {
      transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
      }),
      '&.Mui-focused': {
        boxShadow: theme.customShadows.z12,
      },
      ...(stretchStart && {
        width: stretchStart,
        '&.Mui-focused': {
          boxShadow: theme.customShadows.z12,
          [theme.breakpoints.up('sm')]: {
            width: stretchStart + 60,
          },
        },
      }),
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },

    '& > .Mui-error fieldset': {
      borderColor: error[name] ? 'red !important' : '',
    },
    '& .Mui-error input::placeholder,textarea::placeholder': {
      color: error[name] ? 'red' : '',
    },
    '& p.MuiFormHelperText-root': {
      margin: '5px 0 0 0',
      fontSize: '14px',
    },
  };
});

export const CustomStyledInput = ({
  name,
  value,
  onChange,
  icon,
  placeholder,
  errors,
  type = 'number',
  ...props
}) => {
  return (
    <MyInputStyle
      {...props}
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      error={errors}
      type={type}
      size="small"
      placeholder={placeholder}
      InputProps={
        icon
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon
                    icon={icon}
                    sx={{
                      ml: 1,
                      width: 20,
                      height: 20,
                      color: 'text.disabled',
                    }}
                  />
                </InputAdornment>
              ),
            }
          : null
      }
    />
  );
};

CustomStyledInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default MyInputStyle;
