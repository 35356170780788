import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const columns = ['Промокод', 'Cрок действия'];

export default function PromoTable({ rows = [] }) {
  return (
    <Grid item container xs={12} alignItems={'center'}>
      <Typography variant="h5" my={2}>
        Активные промокоды:
      </Typography>
      {rows.length ? (
        <TableContainer size="small" aria-label="promo-code-table">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col}>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell sx={{ p: 1 }}>{row.name}</TableCell>
                  <TableCell sx={{ p: 1 }}>
                    {new Date(row.expiration_date).toLocaleDateString('ru-Ru', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" ml={1}>
          Нет активных промокодов
        </Typography>
      )}
    </Grid>
  );
}
