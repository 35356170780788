import { format, getTime, formatDistanceToNow } from 'date-fns';
import ru from 'date-fns/locale/ru';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy', { locale: ru });
}

export function fDotDate(date) {
  return format(new Date(date), 'dd.LL.yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm', { locale: ru });
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDateShort(date) {
  return format(new Date(date), 'dd/MM/yy hh:mm', { locale: ru });
}

export const randomDateFormater = (date) => {
  return format(
    new Date().setDate(Math.round(Math.random() * 12) || null),
    'dd.LL.yyyy',
  );
};

export const generateRandomDate = () => {
  // Get a random year between, for example, 2000 and 2023
  const year = Math.floor(Math.random() * (2023 - 2000 + 1)) + 2000;

  // Get a random month between 1 and 12
  const month = Math.floor(Math.random() * 12) + 1;

  // Get a random day between 1 and 31 (you might want to adjust this depending on your needs)
  const day = Math.floor(Math.random() * 31) + 1;

  // Create a new Date object with the random values
  const randomDate = new Date(year, month - 1, day); // Month is 0-indexed

  return randomDate.toDateString();
};
