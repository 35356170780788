import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../apis';
import axiosInstance from '../../utils/axios';

const initialState = {
  favorites: [],
  loading: false,
  error: null,
};

export const fetchFavorites = createAsyncThunk(
  'favorites/fetchFavorites',
  async (keyword = '') => {
    const response = await axiosInstance.get(API.catalog_favorites, {
      params: {},
    });
    return response.data.data;
  },
);

export const addFavorite = createAsyncThunk(
  'favorites/addFavorite',
  async ({ sku }) => {
    const response = await axiosInstance.post(API.catalog_favorites, {
      sku: sku,
    });
    return response.data;
  },
);

export const removeFavorite = createAsyncThunk(
  'favorites/removeFavorite',
  async ({ sku }) => {
    await axiosInstance.delete(`${API.catalog_favorites}${sku}`);
    return sku;
  },
);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites = action.payload;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites.push(action.payload);
      })
      .addCase(addFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites = state.favorites.filter(
          (product) => product.product_variant.sku !== action.payload,
        );
      })
      .addCase(removeFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default favoritesSlice.reducer;
