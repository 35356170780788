import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { useSelector } from 'react-redux';
import { authReduxSelector } from 'src/redux/slices/authentication';

export default function MyAvatar({ profileImage, ...other }) {
  const { user } = useSelector((state) => authReduxSelector(state));

  return (
    <MAvatar
      src={profileImage || user?.profile_image}
      alt={user?.full_name}
      color={user?.photoURL ? 'default' : createAvatar(user?.email).color}
      {...other}
    >
      {createAvatar(user?.email).name}
    </MAvatar>
  );
}
