import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Container, Button } from '@mui/material';
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
}));

const { payment_guard_page, financial_report } = DATA_IDS.dashboard;

export default function PaymentBlock({ expired }) {
  const isVideoTeachingPage =
    PATH_DASHBOARD.general.video_teaching === window.location.pathname;

  return (
    <RootStyle
      childrenProps={{ sx: { display: 'flex', alignItems: 'center' } }}
      title={expired ? 'Срок вашего тарифного плана истек.' : 'iSistant'}
      {...setDataTestId({ name: payment_guard_page })}
    >
      <Container>
        <Box sx={{ maxWidth: 700, margin: 'auto', textAlign: 'center' }}>
          {isVideoTeachingPage ? (
            <>
              <Typography variant="h3" paragraph>
                У вас нет доступа к этой категории
              </Typography>
            </>
          ) : (
            <Typography
              variant="h3"
              paragraph
              {...setDataTestId({
                name: expired
                  ? ''
                  : financial_report.not_available_trial_version,
              })}
            >
              {expired
                ? 'Срок вашего тарифного плана истек.'
                : 'Не доступен в пробной версии'}
            </Typography>
          )}

          {isVideoTeachingPage ? (
            <Typography sx={{ color: 'text.secondary' }}>
              Чтобы получить доступ к категории видеоуроков, просто перейдите на
              наш тариф "Премиум + Обучение". Это идеальный способ улучшить ваш
              опыт обучения!
            </Typography>
          ) : (
            <Typography sx={{ color: 'text.secondary' }}>
              {expired
                ? 'Для продолжения работы с нашим сервисом, оплатите подписку'
                : 'Для получения доступа, оплатите подписку'}
            </Typography>
          )}

          {isVideoTeachingPage && (
            <Button
              color={'success'}
              variant={'contained'}
              style={{ color: 'white', marginTop: 5 }}
              onClick={() =>
                window.open('https://wa.me/996705908005', '_blank')
              }
            >
              Напишите в WhatsApp
            </Button>
          )}

          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

          <Button
            to={`${PATH_DASHBOARD.user.billing}`}
            size="large"
            variant="contained"
            component={RouterLink}
            sx={{ textTransform: 'none' }}
          >
            {expired ? 'Продлить подписку' : 'Оплатить подписку'}
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
