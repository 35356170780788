import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

// Thunks
export const fetchAdSuppliers = createAsyncThunk(
  'ads/fetchSuppliers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.ad_supplier);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

export const fetchCpmTable = createAsyncThunk(
  'ads/fetchCpmTable',
  async ({ keyword, page }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.ad_cpm_table(keyword, page));
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

// Initial state
const initialState = {
  suppliers: {
    data: [],
    loading: false,
    error: null,
  },
  cpmTable: {
    data: [],
    total_count: 0,
    loading: false,
    error: null,
  },
};

// Slice
const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchAdSuppliers cases
      .addCase(fetchAdSuppliers.pending, (state) => {
        state.suppliers.loading = true;
        state.suppliers.error = null;
      })
      .addCase(fetchAdSuppliers.fulfilled, (state, action) => {
        state.suppliers.loading = false;
        state.suppliers.data = action.payload;
      })
      .addCase(fetchAdSuppliers.rejected, (state, action) => {
        state.suppliers.loading = false;
        state.suppliers.error = action.payload || 'Failed to fetch suppliers';
      })

      // fetchCpmTable cases
      .addCase(fetchCpmTable.pending, (state) => {
        state.cpmTable.loading = true;
        state.cpmTable.error = null;
      })
      .addCase(fetchCpmTable.fulfilled, (state, action) => {
        state.cpmTable.loading = false;
        state.cpmTable.data = action.payload.data;
        state.cpmTable.total_count = action.payload.total_count;
      })
      .addCase(fetchCpmTable.rejected, (state, action) => {
        state.cpmTable.loading = false;
        state.cpmTable.error = action.payload || 'Failed to fetch CPM table';
      });
  },
});

export default adsSlice.reducer;
