import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

const loadCartFromLocalStorage = () => {
  const storedCart = localStorage.getItem('cart');
  return storedCart ? JSON.parse(storedCart) : [];
};

const initialState = {
  isLoading: false,
  error: false,
  items: loadCartFromLocalStorage(),
  totalQuantity: loadCartFromLocalStorage().reduce(
    (total, item) => total + item.quantity,
    0,
  ),
  totalPrice: loadCartFromLocalStorage().reduce(
    (total, item) => total + item.totalPrice,
    0,
  ),
};

const saveCartToLocalStorage = (products) => {
  localStorage.setItem('cart', JSON.stringify(products));
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCartItems(state, action) {
      state.items = action.payload;
      state.totalQuantity = state.items.reduce(
        (total, item) => total + item.quantity,
        0,
      );
      state.totalPrice = state.items.reduce(
        (total, item) => total + item.totalPrice,
        0,
      );
      saveCartToLocalStorage(state.items);
    },
    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
        existingItem.totalPrice += newItem.price * newItem.quantity;
      } else {
        state.items.push({
          ...newItem,
          totalPrice: newItem.price * newItem.quantity,
        });
      }

      state.totalQuantity += newItem.quantity;
      state.totalPrice += newItem.price * newItem.quantity;

      saveCartToLocalStorage(state.items);
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (existingItem) {
        state.totalQuantity -= existingItem.quantity;
        state.totalPrice -= existingItem.totalPrice;
        state.items = state.items.filter((item) => item.id !== id);

        saveCartToLocalStorage(state.items);
      }
    },
    updateItemQuantity(state, action) {
      const { id, quantity } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (existingItem) {
        state.totalQuantity += quantity - existingItem.quantity;
        state.totalPrice +=
          (quantity - existingItem.quantity) * existingItem.price;
        existingItem.quantity = quantity;
        existingItem.totalPrice = quantity * existingItem.price;

        saveCartToLocalStorage(state.items);
      }
    },
  },
});

export const {
  startLoading,
  hasError,
  setCartItems,
  addItemToCart,
  removeItemFromCart,
  updateItemQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;

export const getValidCartProducts = () => async (dispatch) => {
  dispatch(startLoading());
  const products = loadCartFromLocalStorage();
  const productIds = products.map((product) => product.id).join(',');

  try {
    const response = await axiosInstance.get(
      `${API.catalog_valid_products}?skus=${productIds}`,
    );
    const validProducts = response.data;

    dispatch(setCartItems(validProducts));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};
