import { createSlice } from '@reduxjs/toolkit';
import toast from '../../utils/snackBarUtils';
import { API } from '../../apis';
import axiosInstance from '../../utils/axios';

const initialState = {};

const portalSpecialists = createSlice({
  name: 'portalSpecialists',
  initialState,
  reducers: {
    switchPortalSpecialists: (state, action) => {
      const value = action.payload;
      state.portalSpecialists = value;
    },
  },
  extraReducers: (builder) => {},
});

export const actionPortalSpecialists = portalSpecialists.actions;
export default portalSpecialists.reducer;

export const createPortalSpecialist = async (data) => {
  const response = await axiosInstance.post(API.portal_specialist, data);
  return response.data;
};

export const getPortalCountries = async () => {
  try {
    const response = await axiosInstance.get(API.portal_specialist_countries);
    return response.data;
  } catch (error) {
    toast.error(error);
    console.error(
      'An error occurred while getting Portal specialist countries',
      error,
    );
  }
};

export const getPortalCities = async () => {
  try {
    const response = await axiosInstance.get(API.portal_specialist_cities);
    return response.data;
  } catch (error) {
    toast.error(error);
    console.error(
      'An error occurred while getting Portal specialist cities',
      error,
    );
  }
};

export const getPortalSpecialityList = async () => {
  try {
    const response = await axiosInstance.get(API.portal_speciality_list);
    return response.data;
  } catch (error) {
    toast.error(error);
    console.error(
      'An error occurred while getting Portal speciality list',
      error,
    );
  }
};

export const getSpecialistPosts = async (specialist_id) => {
  try {
    const response = await axiosInstance.get(
      `${API.specialist_posts}?specialist_id=${specialist_id}`,
    );
    return response.data;
  } catch (error) {
    toast.error(error.error);
  }
};

export const postSpecialistPost = async (data) => {
  try {
    const response = await axiosInstance.post(API.specialist_post_post, data);
    return response.data;
  } catch (error) {
    toast.error(error.error);
  }
};

export const deleteSpecialistPost = async (post_id) => {
  try {
    const response = await axiosInstance.delete(
      `${API.specialist_post_post}${post_id}`,
    );
    return response.data;
  } catch (error) {
    toast.error(error.error);
  }
};

export const getPortalSpecialist = async (id) => {
  try {
    const response = await axiosInstance.get(`${API.portal_specialist}${id}`);
    return response.data;
  } catch (error) {
    toast.error(error.error);
    console.error(
      'An error occurred while getting to the Portal specialist',
      error,
    );
  }
};

export const updatePortalSpecialist = async (id, data) => {
  const response = await axiosInstance.patch(
    `${API.portal_specialist}${id}/`,
    data,
  );
  return response.data;
};

export const likePost = async (id) => {
  try {
    const response = await axiosInstance.post(
      `${API.specialist_posts}${id}/toggle-like/`,
    );
    return response.data;
  } catch (error) {
    toast.error(error.error);
    console.error('An error occurred while linking post', error);
  }
};

export const commentPost = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      `${API.specialist_posts}${id}/add-comment/`,
      data,
    );
    return response.data;
  } catch (error) {
    toast.error(error.error);
    console.error('An error occurred while linking post', error);
  }
};

export const deleteProfile = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `${API.portal_specialist}${id}`,
    );
    return response.data;
  } catch (error) {
    toast.error(error.error);
    console.error('An error occurred while linking post', error);
  }
};
