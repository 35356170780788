import { Icon } from '@iconify/react';
import { Stack } from '@mui/material';

export default function Loader() {
  return (
    <Stack direction="row" justifyContent="center">
      <Icon icon="eos-icons:three-dots-loading" width="100" height="100" />
    </Stack>
  );
}
