import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { API } from '../../apis';

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  product: {
    id: 1,
    name: '',
    description: '',
    brand: {
      id: 0,
      name: '',
      logo: '',
    },
    price: 0,
    old_price: 0,
    available: true,
    variants: [],
    reviews: [],
  },
};

const slice = createSlice({
  name: 'catalog_product',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },
  },
});

export default slice.reducer;

export function getProduct(name, setReviews) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API.catalog_product(name));
      const data = response.data;
      const image = data?.images?.[0] || {};
      dispatch(slice.actions.getProductSuccess({ ...data, image }));
      setReviews(data.reviews);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const changeProductSlice = (newProduct) => {
  return (dispatch) => {
    dispatch(slice.actions.getProductSuccess(newProduct));
  };
};
