import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authReduxSelector } from 'src/redux/slices/authentication';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

const { payment_methods, payment_methods_cancel_button } =
  DATA_IDS.dashboard.user.billing;

const PAYMENT_OPTIONS = [
  {
    id: 'visa',
    path: '/static/icons/ic_visa.svg',
    title: 'Виза',
    dataTestid: payment_methods.visa,
  },
  {
    id: 'mastercard',
    path: '/static/icons/ic_mastercard.svg',
    title: 'Мастеркард',
    dataTestid: payment_methods.mastercard,
  },
  {
    id: 'elcart',
    path: '/static/icons/ic_elcart.png',
    title: 'Элкарт',
    dataTestid: payment_methods.elcart,
  },
  {
    id: 'mbank',
    path: '/static/icons/ic_mbank.png',
    title: 'Мбанк',
    dataTestid: payment_methods.mbank,
  },
];

export default function ApproveModal({ open, setOpen, plan, changePlan }) {
  const { isAuthenticated } = useSelector((state) => authReduxSelector(state));
  const navigate = useNavigate();

  const confirmChangePlan = (method) => {
    setOpen(false);
    if (isAuthenticated) {
      return changePlan(plan?.id, method);
    }
    return navigate('/auth/register');
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Выберите способ оплаты</DialogTitle>
      <DialogContent>
        <Stack
          justifyContent={'center'}
          direction={{ xs: 'column-reverse', sm: 'row' }}
          sx={{ py: 3 }}
        >
          {PAYMENT_OPTIONS.map((obj) => (
            <Tooltip key={obj.id} title={obj.title}>
              <IconButton onClick={() => confirmChangePlan(obj.id)}>
                <img
                  src={obj.path}
                  alt={obj.title}
                  width={50}
                  {...setDataTestId({ name: obj.dataTestid })}
                />
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          sx={{ display: 'block', mx: 'auto' }}
          {...setDataTestId({ name: payment_methods_cancel_button })}
        >
          Отмена
        </Button>
      </DialogContent>
    </Dialog>
  );
}
