import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { API } from 'src/apis';
import toast from 'src/utils/snackBarUtils';
import { AxiosError } from 'axios';

export const featuredCategoriesThunk = createAsyncThunk(
  'featuredCategories/featuredCategoriesThunk',
  async ({ itemId, configs }, { rejectWithValue, getState }) => {
    const state = getState().featured;
    try {
      const categoryId = itemId || state.categoryId;

      const params = { ...state.params, ...configs.params };

      const { data } = await axiosInstance.get(
        API.category_summary_item(categoryId),
        { ...configs, params },
      );

      if (data?.parent_categories) {
        window.location.replace(
          `${
            PATH_DASHBOARD.general.featured_categories
          }/${data?.parent_categories?.join('/')}`,
        );
      }
      return { data, categoryId, params };
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else if (error instanceof AxiosError) toast.error(error);
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  data: [],
  loading: false,
  params: null,
  categoryId: null,
  initialized: false,
};

const featuredCategoriesSlice = createSlice({
  name: 'featuredCategories',
  initialState,
  reducers: {
    setData: (state, action) => {
      const value = action.payload;
      if (Array.isArray(value)) {
        state.data = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(featuredCategoriesThunk.fulfilled, (state, { payload }) => {
        const { data, categoryId, params } = payload;

        if (Array.isArray(data)) {
          if (data.length) {
            state.data = data;
          }
        }
        state.categoryId = categoryId;
        state.params = params;
        state.loading = false;
        state.initialized = true;
      })
      .addCase(featuredCategoriesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(featuredCategoriesThunk.rejected, (state) => {
        state.loading = false;
        state.data = [];
        state.initialized = true;
      });
  },
});

export const actionFeaturedCategories = featuredCategoriesSlice.actions;

export default featuredCategoriesSlice.reducer;
