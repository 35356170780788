import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabValue: 'popular',
};

const slice = createSlice({
  name: 'forum',
  initialState,
  reducers: {
    setTabValue(state, { payload }) {
      state.tabValue = payload;
    },
  },
});

export default slice.reducer;

export const setTabValue = (val) => {
  return (dispatch) => {
    dispatch(slice.actions.setTabValue(val));
  };
};
