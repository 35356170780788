import { Outlet } from 'react-router-dom';
import MainNavbar from './MainNavbar';
import MenuMobile from './MenuMobile';
import { useMediaQuery, useTheme } from '@mui/material';

export default function MainLayout() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile ? <MenuMobile /> : <MainNavbar />}
      <div>
        <Outlet />
      </div>
    </>
  );
}
