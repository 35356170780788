import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

const initialState = {
  isLoading: false,
  error: null,
  brands: [],
};

const slice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getBrandsSuccess(state, action) {
      state.isLoading = false;
      state.brands = action.payload.data;
    },
    updateBrandsSuccess(state, action) {
      const updatedCategory = action.payload;
      state.isLoading = false;
      const index = state.brands.findIndex(
        (category) => category.id === updatedCategory.id,
      );
      if (index !== -1) {
        state.brands[index] = updatedCategory;
      }
    },
  },
});

export const { startLoading, hasError, getBrandsSuccess, updateBrandsSuccess } =
  slice.actions;

export const getCatalogBrands = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosInstance.get(API.catalog_brands);
    dispatch(getBrandsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export default slice.reducer;
