import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

const initialState = {
  ads: [],
  adDetails: {},
  loading: false,
  error: null,
};

export const fetchAds = createAsyncThunk(
  'ads/fetchAds',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.ads);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

export const fetchFullData = async () => {
  try {
    const response = await axiosInstance.post(API.ads_pull_data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : 'Network Error';
  }
};

// Fetch ad details by ID
export const fetchAdDetails = createAsyncThunk(
  'ads/fetchAdDetails', // Ensure action name matches `extraReducers`
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.ad_details(id));
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

const adSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAds.fulfilled, (state, action) => {
        state.loading = false;
        state.ads = action.payload.data; // Ensure `data` is present in payload
      })
      .addCase(fetchAds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching ads';
      })

      .addCase(fetchAdDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.adDetails = action.payload.data;
      })
      .addCase(fetchAdDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error fetching ad details';
      });
  },
});

export default adSlice.reducer;

export const selectAds = (state) => state.ads.ads;
export const selectAdDetails = (state) => state.ads.adDetails;
export const selectAdsLoading = (state) => state.ads.loading;
export const selectAdsError = (state) => state.ads.error;
