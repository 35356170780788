import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as WhiteLogo } from '../../assets/WhiteLogo.svg';
import { ReactComponent as FaceBook } from '../../assets/faceBook-white.svg';
import { ReactComponent as Instagram } from '../../assets/instagram_white.svg';
import { ReactComponent as Telegram } from '../../assets/telegram_white.svg';
import { ReactComponent as YouTube } from '../../assets/youtube_white.svg';
import { PATH_PAGE } from '../../routes/paths';
import { useNavigate } from 'react-router-dom';

export const CONTACT_LINKS = [
  {
    name: 'Отдел продаж: +996 705 908 005',
    link: 'tel: +996 705 908 005',
    type: 'phone',
  },
  {
    name: 'Тех.под: +996 705 908 001',
    link: 'tel:+996 705 908 001',
    type: 'phone',
  },
  {
    name: 'почта: support@isistant.io',
    link: 'https://mail.isistant.io/',
    type: 'email',
  },
];

const SOCIALS = [
  {
    name: 'YouTube',
    icon: <YouTube />,
    href: 'https://www.youtube.com/channel/UC-95FsBJADFETV7Pig34jMQ/videos',
  },
  {
    name: 'Telegram',
    icon: <Telegram />,
    href: 'https://t.me/+MfabNSdIT_czNWY6',
  },
  {
    name: 'Instagram',
    icon: <Instagram />,
    href: 'https://www.instagram.com/isistant.edu.x',
  },
  {
    name: 'Facebook',
    icon: <FaceBook />,
    href: 'https://www.facebook.com/isistant.io',
  },
];

export default function MainFooter() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: '#262626',
      }}
    >
      <Grid
        container
        sx={{
          p: !isMobile ? '90px 150px 48px 150px' : '25px 20px',
        }}
        // columns={{ xs: 3, sm: 8 }}
      >
        <Grid item xs={12} sm={3}>
          <Box display="flex" alignItems="end">
            <WhiteLogo />
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: '700',
                color: '#fff',
                ml: '8px',
              }}
            >
              iSistant
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: !isMobile ? '16px' : '12px',
              fontWeight: '700',
              color: '#fff',
              mt: '30px',
            }}
          >
            Айсистент - самая популярная {!isMobile && <br />}
            платформа развития бизнеса на {!isMobile && <br />}
            маркетплейсах в СНГ, которую {!isMobile && <br />}
            используют сотни тысяч {!isMobile && <br />}
            предпринимателей.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} mt={!isMobile ? 0 : '35px'}>
          <Typography
            sx={{
              fontSize: !isMobile ? '16px' : '12px',
              fontWeight: '700',
              color: '#fff',
            }}
          >
            Данные
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '18px',
            }}
          >
            720001, Бишкек, Тоголок Молдо, 40/4
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '16px',
            }}
          >
            ОсОО “Alee Soft”
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '16px',
            }}
          >
            ИНН 00908202210555
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '16px',
            }}
          >
            Безналичный расчет для Юрлиц и ИП
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} mt={!isMobile ? 0 : '35px'}>
          <Typography
            sx={{
              fontSize: !isMobile ? '16px' : '12px',
              fontWeight: '700',
              color: '#fff',
            }}
          >
            Условия использования
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '18px',
              cursor: 'pointer',

              '&:hover': {
                opacity: 1,
              },
            }}
            onClick={() => navigate(PATH_PAGE.terms)}
          >
            Условия использования сайта
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '16px',
              cursor: 'pointer',

              '&:hover': {
                opacity: 1,
              },
            }}
            onClick={() => navigate(PATH_PAGE.privacy)}
          >
            Политика обработки персональных данных
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '16px',
              cursor: 'pointer',

              '&:hover': {
                opacity: 1,
              },
            }}
            onClick={() => window.open(PATH_PAGE.ourExtension, '_blank')}
          >
            Наше расширение
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} mt={!isMobile ? 0 : '35px'}>
          <Typography
            sx={{
              fontSize: !isMobile ? '16px' : '12px',
              fontWeight: '700',
              color: '#fff',
            }}
          >
            Контакты
          </Typography>
          {CONTACT_LINKS.map((contact, i) => (
            <Typography
              key={i}
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#fff',
                opacity: 0.5,
                mt: '18px',
                cursor: 'pointer',

                '&:hover': {
                  opacity: 1,
                },
              }}
              onClick={() => {
                if (contact.type === 'phone') {
                  window.location.href = `tel:${contact.link}`;
                } else if (contact.type === 'email') {
                  window.location.href = `mailto:${contact.link}`;
                } else {
                  window.open(contact.link, '_blank', 'noopener');
                }
              }}
            >
              {contact.name}
            </Typography>
          ))}
          <Box display="flex" mt="20px">
            {SOCIALS.map((item, i) => (
              <Box
                key={i}
                width="35px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr="10px"
                sx={{
                  color: 'red',
                  cursor: 'pointer',

                  '&:hover': {
                    transform: 'scale(1.3)',
                  },
                }}
                onClick={() => {
                  window.open(item.href, '_blank');
                }}
              >
                {item.icon}
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} mt="70px">
          <Box
            component="hr"
            border="0.5px solid white"
            sx={{ opacity: 0.5 }}
          />
          <Typography
            sx={{
              fontSize: !isMobile ? '14px' : '12px',
              fontWeight: '400',
              color: '#fff',
              opacity: 0.5,
              mt: '48px',
              textAlign: 'center',
            }}
          >
            © 2024 Все права защищены с ОсОО “Alee Soft”
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
