import PromocodeForm from 'src/components/_dashboard/promocode/PromoCodeForm';
import Page from '../../components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';

const title = 'Промокоды';

export default function PromoCode() {
  return (
    <Page title={title}>
      <HeaderBreadcrumbs
        cardKey="promocode"
        title={title}
        heading={title}
        onClickTitle={() => {}}
        links={[]}
      />
      <PromocodeForm />
    </Page>
  );
}
