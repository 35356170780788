import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

export const fetchSheetsCommission = createAsyncThunk(
  'sheets/Commissions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.commission);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

export const fetchSheetsWarehouse = createAsyncThunk(
  'sheets/Warehouse',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.warehouse);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

const initialState = {
  totalCount: 0,
  warehouses: [],
  data: [],
  loading: false,
  error: null,
};

const sheetsSlice = createSlice({
  name: 'sheetsData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSheetsCommission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSheetsCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.totalCount = action.payload.total_count;
        state.data = action.payload.data;
      })
      .addCase(fetchSheetsCommission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch data';
      })
      .addCase(fetchSheetsWarehouse.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSheetsWarehouse.fulfilled, (state, action) => {
        state.loading = false;
        state.warehouses = action.payload.data;
      })
      .addCase(fetchSheetsWarehouse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch data';
      });
  },
});

export default sheetsSlice.reducer;
