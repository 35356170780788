import { Icon } from '@iconify/react';
import {
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'src/redux/store';
import PlanCard from '../user/account/PlanCard/PlanCard';
import { authReduxSelector } from 'src/redux/slices/authentication';

const iconButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 9,
};

CardBody.propTypes = {
  benefits: PropTypes.object.isRequired,
};

export function CardBody({ benefits }) {
  const tariffs = useSelector((state) => state.plan.tariffs);
  const { isAuthenticated } = useSelector((state) => authReduxSelector(state));
  const tariffsSetter = () => {
    const filteredTariffs = tariffs.filter((tariff) =>
      benefits?.discounts.some((benefit) => benefit.plan_id === tariff.id),
    );
    const changedPrices = filteredTariffs?.map((data) => {
      const dataCopy = { ...data };
      const discounts = benefits?.discounts.find(
        (val) => val.plan_id === data.id,
      );
      dataCopy.discount_prices = {
        ...dataCopy.discount_prices,
        discount_price_kzt: Number(discounts?.final_price_kzt)
          ? discounts?.final_price_kzt
          : discounts?.final_price_kzt.toString(),
        discount_price_rub: Number(discounts?.final_price_rub)
          ? discounts?.final_price_rub
          : discounts?.final_price_rub.toString(),
        discount_price_som: Number(discounts?.final_price_som)
          ? discounts?.final_price_som
          : discounts?.final_price_som.toString(),
      };
      return dataCopy;
    });
    return changedPrices;
  };

  return (
    <div style={{ height: '100%', overflow: 'auto', margin: '0, 20px' }}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography
          id="modal-modal-title"
          variant="h3"
          component="h2"
          sx={{ mt: 2, mb: 5 }}
        >
          {isAuthenticated
            ? `Промокод ${benefits?.name || ' '} успешно активирован!`
            : `Что дает промокод ${benefits?.name || ' '}?`}
        </Typography>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }}>
        {benefits?.gained_accesses.length > 0 && 
        
        <Stack justifyContent="left" sx={{ width: { xs: '100%', md: '50%' } }}>
          <List sx={{ textAlign: 'center', p: 0 }}>
            {Boolean(benefits?.gained_accesses.length) && (
              <ListSubheader
                sx={{ position: 'relative', background: 'transparent' }}
              >
                <Typography variant="h4" mb={2}>
                  {isAuthenticated
                    ? 'Разделы которые теперь доступны:'
                    : 'Разделы которые будут доступны:'}
                </Typography>
              </ListSubheader>
            )}
            {benefits?.gained_accesses.map((item) => (
              <ListItem key={item} sx={{ justifyContent: 'left' }}>
                <Typography variant={'h5'} sx={{ textAlign: 'left' }}>
                  - {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Stack>
        }
        <Grid
          container
          justifyContent={'center'}
          alignItems={{ xs: 'stretch', lg: 'flex-end' }}
          spacing={{ md: 1, lg: 4 }}
          sx={{ height: '100%', overflowY: 'auto' }}
        >
          {tariffsSetter().map((plan, i) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              index={i}
              listLength={tariffsSetter().length}
            />
          ))}
        </Grid>
      </Stack>
    </div>
  );
}

PromocodeSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  benefits: PropTypes.object.isRequired,
};

export default function PromocodeSuccess({ open, onClose, benefits }) {
  return (
<Modal
  open={open}
  onClose={onClose}
  aria-labelledby="modal-modal-title"
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
    <Card sx={{ p: 3, height: '90vh', width: '65%', position: 'relative', mx: 2 }}>
      <IconButton onClick={onClose} sx={iconButtonStyle}>
        <Icon icon="fontisto:close" width={25} />
      </IconButton>
      <CardBody benefits={benefits} />
    </Card>
</Modal>

  );
}
