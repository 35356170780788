export const flattenArray = (data) => {
  let flattenedArray = [];

  const flattenElement = ({ element, title }) => {
    if (title) {
      flattenedArray.push({ ...element, name: `${title} -> ${element?.name}` });
    } else {
      flattenedArray.push(element);
    }

    if (
      element.child_category_summary &&
      element.child_category_summary.length > 0
    ) {
      element.child_category_summary.forEach((child) => {
        flattenElement({ element: child, title: element.name });
      });
    }
  };

  data.forEach((element) => {
    flattenElement({ element, title: undefined });
  });

  return flattenedArray;
};

export const flattenId = (array, keys = [], type = 'reverse' | 'none') => {
  if (Array.isArray(array)) {
    if (type === 'reverse') {
      const result = array.reverse().map((item) => ({
        ...keys.reduce((acc, key) => {
          return { ...acc, [key]: item[key] };
        }, {}),
      }));
      return result;
    }
    const result = array.map((item) => ({
      ...keys.reduce((acc, key) => {
        return { ...acc, [key]: item[key] };
      }, {}),
    }));
    return result;
  }
};

export const getParentsById = (id, parentId, prevId, data, flattenKeys) => {
  if (!Array.isArray(data)) {
    return [];
  }

  const isFoundChild = (data, parents, pId) => {
    const findData = data.find((item) => +item.id === +id);

    const findPrevData = data?.find((el) => el.id === prevId);

    if (!findPrevData) {
      if (findData) {
        if (pId === prevId) {
          parents.push(findData);
          return true;
        }
        if (pId === prevId && findData?.children?.length) {
          parents.push(findData);
          return true;
        }
        return false;
      } else {
        for (let item of data) {
          if (item.children.length) {
            if (isFoundChild(item.children, parents, item?.id)) {
              parents.push(item);
              return true;
            }
          }
        }
        return false;
      }
    }
    if (findPrevData.children.length) {
      if (isFoundChild(findPrevData.children, parents, findPrevData.id)) {
        parents.push(findPrevData);
        return true;
      }
    }
    return false;
  };

  const keys = flattenKeys || ['id', 'name'];

  const parents = [];
  const findData = data.find((item) => +item.id === +id);
  if (id === parentId && id === prevId) {
    if (findData) return flattenId([findData], keys, 'reverse');
  } else {
    for (let item of data) {
      if (item?.children?.length) {
        if (+parentId === +item?.id) {
          if (isFoundChild(item?.children, parents, item?.id)) {
            parents.push(item);
            return flattenId(parents, keys, 'reverse');
          }
        }
      }
    }
  }
};

export const filterLanguage = (array, language) => {
  if (Array.isArray(array)) {
    return language === 'all'
      ? array
      : array.filter((o) => o.language === language);
  }
  return array;
};

export const getByIdWithParents = (array = [], ids = [], keys = []) => {
  const generateKeys = keys.reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: '' };
  }, {});

  const result = ids.reduce(
    (previousValue, currentValue) => {
      const parseNumber = Number(currentValue);

      const isArray = previousValue.children.length
        ? previousValue.children
        : array;
      const someId = isArray.find((el) => el.id === parseNumber);

      if (someId) {
        const getByKeys = keys.reduce((acc, el) => {
          return { ...acc, [el]: someId[el] };
        }, {});

        return {
          ...previousValue,
          ...getByKeys,
          children: someId.children,
        };
      }
      return previousValue;
    },
    { ...generateKeys, children: [] },
  );
  return result;
};

export const checkArray = (array) => {
  return Array.isArray(array) ? array : [];
};

export const findCategoriesById = (ids = [], data = []) => {
  const parents = [];

  for (let i = 0; i < ids.length; i++) {
    const element = ids[i];

    if (!parents.length) {
      const find = data.find((el) => el.id === +element);
      if (find) {
        parents.push(find);
      }
    } else if (parents.length === i) {
      const item = parents[i - 1];
      const find = item.children.find((el) => el.id === +element);
      if (find) {
        parents.push(find);
      }
    }
  }

  return parents;
};
