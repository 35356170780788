import { createSlice } from '@reduxjs/toolkit';
import { WILDBERRIES } from 'src/utils/marketplace';

const initialState = {
  currentMarketplace: WILDBERRIES,
};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    switchMarketplace: (state, action) => {
      const value = action.payload;
      state.currentMarketplace = value;
    },
  },
  extraReducers: (builder) => {},
});

export const actionMarketplace = marketplaceSlice.actions;
export default marketplaceSlice.reducer;
