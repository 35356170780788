import Page from '../../components/Page';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableFooter,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CustomBreadcrumbs from '../../customComponents/CutsomBreadCrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import TuneIcon from '@mui/icons-material/Tune';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/system';
import { fCurrency } from '../../utils/formatNumber';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  addItemToCart,
  getValidCartProducts,
  removeItemFromCart,
  updateItemQuantity,
} from '../../redux/slices/cart';
import NoItems from '../../assets/placeHolderCart.png';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { fetchFavorites } from '../../redux/slices/catalogFavorites';
import Router from '../../routes';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: 'relative',
  height: '208px',
  borderBottom: '1px solid #E7E5EA',
  cursor: 'pointer',
  '&:nth-of-type(odd)': {},
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export default function EcommerceCheckout() {
  const { items, totalPrice } = useSelector((state) => state.cart);

  const navigate = useNavigate();

  return (
    <Page title="Ecommerce: Checkout | isistant.io">
      <Container maxWidth="lg">
        <Grid
          container
          gap={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #E7E5EA"
          pb={2}
          mb={2}
        >
          <CustomBreadcrumbs
            items={[
              { name: 'Каталог', href: PATH_DASHBOARD.general.catalog },
              { name: 'Корзина' },
            ]}
          />
          {/*<Box display={'flex'}>*/}
          {/*  <Box display={'flex'} mr={2} alignItems="center">*/}
          {/*    <Typography*/}
          {/*      mr={1}*/}
          {/*      style={{*/}
          {/*        color: '#1C274C',*/}
          {/*        fontSize: '14px',*/}
          {/*        fontStyle: 'normal',*/}
          {/*        fontWeight: 500,*/}
          {/*        lineHeight: '28px',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Фильтр*/}
          {/*    </Typography>*/}

          {/*    <TuneIcon style={{ color: '#898097db' }} fontSize="small" />*/}
          {/*  </Box>*/}
          {/*  <Box display={'flex'} alignItems="center">*/}
          {/*    <Box display={'flex'} mr={2} alignItems="center">*/}
          {/*      <Typography*/}
          {/*        mr={1}*/}
          {/*        style={{*/}
          {/*          color: '#1C274C',*/}
          {/*          fontSize: '14px',*/}
          {/*          fontStyle: 'normal',*/}
          {/*          fontWeight: 500,*/}
          {/*          lineHeight: '28px',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Сортировка*/}
          {/*      </Typography>*/}
          {/*      <KeyboardArrowDownIcon*/}
          {/*        style={{ color: '#898097db' }}*/}
          {/*        fontSize="small"*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Grid>

        {!items.length ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="550px"
              height="700px"
              component="img"
              src={NoItems}
              p={4}
            />
          </Box>
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow
                  style={{ background: '#FAFAFA', borderRadius: '6px' }}
                >
                  {[
                    '',
                    '',
                    'Товар',
                    'Параметры',
                    'Количество',
                    'Цена за единицу',
                    'Сумма',
                  ].map((head) => (
                    <StyledTableCell
                      style={{
                        color: '#61677F',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      }}
                    >
                      {head}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((row) => (
                  <CustomTableRow row={row} />
                ))}
              </TableBody>
            </Table>
            <Stack width="100%" mt="24px">
              <Grid container>
                <Grid item xs={4} display="flex" justifyContent="space-between">
                  {/*<Typography*/}
                  {/*  style={{*/}
                  {/*    color: '#898097',*/}
                  {/*    fontSize: '24px',*/}
                  {/*    fontStyle: 'normal',*/}
                  {/*    fontWeight: '700',*/}
                  {/*    lineHeight: '34px',*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Promo Code:*/}
                  {/*</Typography>*/}
                  {/*<TextField*/}
                  {/*  placeholder="Code"*/}
                  {/*  variant="outlined"*/}
                  {/*  style={{*/}
                  {/*    borderRadius: '40%',*/}
                  {/*  }}*/}
                  {/*  inputProps={{*/}
                  {/*    disableUnderline: true,*/}
                  {/*    style: {*/}
                  {/*      borderRadius: '40%',*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*/>*/}
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center">
                  {/*<Typography*/}
                  {/*  style={{*/}
                  {/*    color: '#898097',*/}
                  {/*    fontSize: '24px',*/}
                  {/*    fontStyle: 'normal',*/}
                  {/*    fontWeight: '700',*/}
                  {/*    lineHeight: '34px',*/}
                  {/*    marginRight: '10px',*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Скидка:*/}
                  {/*</Typography>*/}
                  {/*<Typography*/}
                  {/*  style={{*/}
                  {/*    color: '#1C274C',*/}
                  {/*    fontSize: '24px',*/}
                  {/*    fontStyle: 'normal',*/}
                  {/*    fontWeight: '700',*/}
                  {/*    lineHeight: '34px',*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  10%*/}
                  {/*</Typography>*/}
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center">
                  <Typography
                    style={{
                      color: '#898097',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '34px',
                      marginRight: '10px',
                    }}
                  >
                    Итого к оплате:
                  </Typography>
                  <Typography
                    style={{
                      color: '#1C274C',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '34px',
                    }}
                  >
                    {fCurrency(totalPrice)}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              mt="70px"
              alignItems="center"
            >
              <Box
                display="flex"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(-1)}
              >
                <ArrowBack />
                <Typography
                  ml="13px"
                  variant="subtitle2"
                  style={{
                    color: '#391484',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '28px',
                  }}
                >
                  Вернуться обратно
                </Typography>
              </Box>
              <Button
                style={{
                  background: '#FFBB38',
                  color: 'white',
                  borderRadius: '28px',
                  padding: '15px 60px',
                }}
                onClick={() => {
                  navigate(PATH_DASHBOARD.general.create_order);
                }}
              >
                Перейти к оплате
              </Button>
            </Stack>
          </>
        )}
      </Container>
    </Page>
  );
}

const CustomTableRow = ({ row }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFavorites());
    dispatch(getValidCartProducts());
  }, []);
  const removeCartItem = () => dispatch(removeItemFromCart(row.id));
  const addQuantity = () => {
    if (+row.maxQuantity === +row.quantity) {
      dispatch(
        updateItemQuantity({
          ...row,
          quantity: row.maxQuantity,
        }),
      );
      return;
    }

    if (!isNaN(row.quantity)) {
      dispatch(
        updateItemQuantity({
          ...row,
          quantity: row.quantity + 1,
        }),
      );
    } else {
      dispatch(
        updateItemQuantity({
          ...row,
          quantity: 1,
        }),
      );
    }
  };

  const minusQuantity = () => {
    if (row.quantity > 1) {
      dispatch(
        updateItemQuantity({
          ...row,
          quantity: row.quantity - 1,
        }),
      );
    } else {
      dispatch(
        updateItemQuantity({
          ...row,
          quantity: 1,
        }),
      );
    }
  };

  const handleQtyChange = (newQuantity, product) => {
    dispatch(
      updateItemQuantity({
        ...row,
        quantity: newQuantity,
      }),
    );
  };

  const size = row.sizes.find((item) => item.skus.includes(row.id));

  return (
    <StyledTableRow key={row.id}>
      <Box position="absolute" top={10} onClick={removeCartItem} right={10}>
        <CloseIcon />
      </Box>
      <TableCell style={{ padding: 0, width: '170px' }}>
        <Box
          component="img"
          width="160px"
          height="160px"
          borderRadius="36px"
          src={row?.preview_image?.url || row?.image?.url || ''}
        />
      </TableCell>
      <TableCell
        sx={{
          paddingTop: '30px',
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <Box>
          <Typography
            style={{
              color: '#B0B0B0',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '18px',
            }}
          >
            {row.sku || 'sku'}
          </Typography>
          <Typography
            style={{
              color: '#1C274C',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '24px',
            }}
          >
            {row.name || 'name'}
          </Typography>
          <Typography
            style={{
              color: '#B0B0B0',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '18px',
            }}
          >
            Категория наименование
          </Typography>
        </Box>
      </TableCell>
      <TableCell style={{ width: '100px' }}>
        {row?.size?.value && (
          <Box
            p={1}
            border="1px solid #60656E"
            borderRadius="38px"
            display="flex"
            justifyContent="center"
            bgcolor="#E7E5EA"
          >
            <Typography>
              {row?.size?.value || row.sizes[0].value || ''}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell style={{ width: '150px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mt: 1,
            mb: 2,
            borderRadius: '28px',
            padding: '8px 20px',
            border: '1px solid #E7E5EA',
          }}
        >
          <Stack direction="row" alignItems="center" p={0}>
            <IconButton onClick={minusQuantity}>
              <RemoveIcon />
            </IconButton>
            <TextField
              style={{
                width: `${42 + String(row.quantity).length}px`,
                padding: 0,
                margin: 0,
                border: 'none',
                fontSize: 8,
              }}
              value={row.quantity}
              onChange={(e) => handleQtyChange(Number(e.target.value, row))}
              type="number"
              InputProps={{
                sx: {
                  fontSize: 8,
                  '&::before, &:hover, &:hover:not(.Mui-disabled, .Mui-error):before':
                    {
                      borderBottom: 'none',
                    },
                },
              }}
              inputProps={{
                disableUnderline: true,
                min: 0,
                style: {
                  padding: '5px',
                  margin: 0,
                  border: 'none',
                  borderRadius: 'none',
                  background: 'white',
                  textAlign: 'center',
                  fontSize: 16,
                  lineHeight: '20px',
                },
              }}
              variant="filled"
            />
            <IconButton onClick={addQuantity}>
              <AddIcon />
            </IconButton>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            color: '#1C274C',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          {fCurrency(+row.price)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            color: '#1C274C',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          {fCurrency(+row.price * +row.quantity)}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};
