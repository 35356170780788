export const productImages = [
  'https://basket-01.wb.ru/vol14/part1492/1492218/images/c516x688/1.jpg',
  'https://basket-08.wb.ru/vol1164/part116426/116426549/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol99/part9983/9983141/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol73/part7375/7375911/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol106/part10660/10660172/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1584/part158423/158423560/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1479/part147944/147944317/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1584/part158423/158423565/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1479/part147943/147943723/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1583/part158386/158386715/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1697/part169734/169734908/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol20/part2010/2010436/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1694/part169480/169480363/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1697/part169734/169734907/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1696/part169683/169683379/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1493/part149361/149361954/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol99/part9983/9983182/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol99/part9983/9983146/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1493/part149357/149357285/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1486/part148661/148661231/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol45/part4550/4550114/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1687/part168750/168750916/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1694/part169480/169480368/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol99/part9983/9983145/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1694/part169480/169480362/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1694/part169483/169483199/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol106/part10652/10652000/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol70/part7006/7006735/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1700/part170073/170073142/images/c516x688/1.jpg',
  'https://basket-02.wb.ru/vol248/part24816/24816599/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1641/part164148/164148304/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1479/part147974/147974958/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1700/part170073/170073265/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1700/part170073/170073275/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1700/part170073/170073298/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1700/part170073/170073260/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol63/part6320/6320606/images/c516x688/1.jpg',
  'https://basket-03.wb.ru/vol421/part42104/42104176/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1622/part162266/162266463/images/c516x688/1.jpg',
  'https://basket-05.wb.ru/vol948/part94829/94829541/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol73/part7319/7319927/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol32/part3213/3213407/images/c516x688/1.jpg',
  'https://basket-05.wb.ru/vol948/part94830/94830215/images/c516x688/1.jpg',
  'https://basket-05.wb.ru/vol784/part78451/78451965/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol77/part7745/7745261/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol106/part10635/10635250/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol77/part7745/7745260/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol69/part6991/6991488/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol112/part11253/11253386/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1623/part162375/162375274/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1623/part162371/162371810/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol115/part11587/11587804/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol106/part10652/10652694/images/c516x688/1.jpg',
  'https://basket-09.wb.ru/vol1222/part122277/122277524/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol86/part8683/8683649/images/c516x688/1.jpg',
  'https://basket-04.wb.ru/vol717/part71725/71725281/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol112/part11253/11253387/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol38/part3847/3847862/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1641/part164150/164150380/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1641/part164148/164148889/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1641/part164145/164145853/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol99/part9983/9983170/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1624/part162479/162479501/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1641/part164145/164145850/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1642/part164225/164225809/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1623/part162335/162335935/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1641/part164150/164150384/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1642/part164225/164225815/images/c516x688/1.jpg',
  'https://basket-02.wb.ru/vol147/part14704/14704827/images/c516x688/1.jpg',
  'https://basket-02.wb.ru/vol147/part14704/14704830/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol126/part12646/12646893/images/c516x688/1.jpg',
  'https://basket-04.wb.ru/vol504/part50443/50443481/images/c516x688/1.jpg',
  'https://basket-02.wb.ru/vol147/part14704/14704829/images/c516x688/1.jpg',
  'https://basket-02.wb.ru/vol147/part14704/14704828/images/c516x688/1.jpg',
  'https://basket-04.wb.ru/vol504/part50414/50414129/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol38/part3882/3882660/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol38/part3882/3882658/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol84/part8404/8404506/images/c516x688/1.jpg',
  'https://basket-05.wb.ru/vol912/part91210/91210018/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol124/part12481/12481232/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1698/part169868/169868483/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol38/part3882/3882661/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol37/part3713/3713281/images/c516x688/1.jpg',
  'https://basket-12.wb.ru/vol1698/part169868/169868484/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol28/part2817/2817629/images/c516x688/1.jpg',
  'https://basket-03.wb.ru/vol291/part29147/29147696/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1559/part155912/155912237/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol68/part6825/6825683/images/c516x688/1.jpg',
  'https://basket-08.wb.ru/vol1132/part113258/113258194/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol77/part7717/7717119/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1621/part162180/162180554/images/c516x688/1.jpg',
  'https://basket-11.wb.ru/vol1619/part161933/161933992/images/c516x688/1.jpg',
  'https://basket-10.wb.ru/vol1479/part147979/147979171/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol71/part7193/7193756/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol26/part2628/2628163/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol69/part6970/6970592/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol54/part5464/5464825/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol78/part7884/7884728/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol78/part7884/7884727/images/c516x688/1.jpg',
  'https://basket-01.wb.ru/vol78/part7882/7882149/images/c516x688/1.jpg',
];

export const randomProductImage = () => {
  const randomValueIndex =
    Math.round(Math.random() * productImages.length - 1) || 0;
  const index = randomValueIndex > 0 ? randomValueIndex : 0;
  const feedback = productImages[index];
  return feedback;
};

export const FEEDBACKS = [
  'Нарядная блузка. Швы качественные. Спасибо поставщику. Пришла в срок.',
  'Хорошая блуза,в размер',
  'Отличная. Цена копейки, сшита хорошо. Пришла только в обычный пакет завязана узлом',
  'На лето самое то',
  'Мне все подошло 🙌🏽',
  'В размер. Очень удобная. Просвечивает. Очень хорошо для лета.',
  'Очень лёгкая воздушная и цвет классный.По качеству норм,размер соответствует.',
  'Все отлично, соответствует размеру!',
  'Блузка просто прелесть. Легкая, сохнет за два часа на солнышке, яркая, красивая.',
  'Хорошая лёгкая блузка.',
  'По качеству хорошая, но на мой 40-42 оказалась велика в проймах и для меня слишком прозрачная',
  'Кофточка очень понравилась. Полностью соответствует описанию.',
  'На 40 размер прекрасно сел',
  'Кофточка супер за такие деньги',
  'Цвет как на фото в реальности, размер соответствует,не просвечива т за счёт жёлтого цвета и рисунка',
  'Спасибо за быструю доставку и качественную вещь от этой фирмы, всегда выбираю этот бренд для повседневной жизни. Носила блузу всю неделю, легкая, на лето самое оно👍Цена приятная 314 руб.',
];

export const randomFeedbacks = () => {
  const randomValueIndex =
    Math.round(Math.random() * FEEDBACKS.length - 1) || 0;
  const index = randomValueIndex > 0 ? randomValueIndex : 0;
  const feedback = FEEDBACKS[index];
  return feedback;
};

export const oldCode = `Эффектная блузка с отложным воротником и сплошной спереди. Легкая прозрачная ткань придает модели соблазнительный штрих. Ткань красиво струится и драпируется в процессе ношения, неприхотлива в уходе и со временем не теряет свой первоначальный вид.`;
export const newCode = `Эффектная блузка с отложным воротником и сплошной застежкой на пуговицы спереди. Легкая прозрачная ткань придает модели соблазнительный`;

const oldCodeCharacter = ['nurtilek: 1'].join('\n');
const newCodeCharacter = ['nurtilek: 2', 'nurtilek:3'].join('\n');

export const history = [
  { id: 1, name: 'Бренд', oldValue: 'Product', newValue: 'Item' },
  { id: 12, name: 'Описание', oldValue: oldCode, newValue: newCode },
  {
    id: 13,
    name: 'Характеристики',
    oldValue: oldCodeCharacter,
    newValue: newCodeCharacter,
  },
];

export const CHARACTERISTICS = [
  {
    key: 'Тип рукава',
    value: [
      'без рукавов',
      'Короткий рукав',
      'Лепесток (тюльпан)',
      'Рукав три четверти',
      'Флаттер',
      'Рукав колокол',
      'Епископский рукав',
      'Рукав реглан',
      'Летучая мышь',
    ],
  },
  {
    key: 'Страна производства',
    value: [
      'Китай',
      'США',
      'Индия',
      'Россия',
      'Япония',
      'Республика Корея',
      'Саудовская Аравия',
      'Турция',
      'Казахстан',
      'Узбекистан',
      'Туркменистан',
    ],
  },
  {
    key: 'Вид застежки',
    value: [
      'пуговицы',
      'Английский замок',
      'Французский замок',
      'Итальянский замок',
      'Гвоздики (пусеты)',
      'Гвоздики (пусеты)',
      'Застежка булавка',
      'Застежка скоба',
      'Застежка кольцо',
    ],
  },
  {
    key: 'Пол',
    value: ['Женский', 'Мужской', 'Детский', 'Девочки', 'Мальчики'],
  },
];

export const randomCharacteristics = () => {
  const characteristics = CHARACTERISTICS.map((characteristic) => {
    const randomValueIndex =
      Math.round(Math.random() * characteristic.value.length - 1) || 0;
    const index = randomValueIndex > 0 ? randomValueIndex : 0;

    return `${characteristic.key}:${characteristic.value[index]}`;
  });

  return characteristics;
};
