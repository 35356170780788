import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import MainLogo from '../assets/iSISTANT.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default function Logo({ sx, width = 35, height = 35 }) {
  return (
    <Box sx={{ width: width, height: height, ...sx }}>
      <img alt="isistant-logo" src={MainLogo} />
    </Box>
  );
}
