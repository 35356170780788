import axios from 'axios';
import { endSession } from './jwt';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  credentials: 'include',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  ({ response }) => {
    if (response?.status === 401) {
      endSession();
      window.location.href = '/auth/login';
    }
    return Promise.reject(
      (response && response.data) || 'При запросе произошла ошибка',
    );
  },
);

axiosInstance.interceptors.request.use(
  (config) => {
    if (config?.url === '/api/store/main/task_status/') return config;

    const accessToken = localStorage.getItem('accessToken');
    return {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        ...(!accessToken ? {} : { Authorization: `Bearer ${accessToken}` }),
      },
    };
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
);

export default axiosInstance;
