import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  IconButton,
  Popover,
} from '@mui/material';
import { currencyParser, sortingCurrencies } from '..';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import _ from 'lodash';
import { useState } from 'react';
import styled from '@emotion/styled';
import ApproveModal from './components/ApproveModal';
import { PATH_AUTH } from '../../../../../routes/paths';
import { fPrice } from 'src/utils/formatNumber';
import { useSelector } from 'react-redux';
import { authReduxSelector } from 'src/redux/slices/authentication';
import CustomModal from '../../../../customModal/CustomModal';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';

const { billing } = DATA_IDS.dashboard.user;

const getPlanAction = (isAuthenticated, plan) => {
  if (isAuthenticated) {
    if (plan?.is_current_plan && plan?.price !== 0) {
      return 'Продлить план';
    } else {
      return 'Сменить план';
    }
  } else {
    return 'Попробовать';
  }
};

const RenderOptions = ({ option }) => (
  <Stack spacing={1} direction="row" alignItems="center" sx={{ mb: '12px' }}>
    <Box
      component={Icon}
      icon={option?.enabled ? checkmarkFill : 'eva:close-outline'}
      sx={
        option?.enabled
          ? { color: 'primary.main', width: 20, height: 20 }
          : { color: 'red', width: 20, height: 20 }
      }
    />
    <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 500 }}>
      {option?.name}
    </Typography>
  </Stack>
);

const RenderPrices = ({
  salePrice = 0,
  fullPrice = 0,
  currency = '₽',
  duration,
  plan,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isSalePriceValid = Number(fullPrice) > Number(salePrice);

  return (
    <Grid container direction={'row'} flexWrap={plan ? 'wrap' : 'nowrap'}>
      {isSalePriceValid && (
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '5px',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Typography
            position="relative"
            color="text.secondary"
            fontSize={'25px'}
            fontWeight={700}
          >
            <Line />
            {fPrice(fullPrice)}
          </Typography>
        </Grid>
      )}

      <Grid
        item
        xs={isSalePriceValid ? 7 : 12}
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: isSalePriceValid ? 'flex-start' : 'center',
          pl: isSalePriceValid ? '17px' : 0,
        }}
      >
        <Box>
          <Typography sx={{ fontSize: '25px', fontWeight: 700, mr: '3px' }}>
            {fPrice(salePrice)}
          </Typography>
          <Typography variant="subtitle2" whiteSpace="nowrap" fontSize={'16px'}>
            {currency}/{plan?.name === 'Premium + Обучение' ? ' 2 ' : ''}мес.
          </Typography>
        </Box>

        {plan && (
          <>
            <IconButton
              sx={{
                height: 'fit-content',
                p: 0,
                pb: '2px',
                alignSelf: 'start',
              }}
              aria-describedby={id}
              onClick={handleClick}
              {...setDataTestId({ name: billing.info_prices })}
            >
              <Icon
                icon="clarity:info-circle-line"
                width={15}
                height={15}
                color="#637381"
              />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  padding: '5px',
                  minWidth: '300px',
                  maxWidth: '1600px',
                  maxHeight: 'auto',
                },
              }}
            >
              <Stack
                direction={{ xs: 'column', md: 'column' }}
                justifyContent="space-enevly"
              >
                {sortingCurrencies(plan)
                  .slice(1)
                  .map((curr, i) => (
                    <div key={i}>
                      <RenderPrices
                        salePrice={
                          plan?.discount_prices?.[
                            currencyParser(curr)?.discount
                          ] || Math.ceil(plan?.[curr])
                        }
                        fullPrice={plan?.[curr]}
                        currency={currencyParser(curr)?.name}
                        duration={duration}
                      />
                    </div>
                  ))}
              </Stack>
            </Popover>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const Line = styled('span')`
  position: absolute;
  top: 19px;
  left: -4px;
  width: 110%;
  transform: rotate(0deg);
  height: 2px;
  background-color: #3f3f3f;
`;

export default function PlanCard({
  plan,
  changePlan,
  index,
  listLength = 3,
  paymentDaysRemain,
  allFeatures,
}) {
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const firstCurrency = sortingCurrencies(plan)[0];

  const newPlanFeatures = allFeatures.map((feature) => {
    const findFeature = plan.features.find(
      (featureItem) => feature.name === featureItem.name,
    );

    return {
      ...feature,
      enabled: !!findFeature && findFeature.enabled,
    };
  });

  const sortedFeatures = [
    ...(Array.isArray(newPlanFeatures) ? newPlanFeatures : []),
  ]
    .sort((firstItem, secondItem) => firstItem.id - secondItem.id)
    .sort((firstEl, secondEl) => secondEl.enabled - firstEl.enabled);

  const planFeatures = _.mapValues(
    _.groupBy(sortedFeatures, 'group'),
    (clist) => clist.map((feature) => _.omit(feature, 'group')),
  );

  const { isAuthenticated } = useSelector((state) => authReduxSelector(state));

  const isEmptyPlan = Object.keys(plan).length;

  const actionText = getPlanAction(isAuthenticated, plan);

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={listLength === 3 ? 4 : 5}
      mb={'20px'}
      {...setDataTestId({ name: plan?.dataTestid || 'data-testid' })}
    >
      {isEmptyPlan ? (
        <Card
          sx={{
            p: '30px 20px',
            backgroundColor:
              plan?.is_current_plan && changePlan ? '#F4EFFC' : '',
            color: plan?.is_current_plan && changePlan ? '#212B36' : '',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography variant="h4">{plan?.name}</Typography>
            </Stack>

            {plan?.discount_prices &&
            changePlan &&
            plan?.discount_prices?.discount_percent_saved ? (
              <Box
                sx={{
                  mr: '-20px',
                  mt: '15px',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  backgroundColor: 'primary.main',
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize="20px"
                  px="15px"
                  py="8px"
                  color={'white'}
                >
                  {`- ${plan?.discount_prices?.discount_percent_saved} %`}
                </Typography>
              </Box>
            ) : null}
          </Stack>

          <Divider sx={{ mb: '17px' }} />

          <Grid container alignItems={'center'} flexGrow={2}>
            {plan[firstCurrency] !== 0 ? (
              <Grid item xs={12}>
                <Stack direction="row">
                  <div style={{ flexGrow: 1 }}>
                    <RenderPrices
                      salePrice={
                        plan?.discount_prices?.[
                          currencyParser(firstCurrency)?.discount
                        ] || Math.ceil(plan?.[firstCurrency])
                      }
                      fullPrice={plan?.[firstCurrency]}
                      currency={currencyParser(firstCurrency)?.name}
                      plan={plan}
                      duration={plan?.duration}
                    />
                  </div>
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Stack
                  direction={{ xs: 'row', md: 'row' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    color="#19A760"
                    fontSize={20}
                    fontWeight={700}
                    textAlign={{ md: 'center', lg: 'center' }}
                    sx={{ my: '2.5px' }}
                  >
                    Бесплатно
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
          {plan?.is_current_plan && plan?.price <= 0 && (
            <Typography variant="h5" textAlign="center" color="primary.main">
              Текущий план
            </Typography>
          )}

          {changePlan &&
          (plan?.price > 0 || paymentDaysRemain === 0) &&
          !(plan?.is_current_plan && plan?.price <= 0) ? (
            <Button
              {...setDataTestId({ name: billing.action_button })}
              variant="contained"
              fullWidth
              sx={{
                mt: '20px',
                mb: '20px',
                fontSize: '13px',
                textTransform: 'none',
                py: '10px',
                backgroundColor: plan?.is_current_plan ? 'white' : '',
                color: plan?.is_current_plan ? 'primary.main' : '',
                '&:hover': {
                  color: plan?.is_current_plan ? 'white' : '',
                },
              }}
              onClick={() => {
                if (plan.name === 'Premium + Обучение') {
                  setOpenVideo(true);
                } else {
                  isAuthenticated && plan?.price > 0
                    ? setOpen(true)
                    : changePlan(plan?.id, 'free', plan?.price === 0);
                }
              }}
            >
              {actionText}
            </Button>
          ) : null}

          <Divider sx={{ mt: '12px', mb: '17px' }} />

          <Grid container>
            {Object.keys(planFeatures).map((key, index) => (
              <Grid item sm={6} lg={12} key={index}>
                {planFeatures[key].map((option) => (
                  <RenderOptions
                    key={option.id}
                    option={option}
                    is_current={plan?.is_current_plan}
                  />
                ))}
              </Grid>
            ))}
          </Grid>

          <ApproveModal
            open={open}
            setOpen={setOpen}
            plan={plan}
            changePlan={changePlan}
          />
          <CustomModal
            hideCloseButton
            isOpen={openVideo}
            onClose={() => setOpenVideo(false)}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <Typography variant="h3" paragraph textAlign={'center'}>
                Чтобы перейти на тариф <br /> "Premium + обучение" <br />{' '}
                напишите в Whatsapp
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Менеджер Нагима
              </Typography>

              <Button
                color={'success'}
                variant={'contained'}
                style={{ color: 'white', marginTop: 5 }}
                onClick={() =>
                  window.open('https://wa.me/996705908005', '_blank')
                }
              >
                Напишите в WhatsApp
              </Button>
            </Box>
          </CustomModal>
        </Card>
      ) : (
        <Card
          sx={{
            p: '30px 20px',
            backgroundColor:
              plan?.is_current_plan && changePlan ? '#F4EFFC' : '',
            color: plan?.is_current_plan && changePlan ? '#212B36' : '',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
          }}
        >
          <Stack direction="row" alignItems="flex-start">
            <Typography variant="h5">Мало данных...</Typography>
          </Stack>
        </Card>
      )}
    </Grid>
  );
}
