import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';
import toast from 'src/utils/snackBarUtils';
import { AxiosError } from 'axios';
import { API } from 'src/apis';

const name = 'categoryAnalytics';

const conditionForSort = (sortParamValue, sortValue) => {
  if (sortParamValue) {
    return sortParamValue;
  } else if (sortValue === null) return null;

  return sortValue?.dir > 0 ? sortValue?.name : `-${sortValue?.name}`;
};

export const conditionForSortInfo = (newSort) => {
  if (newSort === null) return null;

  const newSortReplace = newSort?.replace(/^-/, '');

  return {
    dir: newSort.startsWith('-') ? -1 : 1,
    id: newSortReplace,
    name: newSortReplace,
    columnName: newSortReplace,
    type: 'number',
  };
};

export const categoryAnalyticsThunk = createAsyncThunk(
  `${name}/categoryAnalyticsThunk`,
  async (
    { paramId, parents, is_subject, page, sort, filter },
    { rejectWithValue, getState },
  ) => {
    const productsParams = getState().categoryAnalytics.categoryProduct.params;

    const offset = page ? 100 * (page.page - 1) || 0 : productsParams.offset;
    const sortValue = productsParams.sort;

    const newSort = conditionForSort(sort, sortValue);

    const sortInfo = conditionForSortInfo(newSort);

    const newParamId = paramId || productsParams.paramId;

    const newIs_subject = is_subject || {
      is_subject: productsParams.is_subject,
    };

    const newParents = parents || productsParams.parents;

    const newPage = productsParams.page || (page ? page.page - 1 : 0);

    const params = {
      parents: newParents,
      ...newIs_subject,
      offset,
      sort: newSort,
      filter,
    };

    const url = API.category_analytics + `${newParamId}/products_v2/`;

    try {
      const { data } = await axiosInstance.get(url, {
        params,
      });

      return {
        data,
        params: {
          ...params,
          page: newPage,
          sort: sortInfo,
        },
      };
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else if (error instanceof AxiosError) toast.error(error);

      return rejectWithValue(error);
    }
  },
);

export const categoryTrendThunk = createAsyncThunk(
  `${name}/categoryTrendThunk`,
  async ({ parents, subject, paramId }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/store/dashboard/category_analytics/${paramId}/trend/`,
        {
          params: { parents, is_subject: subject?.is_subject },
        },
      );

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else if (error instanceof AxiosError) toast.error(error);

      return rejectWithValue(error);
    }
  },
);

export const categoryPriceSegmentThunk = createAsyncThunk(
  `${name}/categoryPriceSegmentThunk`,
  async ({ parents, subject, paramId, filter }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/store/dashboard/category_analytics/${paramId}/pricesegmentation/`,
        {
          params: { parents, is_subject: subject?.is_subject, date: filter },
        },
      );

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else if (error instanceof AxiosError) toast.error(error);

      return rejectWithValue(error);
    }
  },
);

export const categorySellerThunk = createAsyncThunk(
  `${name}/categorySellerThunk`,
  async ({ paramId, parents, subject, filter }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/store/dashboard/category_analytics/${paramId}/sellers/`,
        {
          params: { parents, is_subject: subject?.is_subject, date: filter },
        },
      );

      return data;
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else if (error instanceof AxiosError) toast.error(error);

      return rejectWithValue(error);
    }
  },
);

const initialState = {
  categoryProduct: {
    products: [],
    productsLength: 0,
    loading: false,
    params: {
      page: 0,
      paramId: 0,
      parents: '',
      is_subject: false,
      offset: 0,
      sort: null,
    },
  },
  trend: {
    loading: false,
    trendProducts: [],
  },
  segment: {
    loading: false,
    segmentProducts: [],
  },
  seller: {
    loading: false,
    sellerProducts: [],
  },
};

const categoryAnalyticsSlice = createSlice({
  name: 'categoryAnalytics',
  initialState,
  reducers: {
    categoryProductPagination: (state, { payload }) => {
      const page = state.categoryProduct.params.page;

      const value =
        state.categoryProduct.params.offset + (payload > page ? 100 : -100);

      state.categoryProduct.params.offset = value;
      state.categoryProduct.params.page = payload;
    },
    categoryProductSort: (state, { payload }) => {
      state.categoryProduct.params.sort = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(categoryAnalyticsThunk.fulfilled, (state, actions) => {
        const { data, params } = actions.payload;
        if (Array.isArray(data)) {
          if (data.length) {
            state.categoryProduct.products = data;
            state.categoryProduct.productsLength = data.length;
          }
        } else {
          state.categoryProduct.products = data.products || [];
          state.categoryProduct.productsLength = data.total || 0;
        }
        const newParams = { ...state.categoryProduct.params, ...params };
        state.categoryProduct.params = newParams;
        state.categoryProduct.loading = false;
      })
      .addCase(categoryAnalyticsThunk.pending, (state) => {
        state.categoryProduct.loading = true;
      })
      .addCase(categoryAnalyticsThunk.rejected, (state) => {
        state.categoryProduct.loading = false;
      })
      .addCase(categoryTrendThunk.pending, (state) => {
        state.trend.loading = true;
      })
      .addCase(categoryTrendThunk.fulfilled, (state, actions) => {
        const products = actions.payload;

        state.trend.trendProducts = products;
        state.trend.loading = false;
      })
      .addCase(categoryTrendThunk.rejected, (state) => {
        state.trend.loading = false;
      })
      .addCase(categoryPriceSegmentThunk.pending, (state) => {
        state.segment.loading = true;
      })
      .addCase(categoryPriceSegmentThunk.fulfilled, (state, actions) => {
        const products = actions.payload;

        state.segment.segmentProducts = products;
        state.segment.loading = false;
      })
      .addCase(categoryPriceSegmentThunk.rejected, (state) => {
        state.segment.loading = false;
      })
      .addCase(categorySellerThunk.pending, (state) => {
        state.seller.loading = true;
      })
      .addCase(categorySellerThunk.fulfilled, (state, actions) => {
        const products = actions.payload;

        state.seller.sellerProducts = products;
        state.seller.loading = false;
      })
      .addCase(categorySellerThunk.rejected, (state) => {
        state.seller.loading = false;
      });
  },
});

export const actionCategoryAnalytics = categoryAnalyticsSlice.actions;

export const selectorCategoryAnalyticsProducts = (state) =>
  state.categoryAnalytics.categoryProduct;

export const selectorCategoryAnalyticsTrend = (state) =>
  state.categoryAnalytics.trend;
export const selectorCategoryAnalyticsSegment = (state) =>
  state.categoryAnalytics.segment;
export const selectorCategoryAnalyticsSeller = (state) =>
  state.categoryAnalytics.seller;

export default categoryAnalyticsSlice.reducer;
