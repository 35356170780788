import { useEffect } from 'react';
// material
import { Grid, Container, Skeleton } from '@mui/material';
// redux
import { useSelector } from '../../../../redux/store';
//
import AccountBillingPaymentMethod from './AccountBillingPaymentMethod';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useDispatch } from 'react-redux';
import { getCards, getInvoices } from 'src/redux/slices/user';
import TariffsPlanCards from 'src/components/partials/TariffsPlanCard';
import toast from 'src/utils/snackBarUtils';
import InfoCard from 'src/infoCard';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';

const title = 'Тарифы';

const CardsInvoicesLoad = (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item xs={12} md={6}>
      <Skeleton height={400} />
    </Grid>
    <Grid item xs={12} md={6}>
      <Skeleton height={400} />
    </Grid>
  </Grid>
);

const status = new URLSearchParams(window.location.search).get('status');
const message = new URLSearchParams(window.location.search).get('message');

export default function AccountBilling() {
  const { cards, invoices, isLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();

  useEffect(() => {
    dispatch(getCards());
    dispatch(getInvoices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status && message) toast?.[status](message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, message]);

  return (
    <Page title={title}>
      <HeaderBreadcrumbs
        heading={title}
        onClickTitle={() => {}}
        links={[]}
        cardKey="user_billing"
        title={title}
      />

      <TariffsPlanCards />
      {isLoading ? (
        CardsInvoicesLoad
      ) : (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={8}>
            <AccountBillingInvoiceHistory invoices={invoices} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AccountBillingPaymentMethod cards={cards} />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}
