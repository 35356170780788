import React, { memo, useState } from 'react';
import axiosInstance from './utils/axios';
import { API } from './apis';
import Loader from './components/Loader';
import { IconButton, Popover, Stack, styled } from '@mui/material';
import { Icon } from '@iconify/react';
import SetDataTestId from './components/SetDataTestId';
import { DATA_IDS } from './utils/cypress';
import { setDataTestId } from './utils/setDataTestId';

export const INFOCARD_ID = 'info-card';

const StyledFontAwesome = styled(Icon)`
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  &:hover {
    color: #0c53b7;
  }
`;

const StyledText = styled('div')`
  font-weight: lighter;
  white-space: pre-wrap;
`;

const { info } = DATA_IDS.components;

const InfoCard = ({
  cardKey,
  title,
  top = 0,
  right = 0,
  children,
  error,
  position = 'start',
}) => {
  const [infoData, setInfoData] = useState();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getInfo();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getInfo = async () => {
    setLoading(true);
    await axiosInstance
      .get(`${API.info_component}?key=${cardKey}&title=${title}`)
      .then(({ data }) => setInfoData(data))
      .finally(() => setLoading(false));
  };

  if (!cardKey) {
    return children;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ p: 1 }}
      >
        {loading ? (
          <Loader />
        ) : (
          <div style={{ padding: 6 }}>
            {infoData?.title && <h2>{infoData?.title}</h2>}
            {infoData?.text && <StyledText>{infoData?.text}</StyledText>}
            {infoData?.link && (
              <p
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(infoData?.link)}
              >
                {infoData?.link}
              </p>
            )}
            {infoData?.link_to_video &&
              (typeof infoData?.link_to_video === 'string' &&
              ['mp4', 'mov', 'avi'].some((type) =>
                infoData?.link_to_video.includes(type),
              ) ? (
                <video
                  src={infoData?.link_to_video}
                  muted
                  loop
                  autoPlay
                  width="100%"
                  height="100%"
                  style={{ maxWidth: 500 }}
                />
              ) : (
                <p
                  style={{
                    cursor: 'pointer',
                    paddingTop: 5,
                    color: '#18a7f2',
                  }}
                  onClick={() => window.open(infoData?.link_to_video)}
                >
                  Видео инструкцияны көрүңүз
                </p>
              ))}
          </div>
        )}
      </Popover>

      <Stack
        direction={position !== 'start' ? 'row' : 'row-reverse'}
        justifyContent={position !== 'start' ? 'flex-start' : 'flex-end'}
        alignItems="baseline"
        gap={1}
      >
        <IconButton
          onClick={handleClick}
          {...setDataTestId({ name: info.icon })}
        >
          <StyledFontAwesome
            id={INFOCARD_ID}
            icon="clarity:info-standard-line"
            sx={{
              color: error ? '#FF4842' : '#7635DC',
              right,
              top,
            }}
          />
        </IconButton>

        <div>{children}</div>
      </Stack>
    </div>
  );
};

export default memo(InfoCard);
