import { createSlice } from '@reduxjs/toolkit';
import { API } from 'src/apis';
import axiosInstance from '../../utils/axios';

const initialState = {
  tariffs: [],
};

const slice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    setTariffs(state, { payload }) {
      state.tariffs = payload;
    },
  },
});

export default slice.reducer;

export const getAllTariffs = () => {
  return async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(API.billing_plans);
      dispatch(slice.actions.setTariffs(data));
      return data;
    } catch (e) {
      console.error(e);
    }
  };
};
