import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import DashboardNavbar from './DashboardNavbar';
import { Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { authReduxSelector } from 'src/redux/slices/authentication';
import { isMobile } from 'react-device-detect';
import MobileNavigation from './MobileNavigation';

export default function DashboardLayout() {
  const { user } = useSelector((state) => authReduxSelector(state));

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHome = pathname === PATH_DASHBOARD.root;
  const promocode = localStorage.getItem('promo');

  const checkUserAddToken = user?.is_user_added_token
    ? PATH_DASHBOARD.general.shop
    : PATH_DASHBOARD.general.analytics;

  const autoNavigateLink = promocode
    ? PATH_DASHBOARD.general.promocode
    : checkUserAddToken;

  useEffect(() => {
    if (isHome) {
      navigate(autoNavigateLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHome, user?.is_user_added_token]);

  return (
    <>
      <Toolbar />
      {isMobile ? <MobileNavigation /> : <DashboardNavbar />}
      <Outlet />
    </>
  );
}
