import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';

export const fetchAdKeywords = createAsyncThunk(
  'ads/fetchKeywords',
  async (id, { rejectWithValue }) => {
    try {
      const today = new Date();
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - 6);

      const formattedToday = today.toISOString().split('T')[0];
      const formattedFromDate = fromDate.toISOString().split('T')[0];

      const body = {
        from_date: formattedFromDate,
        to: formattedToday,
      };

      const response = await axiosInstance.post(
        `${API.ad_details(id)}stat-keywords/`,
        body,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Network Error',
      );
    }
  },
);

const adKeywordsSlice = createSlice({
  name: 'adKeywords',
  initialState: {
    data: {
      keywords: [
        {
          date: '2024-10-31',
          stats: [
            {
              clicks: 0,
              ctr: '0.00',
              keyword: 'string',
              sum: '0.00',
              views: 0,
            },
          ],
        },
      ],
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdKeywords.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdKeywords.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAdKeywords.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Something went wrong';
      });
  },
});

export default adKeywordsSlice.reducer;
